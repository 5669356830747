import * as types from "./actionTypes";
import { fetchGraphFromGoodData, fetchWithDispatch, fetchWithBodyAndDispatch } from "../lib/fetch";
import _ from "lodash";
import { trackEvent } from "../lib/ga";
import { updateFilter } from "./filters";

export const commoditiesRequest = () => ({
  type: types.FETCH_COMMODITIES_REQUEST,
});

export const commoditiesReceived = (commodities) => ({
  type: types.FETCH_COMMODITIES_SUCCESS,
  commodities
});

export const fetchingCommoditiesFailed = (error) => ({
  type: types.FETCH_COMMODITIES_FAILURE,
  error
});

export const commodityUpdated = (commodity) => ({
  type: types.FETCH_COMMODITY_PATCH_SUCCESS,
  commodity
});

export const commodityUpdateFailed = (error) => ({
  type: types.FETCH_COMMODITY_PATCH_FAILURE,
  error
});

export function fetchCommodityGraph(commodityCode) {
  return function (dispatch) {
    dispatch({
      type: types.FETCH_COMMODITY_GRAPH_REQUEST,
      commodityCode,
    });

    fetchGraphFromGoodData(commodityCode,
        graphParams => dispatch({
        type: types.FETCH_COMMODITY_GRAPH_SUCCESS,
        graphParams,
        commodityCode,
      }),
      error => dispatch({
        type: types.FETCH_COMMODITY_GRAPH_FAILURE,
        error,
        commodityCode,
      }));
  };
}

export const saveCommodity = (commodity) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.FETCH_COMMODITY_PATCH_REQUEST,
    payload: commodity,
  });
  fetchWithBodyAndDispatch(`${apiUrl}/commodity/${commodity.commodity_code}`,
    { method: 'PATCH', body: {
      bdd: parseInt(commodity.commodity_bdd, 10),
    } },
    json => dispatch(commodityUpdated(commodity)),
    error => dispatch(commodityUpdateFailed(error))
  );
};

export const changeCommodity = (commodity) => (dispatch, getState) => {
  dispatch({
    type: types.CHANGE_COMMODITY,
    payload: commodity,
  });
};

export const fetchCommodities = () => (dispatch, getState, { apiUrl }) => {
  dispatch(commoditiesRequest());
  fetchWithDispatch(`${apiUrl}/commodity`,
    json => dispatch(commoditiesReceived(json)),
    error => dispatch(fetchingCommoditiesFailed(error))
  );
};

export const dispatchFetchFilteredCommodities = (dispatch, getState, { apiUrl }, changeFilterDispatchFn, { filterBrandsCb } = {}) => {
  changeFilterDispatchFn();
  dispatch(commoditiesRequest());
  const search = getState().router.location.search;
  
  if (!_.isEmpty(search)) {
    fetchWithDispatch(`${apiUrl}/commodity${search}`,
      json => {
        if (filterBrandsCb) {
          const brands = Array.from(
            json.reduce((acc, commodity) => {
              acc.add(commodity.commodity_brand);
              return acc;
            }, new Set())
          )
          dispatch(
            updateFilter({
              filter: 'brands',
              value: brands
            })
          )
          filterBrandsCb(brands);
        }
        dispatch(commoditiesReceived(json));
      },
      error => dispatch(fetchingCommoditiesFailed(error))
    );
  } else {
    dispatch(commoditiesReceived([]));
    dispatch({
      type: types.RESET_BRAND_FILTER,
    })
  }
};

export const quickSearch = (searchTerm) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.QUICK_SEARCH,
    searchTerm
  });
  trackEvent("quick-search", "searched", searchTerm);

  const params = encodeURI(`?code=${searchTerm}`);
  if (!_.isEmpty(searchTerm)) {
    dispatch(commoditiesRequest());
    return fetchWithDispatch(`${apiUrl}/commodity${params}`,
      json => dispatch(commoditiesReceived(json)),
      error => dispatch(fetchingCommoditiesFailed(error))
    );
  } else {
    dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl }, () => null);
  }
};
