import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { login } from "../../actions/authentication";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.svg";
import "./index.css";
import "../../components/Header.css";
import { User } from "react-feather";

class SignIn extends React.PureComponent {
  state = {
    email: "",
    password: ""
  };

  componentDidMount() {
    this.props.accessToken && this.props.history.push("/");
  }

  logIn = e => {
    e.preventDefault();
    this.props.logIn(this.state.email, this.state.password);
  };

  setEmail = e => this.setState({ email: e.target.value });
  setPassword = e => this.setState({ password: e.target.value });

  render() {
    return (
      <div className="signIn">
        <header className="Header">
          <div className="HeaderInner fixedMaxSize">
            <div className="imgHeadingWrap">
              <Link to="/">
                <img src={Logo} width="60" height="60" alt="Malfini"/>
              </Link>
            </div>
          </div>
        </header>

        <div className="content">
          <div className="signInDialog">
            <div className="app">
              <div className="appName">Purchase app</div>
              <div className="appVersion">v.1.0.0</div>
            </div>
            <div className="signInForm">
              <div className="title">
                <div className="titleIcon">
                  <User color="white"/>
                </div>
                <div className="titleText">Přihlášení</div>
              </div>
              <form onSubmit={e => this.logIn(e)}>
                <label htmlFor="email">
                  <span>Uživatelské jméno</span>
                  <input
                    autoFocus={true}
                    type="text"
                    name="email"
                    id="email"
                    value={this.state.email}
                    onChange={e => this.setEmail(e)}
                  />
                </label>
                <div className="errorMsg">
                  {this.props.error}
                </div>
                <label htmlFor="password">
                  <span>Heslo</span>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={this.state.password}
                    onChange={e => this.setPassword(e)}
                  />
                </label>
                <div className="right">
                  <button type="submit" name="logIn" className="logInBtn"
                          disabled={this.props.inProgress ||
                                    this.state.email.trim() === "" ||
                          this.state.password.trim() === ""}>
                    Přihlásit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    accessToken: state["authentication"]["access-token"],
    inProgress: state["authentication"]["inProgress"],
    error: state["authentication"]["error"],
  }),
  dispatch => ({
    logIn: (email, password) => dispatch(login(email, password))
  })
)(withRouter(SignIn));
