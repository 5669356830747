import React from "react";
import { connect } from "react-redux";
import {
  changeNomenclature,
  changeNomenclatureProp,
  changeForOrderAmount,
  changeForOrderAmountBatching,
  fetchInitialNomenclatures,
  fetchNomenclatureExpediting,
  fetchNomenclatureInProduction,
  getFilterParamsFromSearchUrl,
  setFilterParams,
  setFilterParamsAndFetchNomenclatures,
} from "../actions/nomenclatures";
import {
  fetchTrends,
} from "../actions/trends";
import Table from "./Commodity/Table";
import CommoditySuppliers from "./Commodity/CommoditySuppliers";
import { fetchCommodityGraph } from "../actions/commodities";
import "./Commodity.css";
import { prepareNomenclaturesData } from "../reducers/nomenclatures";
import Header from "../components/Header";
import ShoppingCart from "../components/ShoppingCart";
import { ChevronLeft, ChevronRight, FileText, HardDrive } from "react-feather";
import CommodityStateActionButton from "./Commodity/CommodityStateActionButton";
import { getCommodityState, isFinished } from "../lib/commodity";
import {
  fetchSuppliers,
} from "../actions/suppliers";
import {
  fetchInitialFilters,
} from "../actions/filters";
import {
  fetchShoppingCart,
  saveShoppingCartItem,
  saveShoppingCartItems
} from "../actions/shoppingCart";
import { fetchHistoricPurchase } from "../actions/historicPurchase";
import { fetchSalesPlan } from "../actions/salesPlan";
import {
  fetchShoppingCartHeader,
  saveShoppingCartHeader
} from "../actions/shoppingCartHeader";
import {
  AppPredictionLabel,
  OrderLabel,
  PredictionAmountColLabel,
  ProductionLabel,
  PurchaseAmountLabel
} from "./Commodity/columnHeaderRenderers";
import {
  AppPrediction,
  efficiencyClassName,
  efficiencyVal,
  ExpeditingVal,
  ForOrderVal,
  // ExpectedEfficiencyVal,
  InProductionVal,
  NomenclatureIdVal,
  PredictionAmountVal
} from "./Commodity/cellRenders";
import { commodityUrl } from "../lib/urls";
import * as qs from "query-string";
import ProductImg from "../components/ProductImg";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class Commodity extends React.Component {
  constructor(props) {
    super(props);
    const {
      changeNomenclature,
      // changeNomenclatureProp,
      changeForOrderAmount,
      saveShoppingCartItem,
      fetchNomenclatureInProduction,
      saveShoppingCartItems,
      fetchNomenclatureExpediting,
      changeForOrderAmountBatching
    } = props;
    /**
     * returns columns definition for table (in display order)
     * name - respective name of the attribute in payload
     * label - column header label
     * className - header cell css class
     */
    this.columns = (nomenclatures, trends, isCommodityFinished) => [
      {
        name: "nomenclature_id",
        label: ({ selectedCommodity }) => {
          const code = selectedCommodity && selectedCommodity.code;

          return (
            code && (
              <div className="commodityNameCell">
                <ProductImg
                  alt="Komodita"
                  className="commodityImgSmall"
                  commodityId={code}
                  colors={nomenclatures.map(n => n.color_code)}
                />
                <span>
                  {code} <span className="name">{selectedCommodity.name}</span>
                  {selectedCommodity.moq && <span title='MOQ' style={{paddingLeft: '1rem'}}>[{selectedCommodity.moq}]</span>}
                  &ensp;{selectedCommodity.xyz}
                </span>
              </div>
            )
          );
        },
        className: "idColumn rightThickBorder"
      },
      { name: "storage_state", label: () => "Sklad" },
      { name: "expediting", label: () => "Na cestě" },
      {
        name: "in_production",
        label: ({ data, bdd1 }) => (
          <ProductionLabel
            data={data}
            bdd1={bdd1}
            isCommodityFinished={isCommodityFinished}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            saveShoppingCartItems={saveShoppingCartItems}
          />
        )
      },
      {
        name: "prediction_amount_bdd",
        label: () => (
          <span>
            Očekáváný
            <br />
            prodej
          </span>
        )
      },
      {
        name: "future_storage_state",
        label: () => (
          <span>
            Budoucí stav
            <br />
            skladu
          </span>
        ),
        className: "rightThickBorder"
      },
      {
        name: "prediction_amount",
        label: ({ prediction_from, prediction_to }) => (
          <PredictionAmountColLabel
            predictionFrom={prediction_from}
            predictionTo={prediction_to}
          />
        ),
        className: "rightBorder"
      },
      {
        name: "purchase_amount",
        label: ({ purchase_from, purchase_to }) => (
          <PurchaseAmountLabel
            purchaseFrom={purchase_from}
            purchaseTo={purchase_to}
          />
        ),
        className: "rightThickBorder"
      },
      {
        name: "app_prediction",
        label: ({ data, bdd1 }) => (
          <AppPredictionLabel
            data={data}
            bdd1={bdd1}
            trends={trends}
            isCommodityFinished={isCommodityFinished}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            saveShoppingCartItems={saveShoppingCartItems}
          />
        )
      },
      {
        name: "for_order",
        label: ({ data, bdd1 }) => (
          <OrderLabel
            data={data}
            bdd1={bdd1}
            isCommodityFinished={isCommodityFinished}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            saveShoppingCartItems={saveShoppingCartItems}
          />
        )
      },
      { name: "efficiency", label: () => "Výstačnost" },
    ];
    /**
     * returns map where key is attribute name from payload and map of following attributes:
     * value - takes function which will be used instead of value from payload, takes rows data as param
     * className - cell css class
     */
    this.cellRenderers = ({
      isFinished,
      nomenclatureFilter,
      nomenclatureDrillDown,
      fetching,
      nomenclatureEfficiencies,
      data,
      trends,
      selectedCommodity,
    }) => ({
      nomenclature_id: {
        value: row => <NomenclatureIdVal row={row} />
      },
      storage_state: {
        className: () => "rightBorder"
      },
      expediting: {
        value: row => (
          <ExpeditingVal
            row={row}
            bdd1={nomenclatureFilter.bdd1}
            fetchNomenclature={fetchNomenclatureExpediting}
            nomenclatureDrillDown={nomenclatureDrillDown}
          />
        ),
        className: () => "rightBorder"
      },
      in_production: {
        value: row => (
          <InProductionVal
            row={row}
            bdd1={nomenclatureFilter.bdd1}
            fetchNomenclature={fetchNomenclatureInProduction}
            nomenclatureDrillDown={nomenclatureDrillDown}
          />
        ),
        className: () => "rightBorder"
      },
      prediction_amount_bdd: {
        className: () => "rightBorder"
      },
      prediction_amount: {
        value: row => (
          <PredictionAmountVal
            row={row}
            showColorPercentage={nomenclatureFilter.show_color_percentage}
          />
        ),
        className: () => ({ fetching: fetching.salesPlan })
      },
      purchase_amount: {
        className: () => ({ fetching: fetching.historicPurchase })
      },
      future_storage_state: {
        className: row => ({ negativeNumber: row.future_storage_state < 0 })
      },
      app_prediction: {
        value: row => (
          <AppPrediction
            row={row}
            isFinished={isFinished}
            changeForOrderAmount={changeForOrderAmount}
            saveShoppingCartItem={saveShoppingCartItem}
            nomenclatureFilter={nomenclatureFilter}
            trends={trends}
          />
        ),
        className: () => "rightBorder"
      },
      for_order: {
        value: row => (
          <ForOrderVal
            row={row}
            isFinished={isFinished}
            changeForOrderAmount={changeForOrderAmount}
            saveShoppingCartItem={saveShoppingCartItem}
            nomenclatureFilter={nomenclatureFilter}
            data={data}
            bdd1={nomenclatureFilter.bdd1}
            changeNomenclature={changeNomenclature}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            saveShoppingCartItems={saveShoppingCartItems}
            selectedCommodity={selectedCommodity}
          />
        ),
        className: () => "rightBorder forOrderVal"
      },
      efficiency: {
        value: row =>
          efficiencyVal(
            row,
            nomenclatureEfficiencies[row.nomenclature_id]
          ),
        className: row => {
          if (!row.is_sum) {
            const trend = trends && trends.find(t => t.code === row.xyz);
            const efficiency =
              nomenclatureEfficiencies[row.nomenclature_id] || row.efficiency;
            return efficiencyClassName(efficiency, row, trend);
          }
        }
      },
      // expected_efficiency: {
      //   value: (row, b) => {
      //     // return row.xyz && trends && trends[row.xyz] ? trends[row.xyz].default_value : '-'
      //     return <ExpectedEfficiencyVal
      //       row={row}
      //       isFinished={isFinished}
      //       saveShoppingCartItem={saveShoppingCartItem}
      //       changeNomenclatureProp={changeNomenclatureProp}
      //       nomenclatureFilter={nomenclatureFilter}
      //       trends={trends}
      //     />
      //   }
      // }
    });
  }

  fetchData = props => {
    const {
      fetchShoppingCart,
      fetchCommodityGraph,
      fetchNomenclatures,
      fetchTrends,
      match,
      router,
      fetchShoppingCartHeader
    } = props;
    const commodityCode = match.params.id;
    if (qs.parse(router.location.search).show_external_graph === "true")
      fetchCommodityGraph(commodityCode);
    fetchShoppingCart();
    fetchTrends();
    fetchNomenclatures(commodityCode, router.location.search);
    fetchShoppingCartHeader(commodityCode);
  };

  componentDidMount() {
    this.fetchData(this.props);
    this.props.fetchInitialFilters();
    this.props.fetchSuppliers(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevCommodityCode = prevProps.match.params.id;
    const commodityCode = this.props.match.params.id;
    if (prevCommodityCode !== commodityCode) {
      this.fetchData(this.props);
      this.props.fetchSuppliers(this.props.match.params.id);
    }
  }

  render() {
    const {
      errors,
      fetching,
      nomenclatures,
      router,
      selectedCommodity,
      suppliers,
      filterActions,
      shoppingCart,
      saveShoppingCart,
      mutations,
      trends,
      nomenclatureDrillDown,
      nomenclatureEfficiencies,
      history,
    } = this.props;
    const nomenclatureFilter = getFilterParamsFromSearchUrl(
      router.location.search
    );
    const commodityState = getCommodityState(
      selectedCommodity.code,
      shoppingCart
    );
    const isCommodityFinished = isFinished(commodityState);

    const CommodityStateButton = () => (
      <CommodityStateActionButton
        state={commodityState}
        data={nomenclatures}
        trends={trends}
        isSaving={mutations.savingShoppingCart[selectedCommodity.code]}
        commodityCode={selectedCommodity.code}
        saveShoppingCart={saveShoppingCart}
        params={getFilterParamsFromSearchUrl(router.location.search)}
        onOkRedirect={!isCommodityFinished && (
          () => history.push(
            selectedCommodity.next
              ? commodityUrl(selectedCommodity.next.code, {
                bdd1: selectedCommodity.next.bdd,
                top_150: nomenclatureFilter.top_150,
              })
              : '/?index'
          )
        )}
      />
    );

    return (
      <div className="CommodityPage">
        <Header />
        <ShoppingCart
          shoppingCart={shoppingCart}
          isFetching={fetching.shoppingCart}
          error={errors.shoppingCart}
        />
        <div className="fixedMaxSize">
          <section className="commodityNameHeading">
            <Link className="backLink" to={`/?${qs.stringify({ top_150: nomenclatureFilter.top_150 })}`}>
              <ChevronLeft className="backIcon" size="24" />
              Zpět
            </Link>
            <h1>
              {fetching.nomenclatures ? (
                "Načítání..."
              ) : (
                <React.Fragment>
                  <div>
                    <span>{selectedCommodity.code}</span>{" "}
                    <span className="name">{selectedCommodity.name}</span>
                  </div>
                  <div className="commodityNavBtns">
                    {selectedCommodity.prev && (
                      <ChevronLeft
                        className="prevCommodityBtn"
                        size="24"
                        title="Předchozí komodita"
                        onClick={() =>
                          history.push(
                            commodityUrl(selectedCommodity.prev.code, {
                              bdd1: selectedCommodity.prev.bdd,
                              top_150: nomenclatureFilter.top_150,
                            })
                          )
                        }
                      />
                    )}
                    {selectedCommodity.next && (
                      <ChevronRight
                        className="nextCommodityBtn"
                        size="24"
                        title="Další komodita"
                        onClick={() =>
                          history.push(
                            commodityUrl(selectedCommodity.next.code, {
                              bdd1: selectedCommodity.next.bdd,
                              top_150: nomenclatureFilter.top_150,
                            })
                          )
                        }
                      />
                    )}
                  </div>
                </React.Fragment>
              )}
            </h1>
            <Link
              className="reportLink"
              to={`/commodity-report/${selectedCommodity.code}`}
            >
              <div className="reportLinkIcon">
                <FileText className="" size="16" />
              </div>
              Report
            </Link>
          </section>
          <section className="Nomenclatures">
            <CommoditySuppliers suppliers={suppliers}  />
            <div className="heading iconTextWrap">
              <div className="icon">
                <HardDrive color="#FFF" size="18" />
              </div>
              <h2>Nomenklatury</h2>
            </div>
            <Table
              columns={this.columns(nomenclatures, trends, isCommodityFinished)}
              commodityStateButton={CommodityStateButton}
              cellRenderers={this.cellRenderers({
                isFinished: isCommodityFinished,
                nomenclatureFilter,
                nomenclatureDrillDown,
                fetching,
                nomenclatureEfficiencies,
                data: nomenclatures,
                trends,
                selectedCommodity,
              })}
              fetching={fetching}
              error={errors}
              data={nomenclatures}
              selectedCommodity={selectedCommodity}
              nomenclatureFilter={nomenclatureFilter}
              filterActions={filterActions}
            />
            <div className="shoppingActions">
              <CommodityStateButton />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

Commodity.propTypes = {
  commodityGraphUrl: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  fetching: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
  nomenclatures: PropTypes.array.isRequired,
  nomenclatureDrillDown: PropTypes.object.isRequired,
  nomenclatureEfficiencies: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  selectedCommodity: PropTypes.object.isRequired,
  shoppingCart: PropTypes.object.isRequired,
  fetchNomenclatures: PropTypes.func.isRequired,
  fetchSuppliers: PropTypes.func.isRequired,
  fetchNomenclatureExpediting: PropTypes.func.isRequired,
  fetchNomenclatureInProduction: PropTypes.func.isRequired,
  fetchCommodityGraph: PropTypes.func.isRequired,
  fetchInitialFilters: PropTypes.func.isRequired,
  fetchShoppingCart: PropTypes.func.isRequired,
  fetchShoppingCartHeader: PropTypes.func.isRequired,
  saveShoppingCartItem: PropTypes.func.isRequired,
  saveShoppingCart: PropTypes.func.isRequired,
  changeForOrderAmount: PropTypes.func.isRequired,
  changeForOrderAmountBatching: PropTypes.func.isRequired,
  saveShoppingCartItems: PropTypes.func.isRequired
};

export default connect(
  state => ({
    commodityGraphUrl: state.commodityGraphUrl,
    errors: state.errors,
    fetching: state.fetching,
    mutations: state.mutations,
    trends: state.trends,
    nomenclatures: prepareNomenclaturesData(state),
    suppliers: state.suppliers,
    nomenclatureDrillDown: state.nomenclatureDrillDown,
    nomenclatureEfficiencies: state.nomenclatureEfficiencies,
    router: state.router,
    selectedCommodity: state.selectedCommodity,
    shoppingCart: state.shoppingCart
  }),
  dispatch => ({
    filterActions: {
      setPredictionFilter: (commodityCode, predictionFrom, predictionTo) =>
        dispatch(fetchSalesPlan(commodityCode, predictionFrom, predictionTo)),
      setPurchaseFilter: (commodityCode, purchaseFrom, purchaseTo) =>
        dispatch(
          fetchHistoricPurchase(commodityCode, purchaseFrom, purchaseTo)
        ),
      setFilterParamsAndFetch: filterParams =>
        dispatch(setFilterParamsAndFetchNomenclatures(filterParams)),
      setFilterParams: filterParams => dispatch(setFilterParams(filterParams))
    },
    fetchInitialFilters: () => dispatch(fetchInitialFilters()),
    fetchNomenclatures: (commodityCode, filterParams) =>
      dispatch(fetchInitialNomenclatures(commodityCode, filterParams)),
    fetchSuppliers: (commodityCode) =>
      dispatch(fetchSuppliers(commodityCode)),
    fetchNomenclatureExpediting: (nomenclatureId, bdd) =>
      dispatch(fetchNomenclatureExpediting(nomenclatureId, bdd)),
    fetchNomenclatureInProduction: (nomenclatureId, bdd) =>
      dispatch(fetchNomenclatureInProduction(nomenclatureId, bdd)),
    fetchCommodityGraph: commodityCode =>
      dispatch(fetchCommodityGraph(commodityCode)),
    fetchShoppingCart: () => dispatch(fetchShoppingCart()),
    fetchShoppingCartHeader: commodityCode =>
      dispatch(fetchShoppingCartHeader(commodityCode)),
    saveShoppingCartItem: (nomenclatureId, amount, bdd1) =>
      dispatch(saveShoppingCartItem(nomenclatureId, amount, bdd1)),
    saveShoppingCart: (commodityCode, state, opts) =>
      dispatch(saveShoppingCartHeader(commodityCode, state, opts)),
    changeNomenclature: (nomenclatureId, amount) =>
      dispatch(changeNomenclature(nomenclatureId, amount)),
    changeNomenclatureProp: (nomenclatureId, payload) =>
      dispatch(changeNomenclatureProp(nomenclatureId, payload)),
    changeForOrderAmount: (nomenclatureId, amount) =>
      dispatch(changeForOrderAmount(nomenclatureId, amount)),
    changeForOrderAmountBatching: payload =>
      dispatch(changeForOrderAmountBatching(payload)),
    saveShoppingCartItems: (payload, override, bdd1) =>
      dispatch(saveShoppingCartItems(payload, override, bdd1)),
    fetchTrends: () => dispatch(fetchTrends()),
  })
)(withRouter(Commodity));
