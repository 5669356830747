import React from "react";
import "./NumberInput.css";
import PropTypes from "prop-types";
import { formatNumber } from "../../lib/numbers";

class NumberInput extends React.Component {
  constructor() {
    super();
    this.state = { focused: false };
    this.inputRef = React.createRef();
  }

  validate = value => {
    const validation = this.props.validation;
    if (typeof validation === "function") {
      const error = validation(value);
      if (error) {
        this.setState({ error });
        return false;
      } else {
        this.setState({ error: false });
        return true;
      }
    } else {
      return true;
    }
  };

  onBlur = value => {
    this.validate(value) && this.props.onBlur(value);
    this.setState({ focused: false });
  };

  errorAction = (value, error) => {
    if (typeof error.action === "function") {
      const newVal = error.action();
      this.onBlur(newVal);
    }
  };

  onKeyPress = e => {
    if (e.key === "Enter") this.inputRef.current.blur();
  };

  onClick = () => {
    this.setState({ focused: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.focused && !prevState.focused) {
      this.inputRef.current.select();
    }
  }

  render() {
    const { step, value, onChange, className, max } = this.props;
    const { error, focused } = this.state;
    const errorBadge = error && (
      <div
        className="errorBadge"
        title={error.message}
        onClick={() => this.errorAction(value, error)}
      >
        !
      </div>
    );

    return (
      <div className="NumberInput" title={this.props.title}>
        <input
          type={focused ? "number" : "text"}
          ref={this.inputRef}
          value={focused ? value : formatNumber(value)}
          min="0"
          max={max}
          step={step}
          onClick={e => this.onClick(e)}
          onChange={e => onChange(e.target.value)}
          onKeyPress={e => this.onKeyPress(e)}
          onFocus={e => this.setState({ focused: true })}
          onBlur={e => this.onBlur(e.target.value || 0)}
          className={className}
        />
        {errorBadge}
      </div>
    );
  }
}

NumberInput.propTypes = {
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
};

export default NumberInput;
