import * as types from "./actionTypes";
import {
  fetchWithDispatch,
  fetchWithBodyAndDispatch,
} from "../lib/fetch";

export const trendsRequest = () => ({
  type: types.FETCH_TRENDS_REQUEST,
});

export const trendsReceived = (trends) => ({
  type: types.FETCH_TRENDS_SUCCESS,
  trends
});

export const fetchingTrendsFailed = (error) => ({
  type: types.FETCH_TRENDS_FAILURE,
  error
});

export const trendUpdated = (trend) => ({
  type: types.FETCH_TREND_PATCH_SUCCESS,
  trend
});

export const trendUpdateFailed = (error) => ({
  type: types.FETCH_TREND_PATCH_FAILURE,
  error
});

export const changeTrend = (trend) => ({
  type: types.CHANGE_TREND,
  trend
})

export const fetchTrends = () => (dispatch, getState, { apiUrl }) => {
  dispatch(trendsRequest());
  fetchWithDispatch(`${apiUrl}/trend`,
    json => dispatch(trendsReceived(json)),
    error => dispatch(fetchingTrendsFailed(error))
  );
};

export const saveTrend = (trend) => (dispatch, getState, { apiUrl }) => {
  if (!trend.dirty) return
  console.log({trend})
  dispatch({
    type: types.FETCH_TREND_PATCH_REQUEST,
    payload: trend,
  });
  fetchWithBodyAndDispatch(`${apiUrl}/trend/${trend.code}`,
    { method: 'PATCH', body: {
      min_value: parseInt(trend.min_value !== undefined ? trend.min_value : 9, 10),
      max_value: parseInt(trend.max_value !== undefined ? trend.max_value : 9, 10),
    } },
    json => dispatch(trendUpdated(Object.assign(trend, { dirty: false }))),
    error => dispatch(trendUpdateFailed(error))
  );
};