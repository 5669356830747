import * as types from "../actions/actionTypes";
import _ from "lodash";
import { combineReducers } from "redux";

const inProduction = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_SUCCESS:
      return Object.assign({}, _.set(state, action.nomenclatureId, action.response));
    default:
      return state;
  }
};


const expediting = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_EXPEDITING_SUCCESS:
      return Object.assign({}, _.set(state, action.nomenclatureId, action.response));
    default:
      return state;
  }
};

const nomenclatureDrillDown = combineReducers({
  inProduction,
  expediting
});


export default nomenclatureDrillDown;
