import React from "react";
import Select from "react-select";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";
import { Percent } from "react-feather";

import MonthPicker from "./MonthPicker";
import NumberInputWithButtons from "./NumberInputWithButtons";

import { callFnFromNestedObj, dumpError } from "../../lib/utils";
import { trackEvent } from "../../lib/ga";
import { formatNumber } from "../../lib/numbers";

import "./Table.css";

/**
 * Row with cells, it uses cell renderer instead of raw value from data
 */
const Row = ({ columns, cellRenderers, data, isOdd }) => {
  const value = col =>
    callFnFromNestedObj(cellRenderers, col.name, "value", data);
  const className = col =>
    callFnFromNestedObj(cellRenderers, col.name, "className", data);

  const cells = columns.map(col => {
    const val = value(col) || formatNumber(data[col.name]);
    return (
      <td
        key={col.name}
        className={classNames(col.className, className(col), {
          stickyBottom: data.is_total_sum
        })}
      >
        {data.is_sum ? <b>{val}</b> : val}
      </td>
    );
  });

  return (
    <tr
      className={classNames("dataRow", {
        isOdd,
        sumRow: data.is_sum,
        totalSumRow: data.is_total_sum,
        stickyBottom: data.is_total_sum
      })}
    >
      {cells}
    </tr>
  );
};

Row.propTypes = {
  columns: PropTypes.array.isRequired,
  cellRenderers: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isOdd: PropTypes.bool.isRequired
};

/**
 * renders content/loader/error
 */
class Tbody extends React.PureComponent {
  render() {
    const { columns, cellRenderers, data, fetching, error } = this.props;
    const fetchingNomenclatures = fetching.nomenclatures;
    let content;
    if (error.nomenclatures) {
      content = (
        <tr>
          <td colSpan="11">{dumpError(error.nomenclatures)}</td>
        </tr>
      );
    } else if (fetchingNomenclatures && _.isEmpty(data)) {
      content = (
        <tr className="fetchingText">
          <td colSpan="11">Načítání nomenklatur...</td>
        </tr>
      );
    } else if (data.length > 0) {
      content = data.map((row, idx) => (
        <Row
          columns={columns}
          cellRenderers={cellRenderers}
          data={row}
          isOdd={(idx + 1) % 2 !== 0}
          key={row.is_sum ? `sum-${row.color_code}` : row.nomenclature_id}
        />
      ));
    }

    return (
      <tbody
        className={classNames({
          fetching: !_.isEmpty(data) && fetchingNomenclatures
        })}
      >
        {content}
      </tbody>
    );
  }
}

Tbody.propTypes = {
  columns: PropTypes.array.isRequired,
  cellRenderers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  fetching: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired
};

const top150Opts = [
  { value: true, name: 'Top 150 Ano' },
  { value: false, name: 'Top 150 Ne' }
];

/**
 * Nomenclatures table
 * renders table with header and appropriate content
 */
const Table = ({
  columns,
  commodityStateButton: CommodityStateButton,
  cellRenderers,
  data,
  nomenclatureFilter,
  fetching,
  error,
  selectedCommodity,
  filterActions
}) => {
  const {
    bdd1,
    top_150,
    prediction_from,
    prediction_to,
    purchase_from,
    purchase_to,
    show_color_percentage
  } = nomenclatureFilter;
  const {
    setPredictionFilter,
    setPurchaseFilter,
    setFilterParamsAndFetch
  } = filterActions;
  return (
    <div>
      <table className="Table nomenclature">
        <thead>
          <tr className=" filterRow">
            <th className="sticky rightThickBorder" style={{ padding: '0 1rem' }}>
              <Select
                id="top_150"
                options={top150Opts}
                value={top_150}
                onChange={e =>
                  setFilterParamsAndFetch({ top_150: e ? e.value : undefined })
                }
                labelKey="name"
                valueKey="value"
                placeholder="Top 150: Vyberte hodnotu"
                className="width100"
              />
            </th>
            <th colSpan="5" className="sticky filterLabel rightThickBorder">
              <NumberInputWithButtons
                label="Bod dodání (BD)"
                key={bdd1}
                bdd={bdd1}
                setBDD={bdd1 => {
                  trackEvent("commodity-table-filters", "bdd1-changed", bdd1);
                  setFilterParamsAndFetch({ bdd1 });
                }}
              />
            </th>
            <th className="sticky rightBorder">
              <div className="multipleIcons">
                <MonthPicker
                  label="Predikce/Plán CM"
                  from={prediction_from}
                  to={prediction_to}
                  maxYear={new Date().getFullYear() + 2}
                  onChange={(from, to) => {
                    trackEvent(
                      "commodity-table-filters",
                      "prediction-date-changed",
                      `${from}-${to}`
                    );
                    setPredictionFilter(selectedCommodity.code, from, to);
                  }}
                />
                <div className="percentageIcon">
                  <Percent
                    className=""
                    size="20"
                    color="#445e9c"
                    onClick={() => {
                      const willShowColorPercentages = !show_color_percentage;
                      trackEvent(
                        "commodity-table-filters",
                        "toggle-color-percentages",
                        willShowColorPercentages
                      );
                      filterActions.setFilterParams({
                        show_color_percentage: willShowColorPercentages
                      });
                    }}
                  />
                </div>
              </div>
            </th>
            <th className="sticky rightThickBorder">
              <MonthPicker
                label="Historický prodej"
                from={purchase_from}
                to={purchase_to}
                onChange={(from, to) => {
                  trackEvent(
                    "commodity-table-filters",
                    "historic-purchase-date-changed",
                    `${from}-${to}`
                  );
                  setPurchaseFilter(selectedCommodity.code, from, to);
                }}
              />
            </th>
            <th colSpan="4" className="sticky filterLabel align-center">
              <CommodityStateButton />
              {/*<NumberInputWithButtons
                label="Očekávaná výstačnost"
                key={bdd2}
                bdd={bdd2}
                setBDD={bdd2 => {
                  trackEvent("commodity-table-filters", "bdd2-changed", bdd2);
                  setFilterParamsAndFetch({ bdd2 });
                }}
              />*/}
            </th>
          </tr>
          <tr className="headerRow">
            {columns.map(col => (
              <th
                className={classNames("sticky-row2 columnTitle", col.className)}
                key={col.name}
              >
                {col.label({ ...nomenclatureFilter, data, selectedCommodity })}
              </th>
            ))}
          </tr>
        </thead>
        <Tbody
          columns={columns}
          cellRenderers={cellRenderers}
          fetching={fetching}
          error={error}
          data={data}
        />
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  cellRenderers: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  fetching: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired
};

export default Table;
