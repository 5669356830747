import * as types from "../actions/actionTypes";
import { combineReducers } from "redux";

const categories = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_CATEGORY_REQUEST:
    case types.FETCH_CATEGORY_SUCCESS:
      return false;
    case types.FETCH_CATEGORY_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const commodities = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITIES_SUCCESS:
    case types.FETCH_COMMODITIES_REQUEST:
      return false;
    case types.FETCH_COMMODITIES_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const trends = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_TRENDS_SUCCESS:
    case types.FETCH_TRENDS_REQUEST:
      return false;
    case types.FETCH_TRENDS_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const commodityGraphUrl = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITY_GRAPH_SUCCESS:
    case types.FETCH_COMMODITY_GRAPH_REQUEST:
      return false;
    case types.FETCH_COMMODITY_GRAPH_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const filters = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_INITIAL_FILTERS_SUCCESS:
    case types.FETCH_INITIAL_FILTERS_REQUEST:
      return false;
    case types.FETCH_INITIAL_FILTERS_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const historicPurchase = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_HISTORIC_PURCHASE_SUCCESS:
    case types.FETCH_HISTORIC_PURCHASE_REQUEST:
      return false;
    case types.FETCH_HISTORIC_PURCHASE_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const nomenclatureDrillDownInProduction = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_SUCCESS:
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_REQUEST:
      return false;
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const nomenclatureDrillDownExpediting = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_EXPEDITING_SUCCESS:
    case types.FETCH_NOMENCLATURE_EXPEDITING_REQUEST:
      return false;
    case types.FETCH_NOMENCLATURE_EXPEDITING_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const nomenclatures = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURES_SUCCESS:
    case types.FETCH_NOMENCLATURES_REQUEST:
      return false;
    case types.FETCH_NOMENCLATURES_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const salesPlan = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SALES_PLAN_SUCCESS:
    case types.FETCH_SALES_PLAN_REQUEST:
      return false;
    case types.FETCH_SALES_PLAN_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const deleteShoppingCartHeader = (state = false, action) => {
  switch (action.type) {
    case types.DELETE_SHOPPING_CART_HEADER_SUCCESS:
    case types.DELETE_SHOPPING_CART_HEADER_REQUEST:
      return false;
    case types.DELETE_SHOPPING_CART_HEADER_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const shoppingCart = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_SUCCESS:
    case types.FETCH_SHOPPING_CART_REQUEST:
      return false;
    case types.FETCH_SHOPPING_CART_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const shoppingCartHeader = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_HEADER_SUCCESS:
    case types.FETCH_SHOPPING_CART_HEADER_REQUEST:
      return false;
    case types.FETCH_SHOPPING_CART_HEADER_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const shoppingCartHistory = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_HISTORY_SUCCESS:
    case types.FETCH_SHOPPING_CART_HISTORY_REQUEST:
      return false;
    case types.FETCH_SHOPPING_CART_HISTORY_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const commodityReport = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITY_REPORT_SUCCESS:
    case types.FETCH_COMMODITY_REPORT_REQUEST:
      return false;
    case types.FETCH_COMMODITY_REPORT_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const suppliers = (state = false, action) => {
  switch (action.type) {
    case types.BATCH_SAVE_SUPPLIER_HEADER_SUCCESS:
    case types.BATCH_SAVE_SUPPLIER_HEADER_REQUEST:
    case types.BATCH_REMOVE_SUPPLIER_HEADER_REQUEST:
    case types.BATCH_REMOVE_SUPPLIER_HEADER_SUCCESS:
    case types.SAVE_SUPPLIER_ITEM_REQUEST:
    case types.SAVE_SUPPLIER_ITEM_SUCCESS:
      return false;
    case types.BATCH_SAVE_SUPPLIER_HEADER_FAILURE:
    case types.BATCH_REMOVE_SUPPLIER_HEADER_FAILURE:
    case types.SAVE_SUPPLIER_ITEM_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const exportToKarat = (state = false, action) => {
  switch (action.type) {
    case types.EXPORT_TO_KARAT_SUCCESS:
    case types.EXPORT_TO_KARAT_REQUEST:
      return false;
    case types.EXPORT_TO_KARAT_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const errors = combineReducers({
  categories,
  commodities,
  commodityGraphUrl,
  commodityReport,
  deleteShoppingCartHeader,
  exportToKarat,
  filters,
  historicPurchase,
  nomenclatureDrillDownExpediting,
  nomenclatureDrillDownInProduction,
  nomenclatures,
  salesPlan,
  shoppingCart,
  shoppingCartHeader,
  shoppingCartHistory,
  suppliers,
  trends,
});

export default errors;
