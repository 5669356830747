import * as types from "./actionTypes";
import { fetchWithDispatch } from "../lib/fetch";
import { dispatchFetchFilteredCommodities, quickSearch } from "./commodities";
import { push } from "react-router-redux";
import * as qs from "query-string";
import _ from "lodash";

export const fetchInitialFilters = () => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.FETCH_INITIAL_FILTERS_REQUEST,
  });

  return fetchWithDispatch(`${apiUrl}/filter`,
    json => dispatch({
      type: types.FETCH_INITIAL_FILTERS_SUCCESS,
      filterValues: json
    }),
    error => dispatch({
      type: types.FETCH_INITIAL_FILTERS_FAILURE,
      error
    })
  );
};

const appendParam = (getState, filter) => {
  const search = getState().router.location.search;
  const params = qs.parse(search, { arrayFormat: "bracket" });
  return qs.stringify({ ...params, ...filter }, { arrayFormat: "bracket" });
};

export const changeCategoryFilterAndFetchCommodities = (category) => (dispatch, getState, { apiUrl }) => {
  category = _.castArray(category);
  
  return dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl },
    () => {
      dispatch(push({ search: appendParam(getState, { category }) }));
    },
    {
      filterBrandsCb: brands => {
        if (category.length) {
          const params = qs.parse(getState().router.location.search, { arrayFormat: "bracket" });
          const filteredBrands = _.intersection(brands, params.brand)
          dispatch(push({ search: appendParam(getState, { brand: filteredBrands }) }));
        } else {
          dispatch({
            type: types.RESET_BRAND_FILTER,
          })
        }
      },
    });
};

export const changeBrandFilterAndFetchCommodities = (brand) => (dispatch, getState, { apiUrl }) => {
  brand = _.castArray(brand);
  return dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl },
    () => {
      dispatch(push({ search: appendParam(getState, { brand }) }));
    });
};

export const changeColorFilterAndFetchCommodities = (color) => (dispatch, getState, { apiUrl }) => {
  color = _.castArray(color);
  return dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl },
    () => {
      dispatch(push({ search: appendParam(getState, { color }) }));
    });
};

export const changeTop150FilterAndFetchCommodities = (top_150) => (dispatch, getState, { apiUrl }) => {
  return dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl },
    () => {
      dispatch(push({ search: appendParam(getState, { top_150 }) }));
    });
};

export const changeSizeFilterAndFetchCommodities = (size) => (dispatch, getState, { apiUrl }) => {
  size = _.castArray(size);
  return dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl },
    () => {
      dispatch(push({ search: appendParam(getState, { size }) }));
    });
};

export const changeStateFilterAndFetchCommodities = (state) => (dispatch, getState, { apiUrl }) => {
  return dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl },
    () => {
      dispatch(push({ search: appendParam(getState, { state: state || [] }) }));
    });
};

export const clearCommodityFilters = () => (dispatch, getState) => {
  dispatch(push({ search: "" }));

  const searchTerm = getState().filters.quickSearch;
  if (!_.isEmpty(searchTerm)) {
    dispatch(quickSearch(searchTerm));
  }
};

export const updateFilter = (filter) => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_FILTER,
    ...filter,
  })
}

export const fetchFilteredCommodities = () => (dispatch, getState, { apiUrl }) => {
  dispatchFetchFilteredCommodities(dispatch, getState, { apiUrl }, () => null);
};
