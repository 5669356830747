import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { session } from "../actions/authentication";
import { isPurchaser } from "../lib/user";
import "../components/reactPopup.css";
import Popup from "react-popup";

export default function requireAuth(Component) {
  class AuthenticatedComponent extends React.PureComponent {
    componentDidMount() {
      this.props.getSession();
      this.checkAuth();
    }

    checkAuth() {
      const { user } = this.props;
      if (!this.props.accessToken) {
        const location = this.props.location;
        const redirect = location.pathname + location.search;

        this.props.history.push(`/sign-in?redirect=${redirect}`);
      } else if (!isPurchaser(user)) {
        this.props.history.push("/not-authorized");
      }
    }

    render() {
      return this.props.accessToken ? (
        <>
          <Popup/>
          <Component {...this.props} />
        </>
      ) : null;
    }
  }

  function mapStateToProps(state) {
    return {
      user: state["authentication"]["user"],
      accessToken: state["authentication"]["access-token"]
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      getSession: () => dispatch(session())
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AuthenticatedComponent));
}
