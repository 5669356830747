import React from "react";
import "../App.css";
import { connect } from "react-redux";
import {
  changeCategoryFilterAndFetchCommodities,
  changeColorFilterAndFetchCommodities,
  changeTop150FilterAndFetchCommodities,
  changeSizeFilterAndFetchCommodities,
  changeStateFilterAndFetchCommodities,
  changeBrandFilterAndFetchCommodities,
  clearCommodityFilters,
  fetchFilteredCommodities,
  fetchInitialFilters,
} from "../actions/filters";
import Filters from "./Index/Filters";
import Commodities from "./Index/Commodities";
import { withRouter } from "react-router";
import Header from "../components/Header";
import ShoppingCart from "../components/ShoppingCart";
import "./Index.css";
import { fetchCategories } from "../actions/categories";
import Categories from "./Index/Categories";
import * as qs from "query-string";
import _ from "lodash";
import { fetchShoppingCart } from "../actions/shoppingCart";
import { quickSearch } from "../actions/commodities";
import { groupByCategoryName } from "../reducers/commodities";
import PropTypes from "prop-types";

const defaultBrands = [
  'MALFINI',
  'MALFINIPREMIUM',
  'PICCOLIO',
  'RIMECK'
  // 'MP',
  // 'M',
  // 'R'
];

function getTop150Filter(queryParamValue) {
  switch (queryParamValue) {
    case 'true':
      return '&top_150=true'
    case 'false':
      return '&top_150=false'
    default:
      return ''
  }
}

class Index extends React.Component {
  componentDidMount() {
    this.props.fetchInitialFilters();
    this.props.fetchShoppingCart();
    this.props.fetchCategories();
    this.props.fetchFilteredCommodities();
    
    const selectedFilters = qs.parse(this.props.location.search, { arrayFormat: "bracket" });
    // we ignore top_150 here
    const cleanedUpSelectedFilters = { ...selectedFilters };
    delete cleanedUpSelectedFilters.top_150;
    if (_.isEmpty(cleanedUpSelectedFilters) && defaultBrands.length) {
      this.props.filterActions.changeBrandFilter(defaultBrands);
    }
  }

  render() {
    const {
      errors, categories, filters, shoppingCart, filterActions, fetching, history, commodities,
      location
    } = this.props;
    const selectedFilters = qs.parse(location.search, { arrayFormat: "bracket" });

    let content = "";
    if (_.isEmpty(location.search) && _.isEmpty(filters.quickSearch)) {
      content = <Categories categories={categories}
                            error={errors.categories}
                            isFetching={fetching.categories}
                            onClick={(category) => filterActions.changeCategoryFilter(category)}/>;
    } else {
      content = <Commodities commodities={commodities}
                             error={errors.commodities}
                             isFetching={fetching.commodities}
                             selectedColors={selectedFilters.color}
                             states={shoppingCart.ShoppingCartHeaders}
                             onClick={(commodity) => {
                               const { commodity_code, commodity_bdd } = commodity;
                               history.push(`/commodity/${commodity_code}?bdd1=${commodity_bdd}${getTop150Filter(selectedFilters.top_150)}`);
                             }}/>;
    }

    return (
      <div>
        <Header/>
        <ShoppingCart shoppingCart={shoppingCart}
                      isFetching={fetching.shoppingCart}
                      error={errors.shoppingCart}/>
        <div className="fixedMaxSize">
          <Filters filters={filters}
                   selectedFilters={selectedFilters}
                   error={errors.filters}
                   fetching={fetching.filters}
                   filterActions={filterActions}/>
          {content}
        </div>
      </div>
    );
  }
}

Index.propTypes = {
  categories: PropTypes.array.isRequired,
  commodities: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  fetching: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  fetchShoppingCart: PropTypes.func.isRequired,
  fetchInitialFilters: PropTypes.func.isRequired,
  fetchFilteredCommodities: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    categories: state.categories,
    commodities: groupByCategoryName(state),
    errors: state.errors,
    fetching: state.fetching,
    filters: state.filters,
    shoppingCart: state.shoppingCart,
  }),
  dispatch => ({
    filterActions: {
      changeCategoryFilter: (category) => dispatch(changeCategoryFilterAndFetchCommodities(category)),
      changeTop150Filter: (top150) => dispatch(changeTop150FilterAndFetchCommodities(top150)),
      changeColorFilter: (color) => dispatch(changeColorFilterAndFetchCommodities(color)),
      changeSizeFilter: (size) => dispatch(changeSizeFilterAndFetchCommodities(size)),
      changeStateFilter: (state) => dispatch(changeStateFilterAndFetchCommodities(state)),
      changeBrandFilter: (brand) => dispatch(changeBrandFilterAndFetchCommodities(brand)),
      quickSearch: (searchTerm) => dispatch(quickSearch(searchTerm)),
      clearCommodityFilters: () => dispatch(clearCommodityFilters()),
    },
    fetchCategories: () => dispatch(fetchCategories()),
    fetchShoppingCart: () => dispatch(fetchShoppingCart()),
    fetchInitialFilters: () => dispatch(fetchInitialFilters()),
    fetchFilteredCommodities: () => dispatch(fetchFilteredCommodities()),
  })
)(withRouter(Index));
