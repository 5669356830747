import * as types from "./actionTypes";
import * as qs from "query-string";
import { fetchWithDispatch } from "../lib/fetch";
import { setFilterParams } from "./nomenclatures";

export const fetchSalesPlan = (commodityCode, predictionFrom, predictionTo) => (dispatch, getState, { apiUrl }) => {
  dispatch(setFilterParams({ prediction_from: predictionFrom, prediction_to: predictionTo }));

  dispatch({
    type: types.FETCH_SALES_PLAN_REQUEST,
  });

  const params = qs.stringify({
    commodity_code: commodityCode,
    prediction_from: predictionFrom,
    prediction_to: predictionTo,
  });
  return fetchWithDispatch(`${apiUrl}/sales-plan?${params}`,
    json => dispatch({
      type: types.FETCH_SALES_PLAN_SUCCESS,
      response: json
    }),
    error => dispatch({
      type: types.FETCH_SALES_PLAN_FAILURE,
      error,
    }));
};
