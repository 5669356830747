import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "./reducers";

const configureStore = (history) => {
  const middlewares = [
    thunk.withExtraArgument({ apiUrl: process.env.REACT_APP_API_HOST || "" }),
    routerMiddleware(history)
  ];

  // if (process.env.NODE_ENV === `development`) {
  //   const { logger } = require(`redux-logger`);
  //   middlewares.push(logger);
  // }

  return createStore(
    rootReducer,
    applyMiddleware(...middlewares)
  );
};

export default configureStore;
