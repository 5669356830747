import * as types from "../actions/actionTypes";
import _ from "lodash";

const commodityReport = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITY_REPORT_SUCCESS:
      const ovs = action.response.order_inquiries
        .filter(oi => oi.type === "ORDER")
        .reduce((m, oi) => {
          m[oi.reference_number] = true;
          return m;
        }, {});
      const orderInquiries = _(action.response.order_inquiries)
        .groupBy(oi => oi.type)
        .value();

      let nomenclatures = action.response.nomenclatures.map(n => {
        const storageState = n.storage_state ? n.storage_state.amount : 0;
        n.sum =
          Object.values(n.cells).reduce(
            (sum, amount) => sum + parseInt(amount, 10),
            0
          ) + storageState;
        n.storage_state_plus_ov =
          Object.entries(n.cells).reduce((sum, [id, amount]) => {
            return ovs[id] ? sum + parseInt(amount, 10) : sum;
          }, 0) + storageState;
        return n;
      }, []);

      const sumsByColor = _(nomenclatures).reduce((acc, nom) => {
        acc = _.defaultsDeep(acc, {
          [nom.color_code]: {
            cells: {},
            sum: 0,
            average_month_sale: 0,
            storage_state_plus_ov: 0,
            storage_state: { amount: 0 },
            color_code: nom.color_code,
            is_sum: true,
            sort_label: -1
          }
        });
        Object.entries(nom.cells).map(([colId, amount]) => {
          if (!acc[nom.color_code]["cells"][colId])
            acc[nom.color_code]["cells"][colId] = 0;

          return (acc[nom.color_code]["cells"][colId] += amount
            ? parseInt(amount, 10)
            : 0);
        });
        acc[nom.color_code]["sum"] += nom.sum;
        acc[nom.color_code]["average_month_sale"] += nom.average_month_sale;
        acc[nom.color_code]["storage_state_plus_ov"] +=
          nom.storage_state_plus_ov;
        acc[nom.color_code]["storage_state"]["amount"] += nom.storage_state
          ? nom.storage_state.amount
          : 0;
        return acc;
      }, {});

      const totalSum = _(nomenclatures).reduce((acc, nom) => {
        acc = _.defaultsDeep(acc, {
          cells: {},
          sum: 0,
          average_month_sale: 0,
          storage_state_plus_ov: 0,
          storage_state: { amount: 0 },
          is_total_sum: true,
          sort_label: 999
        });
        Object.entries(nom.cells).map(([colId, amount]) => {
          if (!acc["cells"][colId]) acc["cells"][colId] = 0;

          return (acc["cells"][colId] += amount ? parseInt(amount, 10) : 0);
        });
        acc["sum"] += nom.sum;
        acc["average_month_sale"] += nom.average_month_sale;
        acc["storage_state_plus_ov"] += nom.storage_state_plus_ov;
        acc["storage_state"]["amount"] += nom.storage_state
          ? nom.storage_state.amount
          : 0;
        return acc;
      }, {});

      nomenclatures = _(nomenclatures)
        .concat(_.values(sumsByColor))
        .push(totalSum)
        .orderBy(["sort_label", "id"], ["desc", "asc"])
        .groupBy("color_code")
        .sortBy(arr => {
          const sumRow = _(arr).last();
          // place total sum row at the end
          return sumRow.is_total_sum ? 1 : -1;
        })
        .flatten()
        .sortBy(nom => nom.color_code)
        .value();
      return { ...action.response, orderInquiries, nomenclatures };
    default:
      return state;
  }
};

export default commodityReport;
