import React from "react";
import Index from "../screens/Index";
import { Switch } from "react-router-dom";
import { Provider } from "react-redux";
import Commodity from "../screens/Commodity";
import CommodityReport from "../screens/CommodityReport";
import createHistory from "history/createBrowserHistory";
import { ConnectedRouter } from "react-router-redux";
import NoMatch from "../screens/NoMatch";
import ShoppingCartPage from "../screens/ShoppingCartPage";
import "../App.css";
import PurchaseHistory from "../screens/PurchaseHistory";
import CommodityAdministration from "../screens/CommodityAdministration";
import SignIn from "../screens/SignIn";
import configureStore from "../configureStore";
import ScrollToTopRoute from "./ScrollToTopRoute";
import requireAuth from "./requireAuth";
import NotAuthorized from "../screens/NotAuthorized";
import Supplier from "../screens/Supplier";
import SuppliersOverview from "../screens/SuppliersOverview";

const history = createHistory();
const store = configureStore(history);

history.listen(location => {
  if (window.ga) {
    window.ga("send", "pageview", `${location.pathname}${location.search}`);
  }
});

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <ScrollToTopRoute exact path="/" component={requireAuth(Index)} />
        <ScrollToTopRoute exact path="/sign-in" component={SignIn} />
        <ScrollToTopRoute
          path="/commodity/:id/assign-supplier"
          component={requireAuth(Supplier)}
        />
        <ScrollToTopRoute
          path="/suppliers-overview"
          component={requireAuth(SuppliersOverview)}
        />
        <ScrollToTopRoute
          path="/commodity/:id"
          component={requireAuth(Commodity)}
        />
        <ScrollToTopRoute
          path="/commodity-report/:id"
          component={requireAuth(CommodityReport)}
        />
        <ScrollToTopRoute
          path="/purchase-history"
          component={requireAuth(PurchaseHistory)}
        />
        <ScrollToTopRoute
          path="/commodity-administration"
          component={requireAuth(CommodityAdministration)}
        />
        <ScrollToTopRoute
          path="/shopping-cart"
          component={requireAuth(ShoppingCartPage)}
        />
        <ScrollToTopRoute
          path="/kaboom"
          component={() => {
            return (
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  throw new Error("Kaboom!!!");
                }}
              >
                Kaboom
              </a>
            );
          }}
        />
        <ScrollToTopRoute component={NoMatch} />
        <ScrollToTopRoute
          exact
          path="/not-authorized"
          component={NotAuthorized}
        />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default Root;
