import * as qs from "query-string";

export const productImageUrl = (commodityCode, colorCode) =>
  `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/${commodityCode}/${commodityCode}_${colorCode}_c~h210.jpg`;

export const colorImageUrl = (colorCode) =>
  `${process.env.REACT_APP_SHARED_IMAGES_URL}/color/base/${colorCode}.jpg`;

export const shoppingCartExportUrl = (shoppingCart) =>
  `${process.env.REACT_APP_API_HOST}/shopping-cart/${shoppingCart.id}/export`;

export const shoppingCartExportProposalUrl = (shoppingCart) =>
  `${process.env.REACT_APP_API_HOST}/shopping-cart/${shoppingCart.id}/export-proposal`;

export const shoppingCartHeaderExportUrl = (shoppingCart, commodityCode, excludeZero = true) =>
  `${process.env.REACT_APP_API_HOST}/shopping-cart/${shoppingCart.id}/header/${commodityCode}/export?exclude-zero=${excludeZero}`;

export const commodityUrl = (commodityCode, params = {}) =>
  `/commodity/${commodityCode}?${qs.stringify(params)}`;