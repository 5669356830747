import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import Popup from "react-popup";
import PropTypes from "prop-types";
import {Check, AlertOctagon, ChevronLeft, Edit2, Info, X} from "react-feather";

import Header from "../components/Header";
import { ShoppingCartState } from "../components/ShoppingCart";
import { countsByState } from "../lib/shoppingCart";
import {
  batchChangeState,
  batchRemove,
  fetchShoppingCart,
  saveShoppingCart, startApproval
} from "../actions/shoppingCart";
import { STATES } from "../lib/commodity";
import "../components/reactContextMenu.css";
import {
  deleteShoppingCartHeader,
  saveShoppingCartHeader
} from "../actions/shoppingCartHeader";
import ClosePurchasePopup from "./ShoppingCartPage/ClosePurchasePopup";
import CommodityItem from "./ShoppingCartPage/CommodityItem";
import { shoppingCartExportUrl } from "../lib/urls";
import { trackEvent } from "../lib/ga";
import GlobalErrors from "../components/GlobalErrors";

import IconZeroFinished from "../images/icon-zero-finished.svg";

import "./ShoppingCartPage.css";
import "./Commodity.css";

const SECTION_TYPES = {
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  FINISHED_ZERO: "FINISHED_ZERO"
};

function selectionLabel(selectedCount) {
  let suffix = "";
  if (selectedCount === 1) {
    suffix = "u";
  } else if (selectedCount >= 2 && selectedCount <= 4) {
    suffix = "y";
  }
  return `Editovat ${selectedCount} komodit${suffix}:`;
}

function SubHeader({ selectedCommodities, setSelectedCommodities, dispatch }) {
  const selected = Object.values(selectedCommodities);
  const commodities = Object.keys(selectedCommodities);
  const selectedCount = selected.length;
  const canSwitchToFinished =
    selectedCount &&
    _.every(selected, s => s.type === SECTION_TYPES.IN_PROGRESS);
  const canSwitchToInProgress =
    selectedCount &&
    _.every(selected, s => s.type !== SECTION_TYPES.IN_PROGRESS);

  return (
    <section>
      <div className="backToSelectingCommodities">
        <div className="fixedWrap">
          <div className="backToSelectingCommoditiesInner">
            <Link className="backLink" to="/">
              <ChevronLeft className="backIcon" size="24"/>
              Zpět na výběr komodit
            </Link>
          </div>
          {selectedCount !== 0 && (
            <div className="bulkActions">
              <div className="font-semibold">{selectionLabel(selectedCount)}</div>
              {(canSwitchToFinished || canSwitchToInProgress) && (
                <button
                  className="actionBtn"
                  onClick={() => {
                    dispatch(
                      batchChangeState(
                        commodities,
                        canSwitchToFinished ? STATES.FINISHED : STATES.IN_PROGRESS
                      )
                    );
                    setSelectedCommodities({});
                  }}
                >
                  Označit jako {canSwitchToFinished ? "dokončen" : "rozdělan"}
                  {selectedCount > 1 ? "é" : "ou"}
                  {canSwitchToFinished ? (
                    <Check className="actionIcon" color="#009845"/>
                  ) : (
                    <Edit2 className="actionIcon" color="#FFAF00"/>
                  )}
                </button>
              )}
              <button
                className="actionBtn"
                style={{ color: "#EF1D1D" }}
                onClick={() => {
                  dispatch(batchRemove(commodities));
                  setSelectedCommodities({});
                }}
              >
                Vyhodit z košíku <X className="actionIcon" color="#EF1D1D"/>
              </button>
            </div>
          )}
        </div>
      </div>
      <GlobalErrors/>
    </section>
  );
}

function ShoppingCartPage({
                            errors,
                            fetching,
                            shoppingCart,
                            menuActions,
                            saveShoppingCart,
                            dispatch,
                            fetchShoppingCart,
                            keboola
                          }) {
  useEffect(() => {
    fetchShoppingCart();
  }, []);
  const [selectedCommodities, setSelectedCommodities] = useState({});
  const selectCommodity = (commodityCode, type, selected) => {
    setSelectedCommodities(prev => {
      if (selected) {
        return { ...prev, [commodityCode]: { type } };
      } else {
        const { [commodityCode]: deleted, ...rest } = prev;
        return rest;
      }
    });
  };
  const { inProgress } = countsByState(shoppingCart);
  const commoditiesByState = _(shoppingCart.ShoppingCartHeaders).groupBy(
    "state"
  );
  const inProgressCommodities = commoditiesByState.get(STATES.IN_PROGRESS, []);
  const finishedCommodities = commoditiesByState
    .get(STATES.FINISHED, [])
    .filter(c => c && c.items_amount > 0);
  const finishedZeroCommodities = commoditiesByState
    .get(STATES.FINISHED, [])
    .filter(c => !c || (c && c.items_amount === 0));

  const dataExport = (keboola && keboola.export) ? keboola.export : false;
  const exportInProgress =
      dataExport &&
      (dataExport.status === "waiting" || dataExport.status === "processing");
  const exportFailed = dataExport && dataExport.status === "error";

  let dataExportMsg = dataExport && dataExport.finished_at && (
      <p>
        Poslední export dat do karatu{" "}
        {moment(dataExport.finished_at).format("D. M. YYYY HH:mm")}
      </p>
  );
  if (exportInProgress) {
    dataExportMsg = <p>Probíhá export dat do karatu. Akce může trvat 3 - 10 minut.</p>;
  }
  else if (exportFailed) {
    dataExportMsg = <p>
      Poslední export dat do karatu z{" "}
      {moment(dataExport.created_at).format("D. M. YYYY")}{" "}
      <strong>selhala</strong>. Zkuste to znovu.
    </p>
  }

  console.log({shoppingCart})

  return (
    <div className="ShoppingCartPage">
      <Header/>
      <SubHeader
        selectedCommodities={selectedCommodities}
        setSelectedCommodities={setSelectedCommodities}
        dispatch={dispatch}
      />

      <div className="fixedMaxSize">
        <section className="shoppingCartHeading">
          <h1>Nákupní košík</h1>
          <div className="shoppingCartStats">
            <ShoppingCartState
              shoppingCart={shoppingCart}
              isFetching={fetching.shoppingCart}
              error={errors.shoppingCart}
            />
          </div>
          {dataExportMsg}
          <div className="shoppingCartActions">
            {/*<button*/}
            {/*  className="exportBtn"*/}
            {/*  disabled={_.isEmpty(finishedCommodities) || exportToKaratInProgress}*/}
            {/*  onClick={() => {*/}
            {/*    trackEvent("shopping-cart", "load-purchase-plan-all");*/}
            {/*    dispatch(exportToKarat(shoppingCart.id, setExportToKaratInProgress));*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {exportToKaratInProgress ? "Exportuje se..." : "Nahrát do Karátu"}*/}
            {/*</button>*/}
            <button
              className="exportBtn"
              disabled={_.isEmpty(finishedCommodities)}
              onClick={() => {
                trackEvent("shopping-cart", "export-all");
                window.location = shoppingCartExportUrl(shoppingCart);
              }}
            >
              Export
            </button>
            <button
                className="startApprovalButton"
                disabled={_.isEmpty(finishedCommodities) || shoppingCart.data_prepare_status === 'PROCESSING'}
                onClick={() =>
                    dispatch(startApproval())
                }
            >
              {shoppingCart.data_prepare_status === 'ERROR'
                ? 'Nastala chyba při schvalování, zahájit znovu'
                : (shoppingCart.approval_at ? "Opět poslat ke schválení" : "Zahájit schvalování")
              }
            </button>
            {shoppingCart.data_prepare_status && (
              <div
                className='btn-suffix btn-suffix-large'
                title={shoppingCart.data_prepare_status === 'PROCESSING'
                  ? 'Probíhá zpracování dat'
                  : shoppingCart.data_prepare_status === 'ERROR'
                  ? 'Zpracování dat selhalo'
                  : shoppingCart.data_prepare_status === 'DONE'
                  ? 'Zpracování dat proběhlo úspěšně'
                  : ''
                }
              >
                {shoppingCart.data_prepare_status === 'DONE' && <Check style={{ color: '#33dd33' }} />}
                {shoppingCart.data_prepare_status === 'PROCESSING' && <Info style={{ color: '#888' }} />}
                {shoppingCart.data_prepare_status === 'ERROR' && <AlertOctagon style={{ color: '#dd3333' }} />}
              </div>
            )}

            <button
              className="closePurchaseBtn"
              disabled={!shoppingCart.approval_at}
              onClick={() =>
                Popup.create({
                  content: (
                    <ClosePurchasePopup saveShoppingCart={saveShoppingCart}/>
                  )
                })
              }
            >
              Ukončit nákup
            </button>
            <Link className="goToSuppliersBtn" to={`/suppliers-overview`}>
              Dodavatelé
            </Link>
          </div>
        </section>

        <section className="shoppingCartContent">
          <ul className="commoditiesList">
            <li className="commodityItem header">
              <div className="iconTextWrap">
                <div className="iconWrap orange">
                  <Edit2 color="#FFF" className="icon"/>
                </div>
                Rozpracované komodity
              </div>
              <div className="endColumn">{inProgress}</div>
            </li>
            {inProgressCommodities
                .sort((a, b) => a.commodity_code.localeCompare(b.commodity_code))
                .map(com => (
              <CommodityItem
                key={com.id}
                menuActions={menuActions}
                commodity={com}
                shoppingCart={shoppingCart}
                selectedCommodities={selectedCommodities}
                selectCommodity={(comCode, selected) =>
                  selectCommodity(comCode, SECTION_TYPES.IN_PROGRESS, selected)
                }
              />
            ))}
          </ul>

          <ul className="commoditiesList">
            <li className="commodityItem header">
              <div className="iconTextWrap">
                <div className="iconWrap green">
                  <Check color="#FFF" className="icon"/>
                </div>
                Dokončené komodity
              </div>
              <div className="endColumn">{finishedCommodities.length}</div>
            </li>
            {finishedCommodities
                .sort((a, b) => a.commodity_code.localeCompare(b.commodity_code))
                .map(com => (
              <CommodityItem
                key={com.id}
                menuActions={menuActions}
                commodity={com}
                shoppingCart={shoppingCart}
                dispatch={dispatch}
                showSuppliers={true}
                selectedCommodities={selectedCommodities}
                selectCommodity={(comCode, selected) =>
                  selectCommodity(comCode, SECTION_TYPES.FINISHED, selected)
                }
              />
            ))}
          </ul>

          <ul className="commoditiesList">
            <li className="commodityItem header">
              <div className="iconTextWrap">
                <div className="iconWrap green">
                  <img
                    src={IconZeroFinished}
                    alt="Dokončené nulové komodity"
                    className="icon"
                  />
                </div>
                Dokončené nulové komodity
              </div>
              <div className="endColumn">{finishedZeroCommodities.length}</div>
            </li>
            {finishedZeroCommodities
                .sort((a, b) => a.commodity_code.localeCompare(b.commodity_code))
                .map(com => (
              <CommodityItem
                key={com.id}
                menuActions={menuActions}
                commodity={com}
                shoppingCart={shoppingCart}
                excludeZero={false}
                selectedCommodities={selectedCommodities}
                selectCommodity={(comCode, selected) =>
                  selectCommodity(
                    comCode,
                    SECTION_TYPES.FINISHED_ZERO,
                    selected
                  )
                }
              />
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
}

ShoppingCartPage.propTypes = {
  errors: PropTypes.object.isRequired,
  fetching: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  shoppingCart: PropTypes.object.isRequired,
  fetchShoppingCart: PropTypes.func.isRequired,
  saveShoppingCart: PropTypes.func.isRequired,
  menuActions: PropTypes.object.isRequired,
  keboola: PropTypes.object.isRequired
};

export default connect(
  state => ({
    errors: state.errors,
    fetching: state.fetching,
    mutations: state.mutations,
    router: state.router,
    shoppingCart: state.shoppingCart,
    keboola: state.keboola
  }),
  dispatch => ({
    fetchShoppingCart: () => dispatch(fetchShoppingCart()),
    saveShoppingCart: title => dispatch(saveShoppingCart(title)),
    startApproval: () => dispatch(saveShoppingCart()),
    menuActions: {
      removeCommodityFromCart: commodityCode =>
        dispatch(deleteShoppingCartHeader(commodityCode)),
      saveShoppingCartHeader: (commodityCode, state) =>
        dispatch(saveShoppingCartHeader(commodityCode, state))
    },
    dispatch
  })
)(ShoppingCartPage);
