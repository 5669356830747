import * as types from "./actionTypes";
import { fetchWithDispatch } from "../lib/fetch";
import * as qs from "query-string";
import * as moment from "moment";
import { push } from "react-router-redux";

const appendParam = (getState, filter) => {
  const search = getState().router.location.search;
  const params = qs.parse(search, { arrayFormat: "bracket" });
  return qs.stringify({ ...params, ...filter }, { arrayFormat: "bracket" });
};

export const setFilterParams = (filterParams) => (dispatch, getState) => {
  const newFilterParams = appendParam(getState, filterParams);
  dispatch(push({ search: newFilterParams }));
  return newFilterParams;
};

export const setFilterParamsAndFetchNomenclatures = (filterParams) => (dispatch, getState, opts) => {
  const newFilterParams = setFilterParams(filterParams)(dispatch, getState);
  const commodityCode = getState().selectedCommodity.code;
  fetchNomenclatures(dispatch, opts, commodityCode, newFilterParams);
};


export const fetchInitialNomenclatures = (commodityCode, filterParams = {}) => (dispatch, getState, opts) => {
  fetchNomenclatures(dispatch, opts, commodityCode, filterParams);
};

export const getFilterParamsFromSearchUrl = (search) => {
  const params = qs.parse(search);
  return getFilterParamsOrDefault(params);
};

export const changeForOrderAmount = (nomenclatureId, amount) => ({
  type: types.CHANGE_FOR_ORDER_AMOUNT,
  nomenclatureId,
  amount
});

export const changeNomenclature = (nomenclatureId, amount) => ({
  type: types.CHANGE_NOMENCLATURE,
  nomenclatureId,
  amount
});

export const changeNomenclatureProp = (nomenclatureId, { value, prop }) => ({
  type: types.CHANGE_NOMENCLATURE_PROP,
  nomenclatureId,
  value,
  prop,
});

export const updateNomenclature = (nomenclatureId, nomenclature) => ({
  type: types.UPDATE_NOMENCLATURE,
  nomenclatureId,
  nomenclature,
});

export const changeForOrderAmountBatching = (payload) => ({
  type: types.CHANGE_FOR_ORDER_AMOUNT_BATCHING,
  payload,
});

export const getFilterParamsOrDefault = (params) => {
  const bdd1 = params.bdd1 ? parseInt(params.bdd1, 10) : 6;
  return {
    bdd1,
    top_150: params.top_150 === "true" ? true : params.top_150 === "false" ? false : undefined,
    prediction_from: params.prediction_from || moment().startOf("month").format("YYYY-MM-DD"),
    prediction_to: params.prediction_to || moment().endOf("month").add(bdd1, "month").format("YYYY-MM-DD"),
    purchase_from: params.purchase_from || moment().startOf("month").subtract(1, "year").format("YYYY-MM-DD"),
    purchase_to: params.purchase_to || moment().endOf("month").subtract(1, "year").add(bdd1, "month").format("YYYY-MM-DD"),
    show_external_graph: params.show_external_graph === "true",
    show_color_percentage: params.show_color_percentage === "true",
  };
};

export const getBdd1Date = (bdd) =>
  moment().startOf("month").add(bdd, "month").format("YYYY-MM-DD");

export const fetchNomenclatures = (dispatch, { apiUrl }, commodityCode, filterStr) => {
  const { bdd1, purchase_from, purchase_to, prediction_from, prediction_to, top_150 } = getFilterParamsFromSearchUrl(filterStr);
  
  const params = qs.stringify({
    top_150,
    bdd: getBdd1Date(bdd1),
    purchase_from,
    purchase_to,
    prediction_from,
    prediction_to,
    commodity_code: commodityCode,
  });

  dispatch({
    type: types.FETCH_NOMENCLATURES_REQUEST,
    params
  });
  return fetchWithDispatch(`${apiUrl}/nomenclature?${params}`,
    json => dispatch({
      type: types.FETCH_NOMENCLATURES_SUCCESS,
      response: json
    }),
    error => dispatch({
      type: types.FETCH_NOMENCLATURES_FAILURE,
      error
    }));
};

export const fetchNomenclatureInProduction = (nomenclatureId, bdd) => (dispatch, getState, { apiUrl }) => {
  const bddDate = getBdd1Date(bdd);

  dispatch({
    type: types.FETCH_NOMENCLATURE_IN_PRODUCTION_REQUEST,
    nomenclatureId,
    bdd: bddDate,
  });

  const params = qs.stringify({ bdd: bddDate });
  return fetchWithDispatch(`${apiUrl}/nomenclature/${nomenclatureId}/in-production?${params}`,
    json => dispatch({
      type: types.FETCH_NOMENCLATURE_IN_PRODUCTION_SUCCESS,
      response: json,
      nomenclatureId,
      bdd: bddDate,
    }),
    error => dispatch({
      type: types.FETCH_NOMENCLATURE_IN_PRODUCTION_FAILURE,
      error,
      nomenclatureId,
      bdd: bddDate,
    }));
};

export const fetchNomenclatureEfficiency = (nomenclatureId, bdd1, commodityCode) =>
  (dispatch, getState, { apiUrl }) => {
    const bdd1Date = getBdd1Date(bdd1);

    dispatch({
      type: types.FETCH_NOMENCLATURE_EFFICIENCY_REQUEST,
      nomenclatureId,
      bdd: bdd1Date,
      commodity_code: commodityCode
    });

    const params = qs.stringify({
      bdd: bdd1Date,
      commodity_code: commodityCode
    });
    return fetchWithDispatch(`${apiUrl}/nomenclature/${nomenclatureId}/efficiency?${params}`,
      json => {
        dispatch({
          type: types.FETCH_NOMENCLATURE_EFFICIENCY_SUCCESS,
          response: json,
          nomenclatureId,
          bdd: bdd1Date,
        })
        dispatch({
          type: types.UPDATE_NOMENCLATURE,
          response: json,
          nomenclatureId
        })
      },
      error => dispatch({
        type: types.FETCH_NOMENCLATURE_EFFICIENCY_FAILURE,
        error,
        nomenclatureId,
        bdd: bdd1Date,
        commodity_code: commodityCode
      }));
  };

export const fetchNomenclatureExpediting = (nomenclatureId, bdd) => (dispatch, getState, { apiUrl }) => {
  const bddDate = getBdd1Date(bdd);

  dispatch({
    type: types.FETCH_NOMENCLATURE_EXPEDITING_REQUEST,
    nomenclatureId,
    bdd: bddDate,
  });

  const params = qs.stringify({ bdd: bddDate });
  return fetchWithDispatch(`${apiUrl}/nomenclature/${nomenclatureId}/expediting?${params}`,
    json => dispatch({
      type: types.FETCH_NOMENCLATURE_EXPEDITING_SUCCESS,
      response: json,
      nomenclatureId,
      bdd: bddDate,
    }),
    error => dispatch({
      type: types.FETCH_NOMENCLATURE_EXPEDITING_FAILURE,
      error,
      nomenclatureId,
      bdd: bddDate,
    }));
};
