import * as types from "./actionTypes";
import { fetchWithBodyAndDispatch } from "../lib/fetch";

export const importData = () => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.KEBOOLA_IMPORT_REQUEST
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/keboola`,
    {
      method: "POST",
    },
    json => {
      dispatch({
        type: types.KEBOOLA_IMPORT_SUCCESS,
        response: json
      });
    },
    error =>
      dispatch({
        type: types.KEBOOLA_IMPORT_FAILURE,
        error
      })
  );
};
