import React from "react";
import _ from "lodash";
import { AlertTriangle } from "react-feather";

import PropTypes from "prop-types";

import { dumpError } from "../../lib/utils";

import NoImage from "../../images/noimage.png";

import "./Categories.css";

const imagesForCategories = {
  ACCESSORIE: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/t90/t90_t1_c.jpg`,
  OBUV: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/B03/B03_B1_c.jpg`,
  BUNDYVEST: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/510/510_00_c.jpg`,
  ČEPICE: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/3V5/3V5_97_c.jpg`,
  DOPLŇKY: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/BAG/BAG_XX_c.jpg`,
  FLEECE: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/503/503_05_c.jpg`,
  STRCHFLEEC: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/417/417_00_c.jpg`,
  FROTE: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/905/905_00_c.jpg`,
  KALHSORT: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/605/605_00_c.jpg`,
  KOŠILE: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/207/207_00_c.jpg`,
  MIKINY: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/410/410_01_c.jpg`,
  POLOKOŠILE: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/203/203_00_c.jpg`,
  TAŠKY: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/900/900_00_c.jpg`,
  TRIČKA: `${process.env.REACT_APP_SHARED_IMAGES_URL}/product/100/100_00_c.jpg`
};

const Category = ({ category, onClick }) => {
  return (
    <div className="category" onClick={() => onClick(category.code)}>
      <div>
        <img
          width="100"
          height="114"
          alt={category.name}
          src={imagesForCategories[category.code] || NoImage}
        />
        {category.low_on_stock ? (<div className="category-card-status" title='Nedostatečná výstačnost'>
          <AlertTriangle style={{ color: '#d49538' }} className="icon" />
        </div>) : null}
      </div>
      <div className="name">{category.name}</div>
      <div className="count">{category.count} komodit</div>
      <div className="subcount">Dokončeno: {category.finished_count}</div>
    </div>
  );
};

Category.propTypes = {
  category: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

const Categories = ({ categories, onClick, isFetching, error }) => {
  let content;
  if (error) {
    content = dumpError(error);
  } else if (_.isEmpty(categories) && isFetching) {
    content = "Načítání kategorií...";
  } else {
    content = categories.map(cat => (
      <Category key={cat.code} onClick={onClick} category={cat} />
    ));
  }

  return <div className="fixedMaxSize Categories">{content}</div>;
};

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default Categories;
