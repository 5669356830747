import { combineReducers } from "redux";
import errors from "./errors";
import fetching from "./fetching";
import filters from "./filters";
import nomenclatures from "./nomenclatures";
import commodities from "./commodities";
import { routerReducer } from "react-router-redux";
import commodityGraphUrl from "./commodityGraphUrl";
import salesPlan from "./salesPlan";
import historicPurchase from "./historicPurchase";
import selectedCommodity from "./selectedCommodity";
import shoppingCartHeader from "./shoppingCartHeader";
import categories from "./categories";
import shoppingCart from "./shoppingCart";
import mutations from "./mutations";
import shoppingCartHistory from "./shoppingCartHistory";
import nomenclatureDrillDown from "./nomenclatureDrillDown";
import nomenclatureEfficiencies from "./nomenclatureEfficiencies";
import authentication from "./authentication";
import keboola from "./keboola";
import efficiency from "./efficiency";
import suppliers from "./suppliers";
import commodityReport from "./commodityReport";
import suppliersOverview from "./suppliersOverview";
import trends from "./trends";

export default combineReducers({
  authentication,
  categories,
  commodities,
  commodityGraphUrl,
  commodityReport,
  errors,
  efficiency,
  fetching,
  filters,
  historicPurchase,
  keboola,
  mutations,
  nomenclatureDrillDown,
  nomenclatureEfficiencies,
  nomenclatures,
  selectedCommodity,
  router: routerReducer,
  shoppingCart,
  shoppingCartHeader,
  shoppingCartHistory,
  suppliersOverview,
  salesPlan,
  suppliers,
  trends,
});
