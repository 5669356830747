import React from "react";
import * as s from "./Checkbox.module.css";
import PropTypes from "prop-types";

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string
};

export default function Checkbox({
  checked,
  onChange,
  id,
  label,
  labelClass,
  className,
  labelPositionLeft = false
}) {
  return (
    <div className={`${s.checkboxWrap} ${className}`}>
      {labelPositionLeft && (
        <label htmlFor={id} className={labelClass || s.defaultLeftLabel}>
          {label}
        </label>
      )}
      <div className={s.checkbox}>
        <input id={id} type="checkbox" checked={checked} onChange={onChange} />
        <label htmlFor={id} />
      </div>
      {!labelPositionLeft && (
        <label htmlFor={id} className={labelClass || s.defaultRightLabel}>
          {label}
        </label>
      )}
    </div>
  );
}
