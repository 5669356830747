import * as types from "../actions/actionTypes";
import _ from "lodash";
import { createSelector } from "reselect";

const nomenclatures = (state = [], action) => {
  switch (action.type) {
    case types.CHANGE_NOMENCLATURE:
      const nom = state.find(nom => nom.nomenclature_id === action.nomenclatureId)
      if (nom) {
        nom.order_amount = action.amount;
      }
      return Object.assign([], state);
    case types.CHANGE_NOMENCLATURE_PROP:
      const nomNom = state.find(nom => nom.nomenclature_id === action.nomenclatureId)
      if (nomNom) {
        nomNom[action.prop] = action.value;
      }
      return Object.assign([], state);
    case types.UPDATE_NOMENCLATURE:
      const nomNomNom = state.find(nom => nom.nomenclature_id === action.nomenclatureId)
      if (nomNomNom) {
        Object.assign(nomNomNom, action.response)
      }
      return Object.assign([], state);
    case types.FETCH_NOMENCLATURES_SUCCESS:
      action.response.data.commodity = action.response.commodity
      action.response.data.commodityColors = action.response.commodityColors
      return action.response.data;
    case types.SAVE_SHOPPING_CART_ITEMS_SUCCESS:
      const newState = action.response.data
      newState.commodity = action.response.commodity || state.commodity
      newState.commodityColors = action.response.commodityColors || state.commodityColors
      return newState;
    default:
      return state;
  }
};

export default nomenclatures;

// selectors
const getNomenclatures = state => state.nomenclatures;
const getFetchingNomenclatures = state => state.fetching.nomenclatures;
const getShoppingCartHeader = state => state.shoppingCartHeader;
const getSalesPlan = state => state.salesPlan;
const getHistoricPurchase = state => state.historicPurchase;
const getSuppliersSumPerNomenclature = state =>
  state.suppliers.suppliersSumPerNomenclature;
const getFilters = state => state.filters

/**
 * Prepares nomenclature data for table.
 * Merges columns data then group rows by color and sum appropriate columns.
 * Uses reselect to cache results.
 * */
export const prepareNomenclaturesData = createSelector(
  [
    getNomenclatures,
    getFetchingNomenclatures,
    getShoppingCartHeader,
    getSalesPlan,
    getHistoricPurchase,
    getFilters
  ],
  (
    nomenclatures,
    fetchingNomenclatures,
    shoppingCartHeader,
    salesPlan,
    historicPurchase,
    filters
  ) => {
    const summableCols = [
      "app_prediction",
      "bdd_bdd2_prediction",
      "efficiency",
      "expediting",
      "for_order",
      "future_storage_state",
      "in_production_1",
      "in_production_2",
      "prediction_amount",
      "prediction_amount_bdd",
      "purchase_amount",
      "sales_app_amount",
      "storage_state"
    ];

    if (fetchingNomenclatures === false) {
      // const commodity = nomenclatures.commodity
      const commodityColors = nomenclatures.commodityColors

      let resNomenclatures = _(nomenclatures).map(nom => {
        const nomDup = Object.assign({}, nom);
        nomDup.for_order = _.get(
          shoppingCartHeader,
          [nomDup.nomenclature_id, "amount"],
          nomDup.for_order || 0
        );
        nomDup.prediction_amount = _.get(
          salesPlan,
          [nomDup.nomenclature_id, "amount"],
          nomDup.prediction_amount || 0
        );
        nomDup.purchase_amount = _.get(
          historicPurchase,
          [nomDup.nomenclature_id, "amount"],
          nomDup.purchase_amount || 0
        );
        nomDup.sales_app_amount = _.get(
          salesPlan,
          [nomDup.nomenclature_id, "sales_app_amount"],
          nomDup.sales_app_amount || 0
        );
        return nomDup;
      });

      const initialSum = {
        total_sum: {
          is_sum: true,
          is_total_sum: true,
        }
      };
      const sumsByColor = _(resNomenclatures).reduce((acc, row) => {
        summableCols.forEach(col => {
          const color = filters.colors && filters.colors.length && filters.colors.find(item => item.code === row.color_code);

          acc = _.defaultsDeep(acc, {
            [row.color_code]: {
              [col]: 0,
              color_code: row.color_code,
              xyz: (() => {
                const comColor = (commodityColors || []).find(cc => cc.color_code === row.color_code)
                if (comColor) {
                  return comColor.xyz
                }
                return null
              })(),
              is_sum: true,
              color_name: color && color.name,
              sort_label: -1
            }
          });
          let value = row[col] ? parseInt(row[col], 10) : 0;

          if (col === "app_prediction" && !(row.efficiencyWithoutOrder >= 0 && row.efficiencyWithoutOrder - 1 <= (row.min_bdd2))) {
            value = 0
          }
          acc["total_sum"][col] = acc["total_sum"][col] || 0;
          acc["total_sum"][col] += value;
          acc[row.color_code][col] += value;
        });
        return acc;
      }, initialSum);

      resNomenclatures = resNomenclatures.map(nom => {
        nom.prediction_amount_sum =
          sumsByColor[nom.color_code].prediction_amount;
        return nom;
      });
      resNomenclatures = resNomenclatures
        .concat(_.values(sumsByColor))
        .orderBy(["sort_label", "id"], ["desc", "asc"])
        .groupBy("color_code")
        .sortBy(arr => {
          const sumRow = _(arr).last();
          // place total sum row at the end
          return sumRow.is_total_sum ? 1 : -sumRow["prediction_amount"];
        })
        .flatten();
      return resNomenclatures.value();
    } else {
      return nomenclatures;
    }
  }
);

/**
 * sum amounts for for drilldown
 */
export const getNomenclatureDrillDown = nomenclatureDrillDown => {
  if (_.isEmpty(nomenclatureDrillDown)) return nomenclatureDrillDown;

  const sumAmount = _(nomenclatureDrillDown).reduce((acc, nom) => {
    return acc + nom.amount;
  }, 0);
  return _(nomenclatureDrillDown)
    .concat([{ is_sum: true, amount: sumAmount }])
    .value();
};

function isNomenclatureCompleted(suppliersSumPerNomenclature, nom) {
  const suppliersSum = suppliersSumPerNomenclature[nom.nomenclature_id];
  if (suppliersSum === undefined && nom.for_order !== 0) {
    return "not-assigned";
  } else if (
    suppliersSum === nom.for_order ||
    (suppliersSum === undefined && nom.for_order === 0)
  ) {
    return "completed";
  } else if (suppliersSum < nom.for_order) {
    return "uncompleted";
  } else if (suppliersSum > nom.for_order) {
    return "overflow";
  } else {
    console.warn("suppliersSumPerNomenclature:", suppliersSumPerNomenclature); // TODO: remove!
    console.warn("nom:", nom); // TODO: remove!
  }
}

export const prepareNomenclaturesDataForSupplier = createSelector(
  [
    getNomenclatures,
    getFetchingNomenclatures,
    getShoppingCartHeader,
    getSuppliersSumPerNomenclature
  ],
  (
    nomenclatures,
    fetchingNomenclatures,
    shoppingCartHeader,
    suppliersSumPerNomenclature
  ) => {
    if (!suppliersSumPerNomenclature || fetchingNomenclatures === false) {
      let resNomenclatures = _(nomenclatures)
        .map(nom => {
          const nomDup = Object.assign({}, nom);
          nomDup.for_order = _.get(
            shoppingCartHeader,
            [nomDup.nomenclature_id, "amount"],
            nomDup.for_order || 0
          );
          nomDup.supplierState = isNomenclatureCompleted(
            suppliersSumPerNomenclature,
            nomDup
          );
          return nomDup;
        })
        .filter(nom => nom.for_order);

      const nomenclaturesByColor = _(resNomenclatures)
        .orderBy(["for_order"], ["desc"])
        .groupBy("color_code");
      const colors = nomenclaturesByColor
        .reduce((colors, noms, color) => {
          const sum = noms.reduce((sum, nom) => sum + nom.for_order, 0);
          const allStates = _(noms)
            .map(n => n.supplierState)
            .uniq()
            .value();
          return colors.concat({
            color_code: color,
            for_order: sum,
            supplierState: allStates.length === 1 ? allStates[0] : "uncompleted"
          });
        }, _([]))
        .orderBy(["sort_label"], ["desc"]);

      return {
        nomenclaturesByColor: nomenclaturesByColor.value(),
        colors: colors.value(),
        nomenclatures: resNomenclatures.value()
      };
    } else {
      return { nomenclaturesByColor: {}, colors: [], nomenclatures: [] };
    }
  }
);
