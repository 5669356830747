import * as types from "../actions/actionTypes";

const suppliersOverview = (state = { rows: [], sum: {} }, action) => {
  switch (action.type) {
    case types.FETCH_SUPPLIER_OVERVIEW_REQUEST:
      return Object.assign({}, state, { pending: true })
    case types.FETCH_SUPPLIER_OVERVIEW_SUCCESS:
      let sum = {
        piecesSum: 0,
        valueSum: 0
      };
      const rows = action.response.map(sup => {
        const sums = sup.commodities.reduce(
          (acc, s) => {
            acc.piecesSum += parseInt(s.pieces, 10);
            sum.piecesSum += parseInt(s.pieces, 10);
            acc.valueSum += parseInt(s.value, 10);
            sum.valueSum += parseInt(s.value, 10);
            return acc;
          },
          {
            piecesSum: 0,
            valueSum: 0
          }
        );
        return Object.assign(sup, sums);
      });
      return { rows, sum, pending: false };
    default:
      return state;
  }
};

export default suppliersOverview;
