import _ from "lodash";
import { STATES } from "./commodity";

export const countsByState = (shoppingCart) => (
  {
    inProgress: _.get(shoppingCart, ["sub_count", STATES.IN_PROGRESS]),
    commoditiesLeft: _.get(shoppingCart, ["sub_count", "COMMODITIES_LEFT"]),
    finished: _.get(shoppingCart, ["sub_count", STATES.FINISHED]),
  }
);
