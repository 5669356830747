import React from "react";
import classNames from "classnames";
import { getNomenclatureDrillDown } from "../../reducers/nomenclatures";
import Popup from "react-popup";
import moment from "moment/moment";
import _ from "lodash";
import { colorImageUrl } from "../../lib/urls";
import ColorImg from "../../components/ColorImg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatNumber } from "../../lib/numbers";

const CloseButton = () => (
  <div className="actions">
    <button className="close" onClick={() => Popup.close()}>
      Zavřít
    </button>
  </div>
);

const _Content = ({ bdd1, nomenclatureId, type, nomenclatureDrillDown }) => {
  const nomenclatureDD = nomenclatureDrillDown[type][nomenclatureId];
  const tableData = nomenclatureDD && getNomenclatureDrillDown(nomenclatureDD);
  if (!nomenclatureDD) return "Načítají se data...";
  else {
    const isExpediting = _.some(tableData, "load_date");
    const isInProduction = _.some(tableData, "date_loaded");
    if (_.isEmpty(tableData))
      return (
        <div className="NomenclatureDrillDownPopup">
          <p>Pro tuto nomenklaturu nejsou žádná data.</p>
          <CloseButton />
        </div>
      );
    else
      return (
        <div className="NomenclatureDrillDownPopup">
          <table>
            <thead>
              <tr>
                <th className="left">Produkt</th>
                <th>Číslo šarže</th>
                <th>Datum nakládky</th>
                <th>Datum dodání</th>
                <th>
                  {(isExpediting && "Na cestě") ||
                    (isInProduction && "Ve výrobě")}
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, i) => {
                const bddLastDay = moment()
                  .add(bdd1, "month")
                  .endOf("month");
                const isInFuture =
                  isInProduction &&
                  moment(row.date_delivered).isAfter(bddLastDay);
                return (
                  <tr
                    key={i}
                    className={classNames({ sum: row.is_sum, isInFuture })}
                  >
                    <td className="left nomenclatureCol">
                      {!row.is_sum && (
                        <div className="nomenclature">
                          <ColorImg
                            width="32"
                            height="32"
                            alt={row.color_code}
                            className="color"
                            color={row.color_code}
                            src={colorImageUrl(row.color_code)}
                          />
                          <div className="rows">
                            <span className="size">{row.size_name}</span>
                            <span className="id">{row.nomenclature_id}</span>
                            <span className="name">
                              {row.nomenclature_name}
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                    <td>{row.reference_number}</td>
                    <td>
                      {(row.load_date &&
                        moment(row.load_date).format("D. M. YYYY")) ||
                        (row.date_loaded &&
                          moment(row.date_loaded).format("D. M. YYYY"))}
                    </td>
                    <td>
                      {row.date_delivered &&
                        moment(row.date_delivered).format("D. M. YYYY")}
                    </td>
                    <td>{formatNumber(row.amount)} ks</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CloseButton />
        </div>
      );
  }
};

const Content = connect(state => ({
  nomenclatureDrillDown: state.nomenclatureDrillDown
}))(_Content);

Content.propTypes = {
  nomenclatureDD: PropTypes.array,
  tableData: PropTypes.array
};

const NomenclatureDrillDownPopup = ({
  nomenclatureDrillDown,
  nomenclatureId,
  type,
  bdd1
}) => {
  return (
    <Content
      nomenclatureDrillDown={nomenclatureDrillDown}
      nomenclatureId={nomenclatureId}
      type={type}
      bdd1={bdd1}
    />
  );
};

NomenclatureDrillDownPopup.propTypes = {
  nomenclatureDrillDown: PropTypes.object.isRequired,
  nomenclatureId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default NomenclatureDrillDownPopup;
