import * as React from "react";
import "./GlobalErrors.css";
import { connect } from "react-redux";
import { AlertCircle, XCircle } from "react-feather";
import _ from "lodash";
import { dumpError } from "../lib/utils";

function GlobalErrors({ errors }) {
  const [isShowed, setIsShowed] = React.useState(false);
  React.useEffect(
    () =>
      setIsShowed(
        !_(errors)
          .filter((error, _) => error)
          .isEmpty()
      ),
    [errors]
  );

  return isShowed ? (
    <div className="GlobalErrorsWrapper">
      <div className="GlobalErrors fixedMaxSize">
        <AlertCircle className="globalErrorIcon" />
        <div className="globalErrorText">
          {_(errors)
            .filter((error, _) => error)
            .map((error, key) => (
              <p key={key}>Server error occurred: {dumpError(error)}</p>
            ))
            .value()}
        </div>
        <XCircle
          className="closeBtn clickable"
          onClick={() => setIsShowed(false)}
        />
      </div>
    </div>
  ) : null;
}

export default connect(state => ({
  errors: state.errors
}))(GlobalErrors);
