import React from "react";
import Picker from "react-month-picker";
import moment from "moment";
import { Calendar } from "react-feather";
import "./MonthPicker.css";
import PropTypes from "prop-types";

class MonthPicker extends React.Component {
  _handleClickRangeBox = () => {
    this.refs.pickRange.show();
  };

  handleRangeChange = (value, text, listIndex) => {
    // this.refs.pickRange.dismiss();
  };

  handleRangeDissmis = (value) => {
    const { from, to } = value;
    this.props.onChange(
      moment(`${from.year}-${from.month}-01`, "YYYY-MM-DD").format("YYYY-MM-DD"),
      moment(`${to.year}-${to.month}-01`, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"));
  };

  render() {
    const pickerLang = {
      months: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      from: "Od", to: "Do",
    };
    const currentTime = new Date();
    const currentMonth = currentTime.getMonth() + 1;
    const currentYear = currentTime.getFullYear();
    const { from, to, label } = this.props;
    let { minYear, minMonth, maxYear, maxMonth } = this.props;
    minYear = minYear || currentYear - 5;
    minMonth = minMonth || 1;
    maxYear = maxYear || currentYear;
    maxMonth = maxMonth || currentMonth;
    const fromYear = moment(from).year();
    const fromMonth = moment(from).month() + 1;
    const toYear = moment(to).year();
    const toMonth = moment(to).month() + 1;

    return (
      <div className="MonthPicker">
        <Picker
          ref="pickRange"
          years={{ min: { year: minYear, month: minMonth }, max: { year: maxYear, month: maxMonth } }}
          range={{ from: { year: fromYear, month: fromMonth }, to: { year: toYear, month: toMonth } }}
          lang={pickerLang}
          theme="adler"
          onChange={this.handleRangeChange}
          onDismiss={this.handleRangeDissmis}
        >
          <div className="iconBox">
            <Calendar title={label}
                      color="#FFF"
                      onClick={this._handleClickRangeBox}/>
          </div>
        </Picker>
      </div>
    );
  }
}

MonthPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  minMonth: PropTypes.number,
  maxMonth: PropTypes.number,
};

export default MonthPicker;
