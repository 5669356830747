import * as types from "./actionTypes";
import { fetchWithBodyAndDispatch, fetchWithDispatch } from "../lib/fetch";
import { setCookie } from "../lib/auth";
import { push } from "react-router-redux";

const setAuthToLocalStorage = (user) => {
  window.localStorage.setItem("adler-purchase-user", JSON.stringify(user));
};

export const login = (email, password) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.LOG_IN_REQUEST
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/authentication/login`,
    {
      method: "POST",
      body: {
        email,
        password
      }
    },
    json => {
      const accessToken = json["access-token"];
      dispatch({
        type: types.LOG_IN_SUCCESS,
        response: json
      });
      if (accessToken) {
        setCookie("access-token", accessToken, 30);
        setAuthToLocalStorage(json.user);
        dispatch(push("/"));
      }
    },
    error =>
      dispatch({
        type: types.LOG_IN_FAILURE,
        error
      })
  );
};

export const session = () => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.AUTHENTICATION_SESSION_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/authentication/session`,
    json => {
      dispatch({
        type: types.AUTHENTICATION_SESSION_SUCCESS,
        response: json
      });
      setAuthToLocalStorage(json.user);
    },
    error => {
      return dispatch({
        type: types.AUTHENTICATION_SESSION_FAILURE,
        error
      });
    },
    dispatch,
    getState
  );
};
