import * as types from "../actions/actionTypes";
import _ from "lodash";

const shoppingCartHeader = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_HEADER_SUCCESS:
      return action.response;
    case types.CHANGE_FOR_ORDER_AMOUNT:
      return Object.assign({}, _.set(state, [action.nomenclatureId, "amount"], action.amount));
    case types.CHANGE_FOR_ORDER_AMOUNT_BATCHING:
      action.payload.forEach(item => _.set(state, [item.nomenclature_id, "amount"], item.amount));
      return Object.assign({}, state);
    case types.SAVE_SHOPPING_CART_ITEMS_SUCCESS:
      return action.response.shoppingCartItems;
    default:
      return state;
  }
};

export default shoppingCartHeader;
