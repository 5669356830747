import React from "react";
import Select from "react-select";
import "react-select/dist/react-select.css";
import "./Filters.css";
import { Filter, X } from "react-feather";
import _ from "lodash";
import { STATES } from "../../lib/commodity";
import PropTypes from "prop-types";

class QuickSearch extends React.Component {
  delayedOnChange = _.debounce(searchTerm => {
    this.props.filterActions.quickSearch(searchTerm);
  }, 1000);

  onChange = e => {
    e.persist();
    this.delayedOnChange(e.target.value);
  };

  render() {
    const { filters } = this.props;

    return (
      <div className="quickSearchSection input-group">
        <label htmlFor="quickSearch">Rychlé vyhledávání</label>
        <input
          id="quickSearch"
          className="quickSearch"
          type="text"
          defaultValue={filters.searchedTerm}
          onChange={e => this.onChange(e)}
        />
      </div>
    );
  }
}

QuickSearch.propTypes = {
  filters: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired
};

const top150Opts = [
  { value: true, name: 'Ano' },
  { value: false, name: 'Ne' }
];

const Filters = ({ filters, selectedFilters, filterActions }) => {
  return (
    <div className="fixedMaxSize Filters">
      <QuickSearch filters={filters} filterActions={filterActions} />
      <div className="selectFiltersWrapper">
        <div className="iconWrap">
          <Filter color="#FFF" size="28" />
        </div>
        <div className="width100">
          <div className="selectFilters">
            <div className="input-group">
              <label htmlFor="selectCategory">Kategorie</label>
              <Select
                id="selectCategory"
                multi
                options={filters.categories}
                value={selectedFilters.category}
                onChange={e =>
                  filterActions.changeCategoryFilter(e.map(s => s.code))
                }
                labelKey="name"
                valueKey="code"
                placeholder="Vyberte kategorii"
                className="width100"
              />
            </div>
            <div className="input-group">
              <label htmlFor="selectCategory">Top&nbsp;150</label>
              <Select
                id="top_150"
                options={top150Opts}
                value={selectedFilters.top_150}
                onChange={e =>
                  filterActions.changeTop150Filter(e ? e.value : undefined)
                }
                labelKey="name"
                valueKey="value"
                placeholder="Vyberte hodnotu"
                className="width100"
              />
            </div>
            <div className="input-group">
              <label htmlFor="selectBrand">Značka</label>
              <Select
                id="selectBrand"
                multi
                options={filters.brands}
                value={selectedFilters.brand}
                onChange={e =>
                  filterActions.changeBrandFilter(e.map(s => s.code))
                }
                labelKey="name"
                valueKey="code"
                placeholder="Vyberte značku"
                className="width100"
              />
            </div>
            <div className="input-group">
              <label htmlFor="selectColor">Barva</label>
              <Select
                id="selectColor"
                multi
                options={filters.colors}
                value={selectedFilters.color}
                onChange={e =>
                  filterActions.changeColorFilter(e.map(s => s.code))
                }
                labelKey="code_name"
                valueKey="code"
                placeholder="Vyberte barvu"
                className="width100"
              />
            </div>
            <div className="input-group">
              <label htmlFor="selectSize">Velikost</label>
              <Select
                id="selectSize"
                multi
                options={filters.sizes}
                value={selectedFilters.size}
                onChange={e =>
                  filterActions.changeSizeFilter(e.map(s => s.code))
                }
                labelKey="code_name"
                valueKey="code"
                placeholder="Vyberte velikost"
                className="width100"
              />
            </div>
            <div className="input-group">
              <label htmlFor="selectState">Stav</label>
              <Select
                id="selectState"
                options={[
                  { label: "Dokončené", value: STATES.FINISHED },
                  { label: "Rozpracované", value: STATES.IN_PROGRESS },
                  { label: "Nezahájené", value: STATES.NONE }
                ]}
                onChange={e => filterActions.changeStateFilter(e && e.value)}
                value={selectedFilters.state}
                placeholder="Vyberte stav"
                className="width100"
              />
            </div>
          </div>
        </div>
        <button
          className="cancelFilters"
          onClick={() => filterActions.clearCommodityFilters()}
        >
          <X className="icon" />
          Zrušit filtr
        </button>
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  filterActions: PropTypes.object.isRequired,
  selectedFilters: PropTypes.object.isRequired
};

export default Filters;
