import * as types from "../actions/actionTypes";
import _ from "lodash";

const nomenclatureEfficiencies = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_EFFICIENCY_SUCCESS:
      return _(state).set(action.nomenclatureId, action.response.efficiency).clone();
    case types.FETCH_NOMENCLATURE_EFFICIENCY_FAILURE:
      return _(state).set(action.nomenclatureId, null).clone();
    case types.FETCH_NOMENCLATURES_SUCCESS:
      return {};
    default:
      return state;
  }
};

export default nomenclatureEfficiencies;
