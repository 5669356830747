import React from "react";
import { Link } from "react-router-dom";

const NotAuthorized = () => (
  <div className="fixedMaxSize">
    <h3>You do not have permissions to view this page.</h3>

    <p>
      Try to <Link to="/sign-in">log as</Link> someone else.
    </p>
  </div>
);

export default NotAuthorized;
