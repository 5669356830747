import * as types from "../actions/actionTypes";

const salesPlan = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURES_SUCCESS:
      return action.response.prediction;
    case types.FETCH_SALES_PLAN_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export default salesPlan;
