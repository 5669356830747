import * as types from "./actionTypes";
import { fetchWithBodyAndDispatch, fetchWithDispatch } from "../lib/fetch";
import { fetchShoppingCart } from "./shoppingCart";
import { push } from "react-router-redux";

export const deleteShoppingCartHeader = commodityCode => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.DELETE_SHOPPING_CART_HEADER_REQUEST,
    commodityCode
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}`,
    {
      method: "DELETE"
    },
    () => {
      dispatch({
        type: types.DELETE_SHOPPING_CART_HEADER_SUCCESS,
        commodityCode
      });
      dispatch(fetchShoppingCart());
    },
    error => {
      dispatch({
        type: types.DELETE_SHOPPING_CART_HEADER_FAILURE,
        error,
        commodityCode
      });
    }
  );
};

export const fetchShoppingCartHeader = commodityCode => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.FETCH_SHOPPING_CART_HEADER_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}`,
    json =>
      dispatch({
        type: types.FETCH_SHOPPING_CART_HEADER_SUCCESS,
        response: json
      }),
    error =>
      dispatch({
        type: types.FETCH_SHOPPING_CART_HEADER_FAILURE,
        error
      })
  );
};

export const saveShoppingCartHeader = (commodityCode, state, opts = {}) => (
  dispatch,
  getState,
  { apiUrl }
) => {
  const {
    redirectToRoot,
    redirect,
    bdd,
  } = opts;

  dispatch({
    type: types.SAVE_SHOPPING_CART_HEADER_REQUEST,
    commodityCode,
    state
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/${commodityCode}`,
    {
      method: "PUT",
      body: {
        commodityCode,
        state,
        bdd,
      }
    },
    json => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_HEADER_SUCCESS,
        response: json,
        commodityCode
      });
      dispatch(fetchShoppingCart());
      redirectToRoot && dispatch(push("/"));
      redirect && redirect()
    },
    error => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_HEADER_FAILURE,
        error,
        commodityCode
      });
      dispatch(fetchShoppingCart());
    }
  );
};
