import * as types from "../actions/actionTypes";

const efficiency = (state = [], action) => {
    switch (action.type) {
        case types.AUTHENTICATION_SESSION_SUCCESS:
            const response = action.response;
            return response.checkEfficiency;
        case types.EFFICIENCY_CHECK_REQUEST:
            return {...action.response, status: "waiting"};
        // case types.EFFICIENCY_CHECK_SUCCESS:
        //     return action.response;
        case types.EFFICIENCY_CHECK_FAILURE:
            return {...action.response, status: "failed"};
        default:
            return state;
    }
};

export default efficiency;
