export function setCookie(name, value, exdays) {
  const now = new Date();
  now.setTime(now.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + now.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function removeCookie(name) {
  setCookie(name, "", -1);
}

export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function logout() {
  window.localStorage.clear();
  removeCookie("access-token");
  window.location.replace("/sign-in");
}
