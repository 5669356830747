import * as types from "../actions/actionTypes"

const trendDefaults = {
  A: [5, 10],
  B: [4, 9],
  C: [3, 7],
  D: [3, 7],
}

const trends = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_TRENDS_SUCCESS:

      //set defaults
      action.trends.forEach(trend => {
        if ([undefined, null].includes(trend.min_value)) trend.min_value = trendDefaults[trend.code[0]][0]
        if ([undefined, null].includes(trend.max_value)) trend.max_value = trendDefaults[trend.code[0]][1]
      })

      return action.trends
    // case types.SAVE_TREND
    case types.CHANGE_TREND:
      const item = state.find(item => item.code === action.trend.code)
      if (item) {
        Object.assign(item, action.trend, { dirty: true });
        return [...state];
      }
      return state;
    default:
      return state
  }
}

export default trends