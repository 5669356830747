import React from "react";
import classNames from "classnames";
import Popup from "react-popup";
import NomenclatureDrillDownPopup from "./NomenclatureDrillDownPopup";
import NumberInput from "./NumberInput";
import {colorImageUrl} from "../../lib/urls";
import ColorImg from "../../components/ColorImg";
import NewIcon from "../../images/new-icon.svg";
import RebrandingIcon from "../../images/rebranding-icon.svg";
import PropTypes from "prop-types";
import {trackEvent} from "../../lib/ga";
import {formatNumber} from "../../lib/numbers";

export const NomenclatureIdVal = ({row}) => (
    <div className="nomName">
        {!row.is_total_sum ? (
            <ColorImg
                className="color"
                width="28"
                height="28"
                alt={row.color_code}
                color={row.color_code}
                src={colorImageUrl(row.color_code)}
            />
        ) : (
            <span className="colorBlack">Celkový součet</span>
        )}
        <span className="size">{row.size}</span>
        <span className="nomId" title={row.name}>
      {row.id}
    </span>
        {row.color_name && <span>{row.color_name}</span>}
        <div className="rightSection">
      <span className="isNew icon">
        {row.new && <img src={NewIcon} alt="Novinka" title="Novinka "/>}
      </span>
            <span className="isRebranding icon">
        {row.rebranded && (
            <img src={RebrandingIcon} alt="Rebranding" title="Rebranding"/>
        )}
      </span>
            <span className="abc icon">{row.xyz}</span>
        </div>
    </div>
);

NomenclatureIdVal.propTypes = {
    row: PropTypes.object.isRequired
};

export const ExpeditingVal = ({
                                  row,
                                  bdd1,
                                  fetchNomenclature,
                                  nomenclatureDrillDown
                              }) => (
    <div
        className={classNames({clickable: !row.is_sum})}
        onClick={() => {
            if (!row.is_sum) {
                fetchNomenclature(row.nomenclature_id, bdd1);
                trackEvent("commodity-table", "show-expediting-detail-popup");
                Popup.create({
                    content: (
                        <NomenclatureDrillDownPopup
                            nomenclatureDrillDown={nomenclatureDrillDown}
                            nomenclatureId={row.nomenclature_id}
                            type="expediting"
                        />
                    )
                });
            }
        }}
    >
        {formatNumber(row.expediting)}
    </div>
);

ExpeditingVal.propTypes = {
    row: PropTypes.object.isRequired,
    bdd1: PropTypes.number.isRequired,
    fetchNomenclature: PropTypes.func.isRequired,
    nomenclatureDrillDown: PropTypes.object.isRequired
};

export const InProductionVal = ({
                                    row,
                                    bdd1,
                                    fetchNomenclature,
                                    nomenclatureDrillDown
                                }) => (
    <div
        className={classNames({clickable: !row.is_sum})}
        onClick={() => {
            if (!row.is_sum) {
                fetchNomenclature(row.nomenclature_id, bdd1);
                trackEvent("commodity-table", "show-production-detail-popup");
                Popup.create({
                    content: (
                        <NomenclatureDrillDownPopup
                            nomenclatureDrillDown={nomenclatureDrillDown}
                            nomenclatureId={row.nomenclature_id}
                            bdd1={bdd1}
                            type="inProduction"
                        />
                    )
                });
            }
        }}
    >
        <div className="inProduction1">{formatNumber(row.in_production_1)}</div>
        <div className="inProduction2">{formatNumber(row.in_production_2)}</div>
    </div>
);

InProductionVal.propTypes = {
    row: PropTypes.object.isRequired,
    bdd1: PropTypes.number.isRequired,
    fetchNomenclature: PropTypes.func.isRequired,
    nomenclatureDrillDown: PropTypes.object.isRequired
};

export const roundValueUpByPiecesInBox = (value, step) => {
    const rem = value % step;
    if (rem === 0 || value === 0) return value;
    else return Math.floor(value / step) * step + step;
};

export const ExpectedEfficiencyVal = ({
                                          row,
                                          isFinished,
                                          value,
                                          saveShoppingCartItem,
                                          changeNomenclatureProp,
                                          nomenclatureFilter,
                                          trends,
                                      }) => {
    if (row.is_sum) return null;
    if (isFinished) {
        return <span>{row.bdd2}</span>;
    } else {
        const trend = trends && trends.find(t => t.code === row.xyz)

        const bdd2value = (row.fake_bdd2 !== undefined ? row.fake_bdd2 : row.bdd2) || 0
        return <NumberInput
            title={trend && `[${trend.min_value} - ${trend.max_value}]`}
            className={classNames("forOrderField editableField")}
            step={1}
            value={bdd2value /*row.bdd2 || 0*/}
            onChange={value => {

                let newVal = value
                if (trend) {
                    if (value > trend.max_value) {
                        newVal = trend.max_value
                    } else if (value < trend.min_value) {
                        newVal = trend.min_value
                    }
                }
                changeNomenclatureProp(row.nomenclature_id, {prop: 'fake_bdd2', value: newVal})
            }}
            onBlur={value => {
                value = parseInt(value, 10)
                changeNomenclatureProp(row.nomenclature_id, {prop: 'fake_bdd2', value});
                // changeNomenclatureProp(row.nomenclature_id, { prop: 'bdd2', value });
                if (!isNaN(value)) {
                    saveShoppingCartItem(
                        row.nomenclature_id,
                        row.for_order,
                        nomenclatureFilter.bdd1,
                        value
                    );
                }
            }}
        />
    }
}

export const ForOrderVal = ({
                                row,
                                isFinished,
                                changeNomenclature,
                                changeForOrderAmount,
                                saveShoppingCartItem,
                                nomenclatureFilter,
                                data,
                                bdd1,
                                changeForOrderAmountBatching,
                                saveShoppingCartItems,
                                selectedCommodity,
                            }) => {
    const step = row.pices_in_box;

    const Wrapper = ({children}) => (row.is_sum && !row.is_total_sum)
        ? <div
            className={'clickable'}
            title='Kopírovat hodnoty velikostí do ostatních barev'
            onClick={() => {
                if (isFinished) return;

                Popup.create({
                    content: (
                        <div className="FillDataConfirmation">
                            <p className="content">
                                Opravdu chcete hodnoty barvy "{row.color_name}" kopírovat dle velikostí do všech
                                ostatních barev?
                            </p>
                            <div className="actions">
                                <button className="cancelBtn" onClick={() => Popup.close()}>
                                    Zrušit
                                </button>
                                <button
                                    className="confirmBtn"
                                    onClick={() => {
                                        const rowColorSizeAmounts = data.reduce((acc, item) => {
                                            if (row.color_code === item.color_code) {
                                                acc[item.size] = item.order_amount;
                                            }
                                            return acc;
                                        }, {});

                                        const preparePayload = item => {
                                            return {
                                                nomenclature_id: item.nomenclature_id,
                                                // bdd2: item.bdd2,
                                                amount: (
                                                    item.color_code === row.color_code
                                                        ? item.order_amount
                                                        : rowColorSizeAmounts[item.size]
                                                ) || 0
                                            };
                                        };
                                        const allItems = data
                                            .filter(row => !row.is_sum)
                                            .map(preparePayload);

                                        if (allItems.length > 0) {
                                            changeForOrderAmountBatching(allItems);
                                            saveShoppingCartItems(
                                                allItems,
                                                true,
                                                bdd1
                                            );
                                        }
                                        Popup.close();
                                    }}
                                    autoFocus={true}
                                >
                                    Ano
                                </button>
                            </div>
                        </div>
                    )
                });
            }}
        >
            {children}
        </div>
        : <>{children}</>

    return isFinished || row.is_sum ? (
        <span className={classNames({lightGrayColor: !row.is_sum})}>
      <Wrapper>
        <span
            className={classNames({colorRed: row.is_sum && !row.is_total_sum && selectedCommodity && parseInt(row.for_order, 10) !== 0 && (selectedCommodity.moq > parseInt(row.for_order, 10))})}>{formatNumber(row.for_order)}</span>
      </Wrapper>
    </span>
    ) : (
        <NumberInput
            className={classNames("editableField forOrderField", {isZero: row.for_order === 0})}
            step={step}
            value={row.for_order}
            onChange={value => changeForOrderAmount(row.nomenclature_id, value)}
            onBlur={value => {
                const newVal = roundValueUpByPiecesInBox(value, step);
                changeNomenclature(row.nomenclature_id, newVal);
                changeForOrderAmount(row.nomenclature_id, newVal);
                saveShoppingCartItem(
                    row.nomenclature_id,
                    newVal,
                    nomenclatureFilter.bdd1
                );
            }}
        />
    );
};

ForOrderVal.propTypes = {
    row: PropTypes.object.isRequired,
    isFinished: PropTypes.bool.isRequired,
    changeForOrderAmount: PropTypes.func.isRequired,
    saveShoppingCartItem: PropTypes.func.isRequired,
    nomenclatureFilter: PropTypes.object.isRequired
};

export const AppPrediction = ({
                                  row,
                                  isFinished,
                                  changeForOrderAmount,
                                  saveShoppingCartItem,
                                  nomenclatureFilter,
                                  trends
                              }) => {
    const isActionable = !isFinished && !row.is_sum;
    const trend = trends && trends.find(t => t.code === row.xyz);

    const prediction = row.is_sum || (row.efficiencyWithoutOrder >= 0 && row.efficiencyWithoutOrder - 1 <= (trend && trend.min_value)) ? row.app_prediction : 0

    return (
        <div
            className={classNames({clickable: isActionable})}
            onClick={() => {
                if (isActionable) {
                    const newVal = roundValueUpByPiecesInBox(
                        prediction,
                        row.pices_in_box
                    );
                    trackEvent("commodity-table", "fill-order-from-prediction-clicked");
                    changeForOrderAmount(row.nomenclature_id, newVal);
                    saveShoppingCartItem(
                        row.nomenclature_id,
                        newVal,
                        nomenclatureFilter.bdd1
                    );
                }
            }}
        >
            {formatNumber(prediction)}
        </div>
    );
};

AppPrediction.propTypes = {
    row: PropTypes.object.isRequired,
    isFinished: PropTypes.bool.isRequired,
    changeForOrderAmount: PropTypes.func.isRequired,
    saveShoppingCartItem: PropTypes.func.isRequired,
    nomenclatureFilter: PropTypes.object.isRequired
};

export const PredictionAmountVal = ({row, showColorPercentage}) => {
    return (
        <div>
            <div className="predictionAmount">
                {formatNumber(row.prediction_amount)}
            </div>
            <div className="salesAppAmount">{formatNumber(row.sales_app_amount)}</div>
            {showColorPercentage && !row.is_sum && (
                <div className="predictionAmountSum">
                    {row.prediction_amount_sum !== 0
                        ? Math.round(
                            (row.prediction_amount / row.prediction_amount_sum) * 100
                        )
                        : 0}
                    %
                </div>
            )}
        </div>
    );
};

PredictionAmountVal.propTypes = {
    row: PropTypes.object.isRequired,
    showColorPercentage: PropTypes.bool.isRequired
};

export const efficiencyClassName = (eff, row, trend) => {
    const minBdd2 = (trend && trend.min_value) || 0;

    if (eff === -1 || (eff - 1) > minBdd2)
        return "efficiencyVal sufficient-stock";

    if (eff <= minBdd2) {
        return "efficiencyVal out-of-stock";
    }

    return "efficiencyVal insufficient-stock"
};

export const efficiencyVal = (row, updatedEfficiency) => {
    const pluralizeMonths = nr => {
        if (nr === 1) return "měsíc";
        else if (nr > 1 && nr < 5) return "měsíce";
        else return "měsíců";
    };

    const eff = updatedEfficiency || row.efficiency;
    let content;
    if (eff === -1) content = `12+ měsíců`;
    else if (eff === 0) content = "0 měsíců";
    else content = `${eff} ${pluralizeMonths(eff)}`;

    return <div>{!row.is_sum && content}</div>;
};
