import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Header from "../components/Header";
import ShoppingCart from "../components/ShoppingCart";
import { fetchShoppingCart } from "../actions/shoppingCart";
import * as s from "./SuppliersOverview.module.css";
import { fetchSupplierOverview } from "../actions/suppliers";
import { ChevronLeft, Layers } from "react-feather";
import { formatNumber } from "../lib/numbers";
import classNames from "classnames";

function delayClass(delay) {
  if (delay > 50) return s.highDelay;
  else if (delay >= 21) return s.mediumDelay;
  else return s.lowDelay;
}

function SuppliersOverview({
  shoppingCart,
  fetching,
  errors,
  dispatch,
  suppliersOverview
}) {
  useEffect(() => {
    dispatch(fetchShoppingCart());
    dispatch(fetchSupplierOverview());
  }, []);
  const { rows, sum } = suppliersOverview;

  return (
    <div>
      <Header />
      <ShoppingCart
        shoppingCart={shoppingCart}
        isFetching={fetching.shoppingCart}
        error={errors.shoppingCart}
      />
      <section className="fixedMaxSize">
        <div className={s.heading}>
          <div className={s.backLink} onClick={() => window.history.back()}>
            <ChevronLeft className="backIcon" size="24" />
            Zpět
          </div>
          <h1 className={s.headingText}>Přehled dodavatelů</h1>
        </div>
        <table className={s.suppliersTable}>
          <tbody>
            {rows.length !== 0 && (
              <tr className={classNames(s.supplierRow)}>
                <td className={`${s.supTableCell}`} colSpan={3}>
                  <div className={s.supName}>Součet</div>
                </td>
                <td className={`${s.supTableCell} ${s.supCell}`}>
                  <div className={s.supLabel}>Nákup (ks)</div>
                  <div className={s.supSum}>
                    {formatNumber(sum.piecesSum || 0)}
                  </div>
                </td>
                <td className={`${s.supTableCell} ${s.supCell} ${s.rightCell}`}>
                  <div className={s.supLabel}>Hodnota</div>
                  <div className={s.supSum}>
                    {formatNumber(sum.valueSum || 0)}
                  </div>
                </td>
              </tr>
            )}
            {rows.map((sup, idx) => (
              <React.Fragment key={sup.id}>
                <tr className={classNames(s.supplierRow, s.topThickBorder)}>
                  <td className={`${s.supTableCell}`}>
                    <div className={s.supName}>
                      <div>{sup.name}</div>
                      <div className={s.countWrap}>
                        <div>{sup.commodities.length}</div>
                        <Layers className={s.countIcon} />
                      </div>
                    </div>
                  </td>
                  <td className={`${s.supTableCell} ${s.supCell}`}>
                    <div className={s.supLabel}>Již ve výrobě (ks)</div>
                    <div className={s.supSum}>
                      {formatNumber(sup.overview.in_production || 0)}
                    </div>
                  </td>
                  <td className={`${s.supTableCell} ${s.supCell}`}>
                    <div className={s.supLabel}>Zpoždění</div>
                    <div className={s.supSum}>
                      {(parseFloat(sup.overview.delay) || 0).toFixed(2)}%
                    </div>
                  </td>
                  <td className={`${s.supTableCell} ${s.supCell}`}>
                    <div className={s.supLabel}>Nákup (ks)</div>
                    <div className={s.supSum}>
                      {formatNumber(sup.piecesSum)}
                    </div>
                  </td>
                  <td
                    className={`${s.supTableCell} ${s.supCell} ${s.rightCell}`}
                  >
                    <div className={s.supLabel}>Hodnota</div>
                    <div className={s.supSum}>{formatNumber(sup.valueSum)}</div>
                  </td>
                </tr>
                {sup.commodities.map(com => (
                  <tr key={com.commodity}>
                    <td className={`${s.supTableCell}`}>
                      <div className={s.comNameCell}>
                        <div className={s.nomCode}>{com.commodity}</div>
                        <div>{com.commodity_name}</div>
                      </div>
                    </td>
                    <td className={`${s.supTableCell} ${s.numberCenterCell}`}>
                      {formatNumber(com.in_production || 0)}
                    </td>
                    <td
                      className={`${s.supTableCell} ${
                        s.numberCenterCell
                      } ${delayClass(
                        100 / (com.in_production / com.left_to_make)
                      )}`}
                    >
                      {(
                        100 / (com.in_production / com.left_to_make) || 0
                      ).toFixed(2)}
                      %
                    </td>
                    <td className={`${s.supTableCell} ${s.numberCenterCell}`}>
                      {formatNumber(com.pieces)}
                    </td>
                    <td className={`${s.supTableCell} ${s.numberRightCell}`}>
                      {formatNumber(com.value)}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default connect(state => ({
  errors: state.errors,
  fetching: state.fetching,
  mutations: state.mutations,
  shoppingCart: state.shoppingCart,
  suppliersOverview: state.suppliersOverview
}))(withRouter(SuppliersOverview));
