import * as types from "../actions/actionTypes";

const historicPurchase = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURES_SUCCESS:
      return action.response.purchase;
    case types.FETCH_HISTORIC_PURCHASE_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export default historicPurchase;
