import * as types from "../actions/actionTypes";
import { getCookie } from "../lib/auth";

const initialState = {
  "access-token": getCookie("access-token"),
  user: JSON.parse(window.localStorage.getItem("adler-purchase-user") || "{}")
}

const humanMessage = error => {
  try {
    const err = JSON.parse(error);
    switch (err.error) {
      case "err_invalid_credentials":
        return "Neplatné uživatelské jméno nebo heslo";
      default:
        return error.error;
    }
  } catch (e) {
    return error;
  }
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_IN_REQUEST:
      return { ...state, inProgress: true, error: null }

    case types.LOG_IN_SUCCESS:
      return {...state, ...action.response, inProgress: false, error: null}

    case types.LOG_IN_FAILURE:
      return { ...state, inProgress: false, error: humanMessage(action.error) }

    case types.AUTHENTICATION_SESSION_SUCCESS: {
      const response = action.response;
      return { ...state, ...response };
    }

    default:
      return state;
  }
};

export default authentication;
