import * as types from "./actionTypes";
import * as qs from "query-string";
import { fetchWithDispatch } from "../lib/fetch";
import { setFilterParams } from "./nomenclatures";

export const fetchHistoricPurchase = (commodityCode, purchaseFrom, purchaseTo) => (dispatch, getState, { apiUrl }) => {
  dispatch(setFilterParams({ purchase_from: purchaseFrom, purchase_to: purchaseTo }));

  dispatch({
    type: types.FETCH_HISTORIC_PURCHASE_REQUEST,
  });

  const params = qs.stringify({
    commodity_code: commodityCode,
    purchase_from: purchaseFrom,
    purchase_to: purchaseTo,
  });
  return fetchWithDispatch(`${apiUrl}/historic-purchase?${params}`,
    json => dispatch({
      type: types.FETCH_HISTORIC_PURCHASE_SUCCESS,
      response: json
    }),
    error => dispatch({
      type: types.FETCH_HISTORIC_PURCHASE_FAILURE,
      error,
    }));
};
