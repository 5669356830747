import _ from "lodash";

export const STATES = {
  FINISHED: "FINISHED",
  IN_PROGRESS: "IN_PROGRESS",
  NONE: "NONE",
};

export const getCommodityState = (commodityId, shoppingCart) =>
  _.get(shoppingCart, ["ShoppingCartHeaders", commodityId, "state"]);

export const isFinished = (state) =>
  state === STATES.FINISHED;

export const negateState = (state) =>
  isFinished(state) ? STATES.IN_PROGRESS : STATES.FINISHED;
