import * as types from "./actionTypes";
import { fetchWithBodyAndDispatch, fetchWithDispatch } from "../lib/fetch";
import { push } from "react-router-redux";
import _ from "lodash";
import { fetchShoppingCartHeader } from "./shoppingCartHeader";
import {
  fetchNomenclatureEfficiency,
} from "./nomenclatures";

export const fetchShoppingCart = () => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.FETCH_SHOPPING_CART_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/shopping-cart`,
    json =>
      dispatch({
        type: types.FETCH_SHOPPING_CART_SUCCESS,
        response: json
      }),
    error =>
      dispatch({
        type: types.FETCH_SHOPPING_CART_FAILURE,
        error
      })
  );
};

export const fetchShoppingCartHistory = () => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.FETCH_SHOPPING_CART_HISTORY_REQUEST
  });

  return fetchWithDispatch(
    `${apiUrl}/shopping-cart/history`,
    json =>
      dispatch({
        type: types.FETCH_SHOPPING_CART_HISTORY_SUCCESS,
        response: json
      }),
    error =>
      dispatch({
        type: types.FETCH_SHOPPING_CART_HISTORY_FAILURE,
        error
      })
  );
};

export const saveShoppingCartItem = (nomenclatureId, amount, bdd) => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.SAVE_SHOPPING_CART_ITEM_REQUEST,
    nomenclatureId,
    amount
  });
  const commodityCode = _.get(getState(), ["selectedCommodity", "code"]);
  const commodityState = _.get(getState(), [
    "shoppingCart",
    "ShoppingCartHeaders",
    commodityCode,
    "state"
  ]);

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/item`,
    {
      method: "PUT",
      body: {
        nomenclature_id: nomenclatureId,
        amount: parseInt(amount, 10),
        bdd,
      }
    },
    json => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_ITEM_SUCCESS,
        response: json,
        nomenclatureId
      });
      !commodityState && dispatch(fetchShoppingCart());
      dispatch(
        fetchNomenclatureEfficiency(
          nomenclatureId,
          bdd,
          commodityCode
        )
      );
    },
    error => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_ITEM_FAILURE,
        error,
        nomenclatureId
      });
      dispatch(fetchShoppingCartHeader(getState().selectedCommodity.code));
    }
  );
};

export const saveShoppingCartItems = (items, override, bdd1) => (
  dispatch,
  getState,
  { apiUrl }
) => {

  dispatch({
    type: types.SAVE_SHOPPING_CART_ITEMS_REQUEST
  });
  const commodityCode = _.get(getState(), ["selectedCommodity", "code"]);
  const commodityState = _.get(getState(), [
    "shoppingCart",
    "ShoppingCartHeaders",
    commodityCode,
    "state"
  ]);

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/item/batch`,
    {
      method: "PUT",
      body: {
        items,
        override,
        bdd: bdd1,
      }
    },
    json => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_ITEMS_SUCCESS,
        response: json
      });
      !commodityState && dispatch(fetchShoppingCart());
    },
    error => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_ITEMS_FAILURE,
        error
      });
      dispatch(fetchShoppingCartHeader(commodityCode));
    }
  );
};

export const saveShoppingCart = title => (dispatch, getState, { apiUrl }) => {
  const goToShoppingHistory = () => dispatch(push("/purchase-history"));

  dispatch({
    type: types.SAVE_SHOPPING_CART_REQUEST,
    title
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart`,
    {
      method: "PUT",
      body: {
        title
      }
    },
    () => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_SUCCESS,
        title
      });
      goToShoppingHistory();
    },
    error => {
      dispatch({
        type: types.SAVE_SHOPPING_CART_FAILURE,
        error,
        title
      });
    }
  );
};

export const startApproval = () => (dispatch, getState, { apiUrl }) => {

  dispatch({
    type: types.START_APPROVAL_SHOPPING_CART_REQUEST
  });

  return fetchWithBodyAndDispatch(
      `${apiUrl}/shopping-cart:approval`,
      {
        method: "PUT",
        body: {
        }
      },
      () => {
        dispatch({
          type: types.START_APPROVAL_SHOPPING_CART_REQUEST_SUCCESS
        });
        dispatch(fetchShoppingCart());
      },
      error => {
        dispatch({
          type: types.START_APPROVAL_SHOPPING_CART_REQUEST_FAILURE,
          error,
        });
      }
  );
};

export const batchRemove = commodities => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.BATCH_REMOVE_SHOPPING_CART_HEADER_REQUEST,
    commodities
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/batch/remove`,
    {
      method: "PUT",
      body: {
        commodities
      }
    },
    () => {
      dispatch({
        type: types.BATCH_REMOVE_SHOPPING_CART_HEADER_SUCCESS
      });
      dispatch(fetchShoppingCart());
    },
    error => {
      dispatch({
        type: types.BATCH_REMOVE_SHOPPING_CART_HEADER_FAILURE,
        error
      });
    }
  );
};

export const batchChangeState = (commodities, state) => (
  dispatch,
  getState,
  { apiUrl }
) => {
  dispatch({
    type: types.BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_REQUEST,
    commodities
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/shopping-cart/header/batch/state`,
    {
      method: "PUT",
      body: {
        commodities,
        state
      }
    },
    () => {
      dispatch({
        type: types.BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_SUCCESS
      });
      dispatch(fetchShoppingCart());
    },
    error => {
      dispatch({
        type: types.BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_FAILURE,
        error
      });
    }
  );
};

export const exportToKarat = (shoppingCartId, setExportToKaratInProgress) => (dispatch, getState, { apiUrl }) => {
  setExportToKaratInProgress(true);
  dispatch({
    type: types.EXPORT_TO_KARAT_REQUEST
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/keboola/export-plan/${shoppingCartId}`,
    {method: 'POST'},
    json => {
      setExportToKaratInProgress(false);
      return dispatch({
        type: types.EXPORT_TO_KARAT_SUCCESS,
        response: json
      });
    },
    error => {
      setExportToKaratInProgress(false);
      return dispatch({
        type: types.EXPORT_TO_KARAT_FAILURE,
        error
      });
    }
  );
};
