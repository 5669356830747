export const initHotjar = () => {
  const { REACT_APP_HOTJAR_ENABLED, REACT_APP_HOTJAR_ID } = process.env;

  if (REACT_APP_HOTJAR_ENABLED === "true" && REACT_APP_HOTJAR_ID) {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
      h._hjSettings = { hjid: REACT_APP_HOTJAR_ID, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }
};
