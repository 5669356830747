import React from "react";
import { Check, Edit2 } from "react-feather";
import PropTypes from "prop-types";

import { isFinished, negateState } from "../../lib/commodity";
import "./CommodityStateActionButton.css";

const CommodityStateActionButton = ({
  commodityCode,
  state,
  data,
  onOkRedirect,
  saveShoppingCart,
  isSaving,
  params,
  trends,
}) => {
  const nextState = negateState(state);
  const saveOpts = {
    bdd: params.bdd1,
    redirect: onOkRedirect,
  };
  return (
    <button
      className="CommodityStateActionButton"
      disabled={!commodityCode || isSaving}
      onClick={() => {
        return saveShoppingCart(commodityCode, nextState, saveOpts);
        // const dataWithNonConformatoryValues = data.filter(item => {
        //   const trend = trends.find(t => t.code === item.xyz)
        //   if (item.is_sum || !trend) return false
        //   return trend.default_value !== item.bdd2;
        // })
        // if (isFinished(state) || !dataWithNonConformatoryValues.length) {
        //   return saveShoppingCart(commodityCode, nextState, saveOpts);
        // }
        // return Popup.create({
        //   content: (
        //     <div className="FillDataConfirmation">
        //       <div className="content">
        //         <p>
        //           Opravdu chcete položku označit za hotovou? Očekáváná výstačnost některých řádků neodpovídá nastaveným výchozím hodnotám.
        //         </p>
        //         <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        //           <table className='table--condensed'>
        //             <thead>
        //               <tr>
        //                 <th>#</th>
        //                 <th>Jméno</th>
        //                 <th>XYZ</th>
        //                 <th>Očekávaná v.</th>
        //                 <th>Výchozí v.</th>
        //               </tr>
        //             </thead>
        //             <tbody>
        //               {dataWithNonConformatoryValues.map((item, idx) => (
        //                 <tr key={idx}>
        //                   <td>{(idx + 1)}.</td>
        //                   <td>{item.id} {item.name}</td>
        //                   <td>{item.xyz}</td>
        //                   <td>{item.bdd2}</td>
        //                   <td>{(trends.find(t => t.code === item.xyz) || {}).default_value || '-'}</td>
        //                 </tr>
        //               ))}
        //             </tbody>
        //           </table>
        //         </div>
        //       </div>
        //       <div className="actions">
        //         <button className="cancelBtn" onClick={() => Popup.close()}>
        //           Zrušit
        //         </button>
        //         <button
        //           className="confirmBtn"
        //           onClick={() => {
        //             saveShoppingCart(commodityCode, nextState, saveOpts);
        //             Popup.close();
        //           }}
        //           autoFocus={true}
        //         >
        //           Označit za hotovou
        //         </button>
        //       </div>
        //     </div>
        //   )
        // });
      }}
    >
      {isFinished(state) ? "Editovat" : "Označit za hotovou"}
      {isFinished(state) ? (
        <Edit2 className="icon" height="40" width="56" color="#fff" />
      ) : (
        <Check className="icon" height="40" width="56" color="#fff" />
      )}
    </button>
  );
};

CommodityStateActionButton.propTypes = {
  commodityCode: PropTypes.string,
  state: PropTypes.string,
  saveShoppingCart: PropTypes.func.isRequired,
  isSaving: PropTypes.bool
};

export default CommodityStateActionButton;
