import React from "react";
import { isFinished, negateState } from "../../lib/commodity";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { Check, Download, Edit2, MoreVertical, X } from "react-feather";
import { shoppingCartHeaderExportUrl } from "../../lib/urls";
import PropTypes from "prop-types";
import { trackEvent } from "../../lib/ga";

class Menu extends React.Component {
  toggleMenu = e => {
    if (this.contextTrigger) {
      this.contextTrigger.handleContextClick(e);
    }
  };

  render() {
    const { menuActions, commodity, shoppingCart, excludeZero } = this.props;
    const { id, commodity_code, state } = commodity;
    const oppositeState = negateState(state);

    return (
      <div>
        <ContextMenuTrigger id={`contextMenuTrigger-${id}`} ref={c => this.contextTrigger = c}>
          <MoreVertical className="actionsMenu"
                        size="28"
                        onClick={e => this.toggleMenu(e)}/>
        </ContextMenuTrigger>
        <ContextMenu id={`contextMenuTrigger-${id}`}>
          <MenuItem onClick={() => {
            isFinished(state) ?
              trackEvent("shopping-cart", "switch-commodity-to-in-progress") :
              trackEvent("shopping-cart", "switch-commodity-to-finished")
            menuActions.saveShoppingCartHeader(commodity_code, oppositeState);
          }}>
            <div>{isFinished(state) ? "Označit jako rozdělanou" : "Označit za dokončenou"}</div>
            {isFinished(state) ?
              <Edit2 color="#ffaf00" size="28"/> :
              <Check color="#009845" size="28"/>}
          </MenuItem>
          <MenuItem divider/>
          <MenuItem onClick={() => {
            trackEvent("shopping-cart", "export-commodity")
            window.location = shoppingCartHeaderExportUrl(shoppingCart, commodity_code, excludeZero);
          }}>
            Exportovat
            <Download color="#009845"/>
          </MenuItem>
          <MenuItem divider/>
          <MenuItem onClick={() => {
            trackEvent("shopping-cart", "remove-commodity")
            menuActions.removeCommodityFromCart(commodity_code);
          }}>
            <div style={{ color: "#ef1d1d" }}>Vyhodit z košíku</div>
            <X color="#ef1d1d"/>
          </MenuItem>
        </ContextMenu>
      </div>
    );
  }
}

Menu.propTypes = {
  menuActions: PropTypes.object.isRequired,
  commodity: PropTypes.object.isRequired,
  shoppingCart: PropTypes.object.isRequired,
};

export default Menu;
