import * as types from "./actionTypes";
import { fetchWithDispatch } from "../lib/fetch";

export const fetchCategories = () => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.FETCH_CATEGORY_REQUEST,
  });

  return fetchWithDispatch(`${apiUrl}/category`,
    json => dispatch({
      type: types.FETCH_CATEGORY_SUCCESS,
      response: json
    }),
    error => dispatch({
      type: types.FETCH_CATEGORY_FAILURE,
      error,
    }));
};
