import React from "react";
import "./NumberInputWithButtons.css";
import _ from "lodash";
import PropTypes from "prop-types";

class NumberInputWithButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { bdd: parseInt(props.bdd, 10) };
    this.inputRef = React.createRef();
  }

  delayedOnChange = _.debounce((bdd) => {
    this.props.setBDD(bdd);
  }, 1000);

  delayedOnClick = _.debounce((bdd) => {
    this.props.setBDD(bdd);
  }, 200);

  onChange = (e) => {
    e.persist();
    const bdd = e.target.value;
    this.setState({ bdd });
    this.delayedOnChange(bdd);
  };

  onKeyPress = (e) => {
    if (e.key === "Enter")
      this.inputRef.current.blur();
  };

  onClick = () => {
    this.inputRef.current.select();
  };

  setBDD = (bdd) => {
    this.setState({ bdd });
    this.delayedOnClick(bdd);
  };

  render() {
    const { label } = this.props;
    const bdd = this.state.bdd;
    return (
      <div className="numberInput">
        <label className="label" htmlFor={`bdd-${label}`}>{label}</label>
        <div className="controlGroup">
          <button className="action" onClick={() => this.setBDD(parseInt(bdd, 10) - 1)}
                  disabled={parseInt(bdd, 10) <= 1}>
            -
          </button>
          <input type="number"
                 ref={this.inputRef}
                 className="input"
                 id={`bdd-${label}`}
                 min="1"
                 max="12"
                 value={bdd}
                 onClick={e => this.onClick(e)}
                 onKeyPress={e => this.onKeyPress(e)}
                 onChange={e => this.onChange(e)}/>
          <button className="action" onClick={() => this.setBDD(parseInt(bdd, 10) + 1)}
                  disabled={parseInt(bdd, 10) >= 12}>
            +
          </button>
        </div>
      </div>
    );
  }
}

NumberInputWithButtons.propTypes = {
  label: PropTypes.string.isRequired,
  bdd: PropTypes.number.isRequired,
  setBDD: PropTypes.func.isRequired,
};

export default NumberInputWithButtons;
