import React from "react";
import { colorImageUrl } from "../lib/urls";
import L9Img from "../images/missing/colors/L9.gif"

const fallBackColors = {
  L9: L9Img,
}

const getFallBackImg = (colorCode) => {
  return fallBackColors[colorCode];
}

export const ColorImg = ({ alt, className, src, color, width, height }) => {
  const fallBackImg = getFallBackImg(color);
  const source = fallBackImg || src || colorImageUrl(color);
  return <img alt={alt}
              className={className}
              width={width}
              height={height}
              src={source}/>;
};

export default ColorImg;
