import React from "react";
import {connect} from "react-redux";
import "./Commodity.css";
import Header from "../components/Header";
import ShoppingCart from "../components/ShoppingCart";
import "./PurchaseHistory.css";
import {Download} from "react-feather";
import {exportToKarat, fetchShoppingCart, fetchShoppingCartHistory} from "../actions/shoppingCart";
import moment from "moment/moment";
import {dumpError} from "../lib/utils";
import PropTypes from "prop-types";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {trackEvent} from "../lib/ga";

class DownloadExportMenu extends React.Component {
    toggleMenu = e => {
        if (this.contextTrigger) {
            this.contextTrigger.handleContextClick(e);
        }
    };

    render() {
        const {shoppingCart, exportDataToKarat} = this.props;
        const {id} = shoppingCart;

        return (
            <div className="icon">
                <ContextMenuTrigger id={`download-export-menu-${id}`} ref={c => this.contextTrigger = c}>
                    <Download size="28"
                              color="#009845"
                              onClick={e => this.toggleMenu(e)}/>
                </ContextMenuTrigger>
                <ContextMenu id={`download-export-menu-${id}`}>
                    <MenuItem
                        onClick={() => {
                            trackEvent("shopping-cart", "load-to-karat");
                            exportDataToKarat(shoppingCart.id);
                        }}>
                        Nahrát nákupní rozhodnutí do Karátu
                    </MenuItem>
                    {/*<MenuItem
            onClick={() => {
              trackEvent("shopping-cart", "export-all");
              window.location = window.location = shoppingCartExportUrl(shoppingCart);
            }}>
            Export nákupního rozhodnutí
          </MenuItem>
          <MenuItem onClick={() => {
            trackEvent("shopping-cart", "export-proposal");
            window.location = shoppingCartExportProposalUrl(shoppingCart);
          }}>
            Export návrhu aplikace
          </MenuItem>*/}
                </ContextMenu>
            </div>
        );
    }
}

class PurchaseHistory extends React.Component {

    componentDidMount() {
        this.props.fetchShoppingCart();
        this.props.fetchShoppingCartHistory();
    }

    render() {
        const {errors, fetching, shoppingCart, shoppingCartHistory, exportDataToKarat} = this.props;

        return (
            <div className="PurchaseHistory">
                <Header/>

                <ShoppingCart shoppingCart={shoppingCart}
                              showBackLink={true}
                              isFetching={fetching.shoppingCart}
                              error={errors.shoppingCart}/>

                <div className="fixedMaxSize">
                    <section className="heading">
                        Historie nákupů
                    </section>

                    <section>
                        <ul className="purchases">
                            {errors.shoppingCartHistory ?
                                dumpError(errors.shoppingCartHistory) :
                                shoppingCartHistory.map(cart => {
                                    return (
                                        <li key={cart.id} className="purchaseItem">
                                            <div className="date">{moment(cart.updated_at).format("D. M. Y")}</div>
                                            <div className="time">{moment(cart.updated_at).format("H:mm")}</div>
                                            <div className="title">{cart.title}</div>
                                            <DownloadExportMenu shoppingCart={cart} exportDataToKarat={exportDataToKarat}/>
                                        </li>
                                    );
                                })}
                        </ul>
                    </section>
                </div>
            </div>
        );
    }
}

PurchaseHistory.propTypes = {
    errors: PropTypes.object.isRequired,
    fetching: PropTypes.object.isRequired,
    shoppingCart: PropTypes.object.isRequired,
    shoppingCartHistory: PropTypes.array.isRequired,
    fetchShoppingCart: PropTypes.func.isRequired,
    fetchShoppingCartHistory: PropTypes.func.isRequired,
    exportDataToKarat: PropTypes.func.isRequired,
};

export default connect(
    state => ({
        errors: state.errors,
        fetching: state.fetching,
        shoppingCart: state.shoppingCart,
        shoppingCartHistory: state.shoppingCartHistory,
    }),
    dispatch => ({
        fetchShoppingCart: () => dispatch(fetchShoppingCart()),
        fetchShoppingCartHistory: () => dispatch(fetchShoppingCartHistory()),
        exportDataToKarat: (cartId) => dispatch(exportToKarat(cartId, () => {
        })),
    })
)(PurchaseHistory);
