import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { AlertTriangle, Check, Edit2 } from "react-feather";

import { isFinished } from "../../lib/commodity";
import { dumpError } from "../../lib/utils";
import { colorImageUrl } from "../../lib/urls";
import ProductImg from "../../components/ProductImg";
import ColorImg from "../../components/ColorImg";

import "./Commodities.css";

const commodityFullName = commodity =>
  `${commodity.commodity_code} ${commodity.commodity_name}`;

const StateBadge = ({ states, commodityCode, prefix }) => {
  const state = _.get(states, [commodityCode, "state"]);
  let badge;
  if (isFinished(state)) {
    badge = <Check className="icon" color="#009845" size="32" />;
  } else if (state) {
    badge = <Edit2 className="icon" color="#ffaf00" size="32" />;
  }
  return <div className="stateBadge">{prefix}{badge}</div>;
};

StateBadge.propTypes = {
  states: PropTypes.object,
  commodityCode: PropTypes.string.isRequired
};

const Commodity = ({ commodity, selectedColors, onClick, states }) => {
  const id = commodity.commodity_code;
  const colors = commodity.color.map(c => (
    <ColorImg
      width="30"
      height="30"
      alt={c}
      className="color"
      color={c}
      key={c}
      src={colorImageUrl(c)}
    />
  ));

  return (
    <div className="commodityBox" onClick={() => onClick(commodity)}>
      <div className="imgWrap">
        <ProductImg
          alt={commodityFullName(commodity)}
          className="commodityImg"
          commodityId={id}
          colors={commodity.color}
        />
      </div>
      <div>
        <div className="state">
          
          <StateBadge
            states={states}
            commodityCode={commodity.commodity_code}
            prefix={commodity.low_on_stock ? (
              <div className="card-status" style={{marginRight: '1rem'}} title='Nedostatečná výstačnost'>
                <AlertTriangle style={{ color: '#d49538' }} className="icon" />
              </div>
            ) : null}
          />
        </div>
        <div className="code">{commodity.commodity_code}</div>
        <div className="name">{commodity.commodity_name}</div>
        <div>{colors}</div>
        <div className="sizes">
          {commodity.size.map(s => (
            <div key={s} className="size">
              {s}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Commodity.propTypes = {
  commodity: PropTypes.object.isRequired,
  selectedColors: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  states: PropTypes.object
};

const Commodities = ({ commodities, selectedColors, onClick, states, error, isFetching }) => {
  let content;
  if (error) {
    content = dumpError(error);
  } else if (_.isEmpty(commodities) && isFetching) {
    content = <div className="plainMessage">Načítání komodit...</div>;
  } else if (_.isEmpty(commodities) && !isFetching) {
    content = (
      <div className="plainMessage">
        Žádné komodity neodpovídají zadaným filtrům
      </div>
    );
  } else {
    content = _(commodities)
      .map((commodities, name) => {
        return (
          <div key={name}>
            <h2 className="categoryHeading">{name}</h2>
            <div className="CommoditiesGrid">
              {commodities.map(commodity => (
                <Commodity
                  key={commodityFullName(commodity)}
                  commodity={commodity}
                  states={states}
                  selectedColors={selectedColors}
                  onClick={onClick}
                />
              ))}
            </div>
          </div>
        );
      })
      .value();
  }

  return <div className="Commodities">{content}</div>;
};

Commodities.propTypes = {
  commodities: PropTypes.object.isRequired,
  selectedColors: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  states: PropTypes.object,
  error: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default Commodities;
