import { getCookie } from "./auth";

export function fetchWithDispatch(apiUrl, successFn, errorFn) {
  return fetch(apiUrl, {
    headers: {
      "access-token": getCookie("access-token")
    }
  }).then(
    response => {
      if (response.ok) return response.json().then(json => successFn(json));
      else return response.text().then(errorMessage => errorFn(errorMessage));
    },
    error => errorFn(error)
  );
}

export function fetchWithBodyAndDispatch(
  apiUrl,
  fetchOpts,
  successFn,
  errorFn
) {
  const { method, body } = fetchOpts;

  return fetch(apiUrl, {
    method,
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "access-token": getCookie("access-token")
    }
  }).then(
    response => {
      if (response.ok) {
        if (response.status === 200 || response.status === 201)
          return response.json().then(json => successFn(json));
        else return successFn();
      } else return response.text().then(errorMessage => errorFn(errorMessage));
    },
    error => errorFn(error)
  );
}

/**
 * Uses Keboola connector for graph from GoodData
 * API keys and other connection information are provided by BizzTreat
 */
export function fetchGraphFromGoodData(commodityCode, successFn, errorFn) {
  return fetch(
    "https://adler-sso.appspot.com/encrypted-claims/sso+adler@bizztreat.com",
    {
      method: "GET",
      headers: {
        "X-StorageApi-Token": "C49YmHwmDew9jWtWRMPJraE8LrcbNNEt"
      }
    }
  ).then(
    response => {
      if (response.ok) {
        return response.json().then(json => {
          const pgp_signature = json.encryptedClaims;
            const dashboard = `/dashboard.html?label.outcmainrskomodity.komodita=${commodityCode}#project=/gdc/projects/aezbrmitsqs0vhe9fiu2o8z19snul297&dashboard=/gdc/md/aezbrmitsqs0vhe9fiu2o8z19snul297/obj/10427`;

          successFn({pgp_signature, dashboard});
        });
      } else return response.text().then(errorMessage => errorFn(errorMessage));
    },
    error => errorFn(error)
  );
}
