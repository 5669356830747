import * as types from "./actionTypes";
import { fetchWithBodyAndDispatch } from "../lib/fetch";

export const checkEfficiency = () => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.EFFICIENCY_CHECK_REQUEST
  });

  return fetchWithBodyAndDispatch(
    `${apiUrl}/nomenclature/efficiency:check`,
    {
      method: "POST",
    },
    json => {
      dispatch({
        type: types.EFFICIENCY_CHECK_SUCCESS,
        response: json
      });
    },
    error =>
      dispatch({
        type: types.EFFICIENCY_CHECK_FAILURE,
        error
      })
  );
};
