import * as types from "./actionTypes";
import { fetchWithDispatch } from "../lib/fetch";

export const fetchCommodityReport = (commodityId) => (dispatch, getState, { apiUrl }) => {
  dispatch({
    type: types.FETCH_COMMODITY_REPORT_REQUEST,
  });

  return fetchWithDispatch(`${apiUrl}/commodity/${commodityId}/report`,
    json => dispatch({
      type: types.FETCH_COMMODITY_REPORT_SUCCESS,
      response: json
    }),
    error => dispatch({
      type: types.FETCH_COMMODITY_REPORT_FAILURE,
      error,
    }));
};
