/*eslint-disable */
export const initGA = () => {
  const { REACT_APP_GA_ENABLED, REACT_APP_GA_TRACKING_ID } = process.env;

  if (REACT_APP_GA_ENABLED === "true" && REACT_APP_GA_TRACKING_ID) {
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * new Date();
      a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

    window.ga("create", REACT_APP_GA_TRACKING_ID, "auto");
    window.ga("send", "pageview");
  }
};
/*eslint-enable */

export const trackEvent = (eventCategory, eventAction, eventLabel) => {
  if (window.ga) {
    window.ga("send", "event", eventCategory, eventAction, eventLabel);
  }
};
