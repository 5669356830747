import React from "react";
import { productImageUrl } from "../lib/urls";
import NoImage from "../images/noimage.png";

const fallBackColors = {
  109: "01",
  130: "00",
  506: "01",
  550: "80",
  B01: "b1",
  B02: "b1",
  B23: "b3",
  B24: "b3",
  W05: "02"
};

const getFallBackImg = commodityId => {
  const fallbackColors = fallBackColors[commodityId];
  return fallbackColors
    ? productImageUrl(commodityId, fallbackColors)
    : NoImage;
};

export const ProductImg = ({ alt, className, commodityId, colors }) => {
  const [colorsToTry, setColorsToTry] = React.useState(colors);
  const src = colorsToTry[0]
    ? productImageUrl(commodityId, colorsToTry[0])
    : getFallBackImg(commodityId);

  return (
    <img
      alt={alt}
      className={className}
      onError={e => {
        if (colorsToTry.length === 1) e.target.onerror = null;
        setColorsToTry(colorsToTry.slice(1, colorsToTry.length));
      }}
      src={src}
    />
  );
};

export default ProductImg;
