import * as types from "../actions/actionTypes";

const shoppingCartHistory = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_HISTORY_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

export default shoppingCartHistory;
