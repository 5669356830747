import React, { useState, useMemo } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Check, Edit2 } from "react-feather";
import Menu from "./Menu";
import Truck from "../../images/truck.svg";
import {
  batchSaveSupplierHeader,
  fetchSuppliers
} from "../../actions/suppliers";
import { connect } from "react-redux";
import s from "../Supplier.module.css";
import { fetchShoppingCartHeader } from "../../actions/shoppingCartHeader";
import { fetchShoppingCart } from "../../actions/shoppingCart";
import { push } from "react-router-redux";
import { isSuperset } from "../../lib/set";
import Checkbox from "../../components/Checkbox/Checkbox";

const goToAssignSupplier = (dispatch, commodityCode) =>
  dispatch(push(`/commodity/${commodityCode}/assign-supplier`));

function AssignSuppliersMenu({
  suppliers,
  dispatch,
  commodityCode,
  shoppingCartHeader,
  allowAssigment
}) {
  const [isOpened, setOpened] = useState(false);
  const nomenclatures = Object.values(shoppingCartHeader);
  const nomenclatureIds = useMemo(
    () => new Set(nomenclatures.map(n => n.nomenclature_id)),
    [nomenclatures]
  );
  const allowedNomenclaturesBySupplier = useMemo(
    () =>
      suppliers.reduce((acc, sup) => {
        acc[sup.id] = new Set(sup.Nomenclatures.map(n => n.nomenclature_id));
        return acc;
      }, {}),
    [suppliers]
  );
  const allowedSuppliers = suppliers.filter(sup =>
    isSuperset(allowedNomenclaturesBySupplier[sup.id], nomenclatureIds)
  );

  return (
    <span
      tabIndex="0"
      className={s.addSupplier}
      onBlur={() => setOpened(false)}
      onClick={e => {
        e.stopPropagation();
        setOpened(true);
      }}
    >
      <img
        src={Truck}
        alt="Dodavatelé"
        className="suppliersMenuIcon"
        onMouseEnter={() => {
          dispatch(fetchSuppliers(commodityCode));
          dispatch(fetchShoppingCartHeader(commodityCode));
        }}
        onClick={() => {
          !allowAssigment && goToAssignSupplier(dispatch, commodityCode);
        }}
      />
      {isOpened && (
        <ul className={s.suppliersMenu}>
          {allowedSuppliers.length > 0 &&
            allowedSuppliers.map(sup => (
              <li
                className={s.suppliersMenuItem}
                onClick={e => {
                  e.stopPropagation();
                  dispatch(
                    batchSaveSupplierHeader(
                      commodityCode,
                      nomenclatures.map(nom => ({
                        nomenclature_id: nom.nomenclature_id,
                        supplier_id: sup.id,
                        amount: nom.amount
                      }))
                    )
                  ).then(() => dispatch(fetchShoppingCart()));
                }}
                key={sup.id}
              >
                {sup.name}
              </li>
            ))}
          <li
            className={`${s.suppliersMenuItem} ${s.suppliersMenuItemDetail}`}
            onClick={() => goToAssignSupplier(dispatch, commodityCode)}
          >
            Detail
          </li>
        </ul>
      )}
    </span>
  );
}

function suppliersLabel(suppliersCount, commodity) {
  if (suppliersCount === 1) {
    return commodity.suppliers;
  } else if (suppliersCount > 1) {
    return `${suppliersCount} dodavatelé`;
  }
}

const CommodityItem = ({
  commodity,
  menuActions,
  shoppingCart,
  excludeZero,
  showSuppliers,
  dispatch,
  suppliers,
  shoppingCartHeader,
  selectedCommodities,
  selectCommodity
}) => {
  const commodityName = commodity.commodity_name;
  const commodityCode = commodity.commodity_code;
  const updatedAt = commodity.updated_at;
  const suppliersCount = commodity.suppliers ? commodity.suppliers.length : 0;
  const suppliersNames = (commodity.suppliers, []).join(", ");

  return (
    <li className="commodityItem">
      <Checkbox
        id={`chbx-${commodityCode}`}
        className="checkbox"
        checked={!!selectedCommodities[commodityCode]}
        onChange={e => selectCommodity(commodityCode, e.target.checked)}
      />
      <Link to={`/commodity/${commodityCode}`} className="iconTextWrap">
        <div className="code">{commodityCode}</div>
        <div className="name">{commodityName}</div>
        <div className="date">{moment(updatedAt).format("D. M. Y H:mm")}</div>
      </Link>
      <div className="endColumn">
        <div className="endColumnInfo">
          {showSuppliers && (
            <>
              <span className="suppliers" title={suppliersNames}>
                {suppliersLabel(suppliersCount, commodity)}
              </span>
              <AssignSuppliersMenu
                suppliers={suppliers.allSuppliers}
                commodityCode={commodityCode}
                dispatch={dispatch}
                shoppingCartHeader={shoppingCartHeader}
                allowAssigment={suppliersCount === 0}
              />
              {commodity.completed_suppliers ? (
                <Check
                  size="28"
                  color="#009845"
                  title="Všechny nomenklatury mají přiřazeného dodavatele"
                />
              ) : (
                <Edit2
                  size="28"
                  color="#ffaf00"
                  title="Některé nomenklatury nemají přiřazeného dodavatele"
                />
              )}
            </>
          )}
          <Menu
            commodity={commodity}
            menuActions={menuActions}
            shoppingCart={shoppingCart}
            excludeZero={excludeZero}
          />
        </div>
      </div>
    </li>
  );
};

export default connect(state => ({
  suppliers: state.suppliers,
  shoppingCartHeader: state.shoppingCartHeader
}))(CommodityItem);
