import React from "react";
import "./Header.css";
import Logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import { Menu } from "react-feather";
import { logout } from "../lib/auth";
import { withRouter } from "react-router";
import { connect } from "react-redux";

class UserMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  close = () => {
    this.setState({ opened: false });
  };

  render() {
    const opened = this.state.opened;
    return (
      <div className="menu" tabIndex="0" onBlur={() => this.close()}>
        <Menu className="clickable" size="28" onClick={() => this.setState({ opened: !opened })}/>
        {opened &&
        <div className="menuContent">
          <div className="menuItem">
            <strong>{this.props.user.unique_name}</strong>
          </div>
          <div className="menuItem clickable" onClick={() => this.props.history.push("/purchase-history")}>
            Historie nákupů
          </div>
          <div className="menuItem clickable" onClick={() => this.props.history.push("/commodity-administration")}>
            Administrace komodit
          </div>
          <div className="menuItem clickable" onClick={() => logout()}>
            Odhlásit
          </div>
        </div>
        }
      </div>
    );
  }
}

const Header = ({ history, user }) => {
  return (
    <header className="Header">
      <div className="HeaderInner fixedMaxSize">
        <div className="imgHeadingWrap">
          <Link to="/">
            <img src={Logo} width="60" height="60" alt="Malfini"/>
          </Link>
          <h1>Nákup</h1>
        </div>
        <UserMenu history={history} user={user}/>
      </div>
    </header>
  );
};

export default connect(state => ({
  user: state["authentication"]["user"]
}))(withRouter(Header));
