import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const NoMatch = ({ location }) => (
  <div className="fixedMaxSize">
    <h3>
      Tato adresa neexistuje <code>{location.pathname}</code>
    </h3>

    <p>
      Vrátit se zpět na <Link to="/">úvodní stránku</Link>.
    </p>
  </div>
);

NoMatch.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NoMatch;
