import * as types from "../actions/actionTypes";

const keboola = (state = [], action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SESSION_SUCCESS:
      const response = action.response;
      return { import: response.keboolaLog, export: response.keboolaLoadDataLog };
    case types.KEBOOLA_IMPORT_REQUEST:
      return { import: {...action.response, status: "waiting" } };
    case types.KEBOOLA_IMPORT_SUCCESS:
      return { import: action.response };
    case types.KEBOOLA_IMPORT_FAILURE:
      return { import: {...action.response, status: "failed"} };
    case types.EXPORT_TO_KARAT_REQUEST:
      return { export: {...action.response, status: "waiting" } };
    case types.EXPORT_TO_KARAT_SUCCESS:
      return { export: action.response };
    case types.EXPORT_TO_KARAT_FAILURE:
      return { export: {...action.response, status: "failed"} };
    default:
      return state;
  }
};

export default keboola;
