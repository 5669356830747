import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import Header from "../components/Header";
import NumberInput from "./Commodity/NumberInput";
import { fetchCommodities, changeCommodity, saveCommodity } from "../actions/commodities";
import { fetchTrends, changeTrend, saveTrend } from "../actions/trends";
import "./CommodityAdministration.css";

class CommodityAdministration extends React.Component {

  componentDidMount() {
    this.props.fetchCommodities();
    this.props.fetchTrends();
  }

  render() {
    const {
      fetching,
      commodities,
      changeCommodity,
      saveCommodity,
      trends,
      changeTrend,
      saveTrend,
    } = this.props;

    return (
      <div>
        <div className="CommodityAdministration">
          <Header/>
          <div className="fixedMaxSize">
            <section className="heading">
              Výstačnost
            </section>
            {fetching.trends ? (
              <div className="text-align-center">Načítání..</div>
            ) : (
              <section className="contentWrapper">
                {trends && trends.length ? (
                  <table className="Table dataTable">
                    <thead>
                      <tr className="headerRow">
                        <th className="rightBorder">Kód</th>
                        <th className="rightBorder">Min</th>
                        <th className="">Max</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trends.map((trend, idx) => (
                        <tr key={trend.code} className={classNames("dataRow", {
                          isOdd: (idx + 1) % 2 !== 0
                        })}>
                          <td><strong>{trend.code}</strong></td>
                          <td style={{ padding: '0 5px' }}>
                            <input
                              style={{ width: 'calc(100% - 6px)' }}
                              className={'editableField'}
                              type='number'
                              value={trend.min_value}
                              onChange={
                                /* value => changeTrend(Object.assign(trend, { min_value: value })) */
                                e => {
                                  let value = e.target.value
                                  if (value >= trend.max_value) value = trend.max_value - 1
                                  changeTrend(Object.assign(trend, { min_value: value }))
                                }
                              }
                              onBlur={value => {
                                saveTrend(trend)
                              }}
                              placeholder='Min'
                              name='min_value'
                              min='0'
                              max={trend.max_value}
                              step='1'
                            />
                          </td>
                          <td style={{ padding: '0 5px' }}>
                            <input
                              style={{ width: 'calc(100% - 6px)' }}
                              className={'editableField'}
                              type='number'
                              value={trend.max_value}
                              onChange={
                                /* value => changeTrend(Object.assign(trend, { max_value: value })) */
                                e => {
                                  let value = e.target.value
                                  if (value <= trend.min_value) value = trend.min_value + 1
                                  changeTrend(Object.assign(trend, { max_value: value }))
                                }
                              }
                              onBlur={value => {
                                saveTrend(trend)
                              }}
                              placeholder='Max'
                              name='max_value'
                              min={trend.min_value}
                              max='9001'
                              step='1'
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : 'Nejsou nastaveny žádné trendy'}
              </section>
            )}
          </div>

          <div className="fixedMaxSize">
            <section className="heading">
              Administrace komodit
            </section>

            {fetching.commodities ? (
              <div className="text-align-center">Načítání..</div>
            ) : (
              <section className="contentWrapper">
                <table className="Table dataTable">
                  <thead>
                    <tr className="headerRow">
                      <th className="rightBorder">Kód</th>
                      <th className="rightBorder">Název</th>
                      <th className="">BD</th>
                      {/*<th>Výstačnost</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {commodities.map((item, idx) => (
                      <tr key={item.id} className={classNames("dataRow", {
                        isOdd: (idx + 1) % 2 !== 0
                      })}>
                        <td className="rightBorder">{item.commodity_code}</td>
                        <td className="text-align-left padding-left rightBorder">{item.commodity_name}</td>
                        <td className="rightBorder">
                          <NumberInput
                            max={12}
                            className={'editableField'}
                            value={item.commodity_bdd}
                            onChange={value => changeCommodity(Object.assign(item, { commodity_bdd: Math.min(Math.max(value, 1), 12) }))}
                            onBlur={value => {
                              saveCommodity(item)
                            }}
                          />
                        </td>
                        {/*<td>
                          <NumberInput
                            max={12}
                            className={'editableField'}
                            value={item.commodity_bdd2}
                            onChange={value => changeCommodity(Object.assign(item, { commodity_bdd2: Math.min(Math.max(value, 1), 12) }))}
                            onBlur={value => {
                              saveCommodity(item)
                            }}
                          />
                        </td>*/}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CommodityAdministration.propTypes = {
  commodities: PropTypes.array.isRequired,
  fetchCommodities: PropTypes.func.isRequired,
  fetchTrends: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    commodities: state.commodities,
    trends: state.trends,
    fetching: state.fetching,
  }),
  dispatch => ({
    fetchCommodities: () => dispatch(fetchCommodities()),
    fetchTrends: () => dispatch(fetchTrends()),
    saveCommodity: (commodity) => dispatch(saveCommodity(commodity)),
    changeCommodity: (commodity) => dispatch(changeCommodity(commodity)),
    saveTrend: (trend) => dispatch(saveTrend(trend)),
    changeTrend: (trend) => dispatch(changeTrend(trend)),
  })
)(CommodityAdministration);
