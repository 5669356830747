import * as types from "../actions/actionTypes";
import { combineReducers } from "redux";
import _ from "lodash";

const deletingShoppingCartHeader = (state = false, action) => {
  switch (action.type) {
    case types.DELETE_SHOPPING_CART_HEADER_REQUEST:
      return _(state).set(action.commodityCode, true).clone();
    case types.DELETE_SHOPPING_CART_HEADER_SUCCESS:
      return _(state).set(action.commodityCode, false).clone();
    case types.DELETE_SHOPPING_CART_HEADER_FAILURE:
      return _(state).set(action.commodityCode, false).clone();
    default:
      return state;
  }
};

const savingShoppingCart = (state = false, action) => {
  switch (action.type) {
    case types.SAVE_SHOPPING_CART_REQUEST:
      return true;
    case types.SAVE_SHOPPING_CART_SUCCESS:
      return false;
    case types.SAVE_SHOPPING_CART_FAILURE:
      return false;
    default:
      return state;
  }
};

const savingShoppingCartHeader = (state = false, action) => {
  switch (action.type) {
    case types.SAVE_SHOPPING_CART_HEADER_REQUEST:
      return _(state).set(action.commodityCode, true).clone();
    case types.SAVE_SHOPPING_CART_HEADER_SUCCESS:
      return _(state).set(action.commodityCode, false).clone();
    case types.SAVE_SHOPPING_CART_HEADER_FAILURE:
      return _(state).set(action.commodityCode, false).clone();
    default:
      return state;
  }
};

const savingShoppingCartItem = (state = false, action) => {
  switch (action.type) {
    case types.SAVE_SHOPPING_CART_ITEM_REQUEST:
      return _(state).set(action.nomenclatureId, true).clone();
    case types.SAVE_SHOPPING_CART_ITEM_SUCCESS:
      return _(state).set(action.nomenclatureId, false).clone();
    case types.SAVE_SHOPPING_CART_ITEM_FAILURE:
      return _(state).set(action.nomenclatureId, false).clone();
    default:
      return state;
  }
};

const mutations = combineReducers({
  deletingShoppingCartHeader,
  savingShoppingCart,
  savingShoppingCartHeader,
  savingShoppingCartItem,
});

export default mutations;
