import React from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { roundValueUpByPiecesInBox } from "./cellRenders";
import Popup from "react-popup/dist/index";
import classNames from "classnames";

export const formatDate = (from, to) => {
  return `${moment(from).format("M/YYYY")} - ${moment(to).format("M/YYYY")}`;
};

export const formatDateDMY = from => {
  return moment(from).format("D. M. YYYY");
};

export const PredictionAmountColLabel = ({ predictionFrom, predictionTo }) => (
  <div>
    <div>
      Predikce/
      <br />
      Plán CM
    </div>
    <div className="date">{formatDate(predictionFrom, predictionTo)}</div>
  </div>
);

PredictionAmountColLabel.propTypes = {
  predictionFrom: PropTypes.string.isRequired,
  predictionTo: PropTypes.string.isRequired
};

export const PurchaseAmountLabel = ({ purchaseFrom, purchaseTo }) => (
  <div>
    <div>
      Historický
      <br />
      prodej
    </div>
    <div className="date">{formatDate(purchaseFrom, purchaseTo)}</div>
  </div>
);

PurchaseAmountLabel.propTypes = {
  purchaseFrom: PropTypes.string.isRequired,
  purchaseTo: PropTypes.string.isRequired
};

class FillDataConfirmation extends React.Component {
  toggleOverride = () => this.setState({ override: !this.state.override });

  constructor() {
    super();
    this.state = { override: false };
  }

  render() {
    const {
      amountGetter,
      confirmationText,
      data,
      overrideDisabled,
      changeForOrderAmountBatching,
      saveShoppingCartItems,
      bdd1,
    } = this.props;
    return (
      <div className="FillDataConfirmation">
        <p className="content">
          {confirmationText}
        </p>
        <div>
          {!overrideDisabled && (
            <label className="checkbox" htmlFor="override">
              <input
                type="checkbox"
                id="override"
                name="override"
                onClick={() => this.toggleOverride()}
              />
              přepsat i již vyplněné hodnoty
            </label>
          )}
        </div>
        <div className="actions">
          <button className="cancelBtn" onClick={() => Popup.close()}>
            Zrušit
          </button>
          <button
            className="confirmBtn"
            onClick={() => {
              const preparePayload = row => {
                const newAmount = amountGetter(row);
                return {
                  nomenclature_id: row.nomenclature_id,
                  amount: newAmount
                };
              };
              const allItems = data
                .filter(row => !row.is_sum)
                .map(preparePayload);
              const itemsWithoutOverride = data
                .filter(row => !row.is_sum && row.for_order === 0)
                .map(preparePayload);

              if (allItems.length > 0) {
                const prefillForOrder = (this.state.override || overrideDisabled)
                  ? allItems
                  : itemsWithoutOverride;
                changeForOrderAmountBatching(prefillForOrder);
                saveShoppingCartItems(
                  allItems,
                  this.state.override || overrideDisabled,
                  bdd1
                );
              }
              Popup.close();
            }}
            autoFocus={true}
          >
            Ano
          </button>
        </div>
      </div>
    );
  }
}

FillDataConfirmation.propTypes = {
  amountGetter: PropTypes.func,
  confirmationText: PropTypes.string,
  data: PropTypes.array.isRequired,
  changeForOrderAmountBatching: PropTypes.func.isRequired,
  overrideDisabled: PropTypes.bool,
  saveShoppingCartItems: PropTypes.func.isRequired,
  bdd1: PropTypes.number.isRequired,
};

export const OrderLabel = ({
  data,
  changeForOrderAmountBatching,
  saveShoppingCartItems,
  bdd1,
  isCommodityFinished
}) => (
  <div className={classNames({ clickable: !isCommodityFinished })}
    onClick={() => {
      if (isCommodityFinished) return;

      Popup.create({
        content: (
          <FillDataConfirmation
            amountGetter={() => 0}
            bdd1={bdd1}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            confirmationText={'Opravdu chcete vynulovat sloupec "Objednat"?'}
            data={data}
            overrideDisabled={true}
            saveShoppingCartItems={saveShoppingCartItems}
          />
        )
      });
    }}
  >
    Objednat
  </div>
)

export const ProductionLabel = ({
  data,
  changeForOrderAmountBatching,
  saveShoppingCartItems,
  bdd1,
  isCommodityFinished
}) => (
  <div
    className={classNames({ clickable: !isCommodityFinished })}
    onClick={() => {
      if (isCommodityFinished) return;

      Popup.create({
        content: (
          <FillDataConfirmation
            amountGetter={row => row.in_production_1}
            bdd1={bdd1}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            confirmationText={'Opravdu chcete vyplnit všechna nevyplněná políčka ve sloupci "Objednat" hodnotami ze sloupce "Výroba"?'}
            data={data}
            overrideDisabled={true}
            saveShoppingCartItems={saveShoppingCartItems}
          />
        )
      });
    }}
  >
    Výroba
  </div>
);

export const AppPredictionLabel = ({
  data,
  changeForOrderAmountBatching,
  saveShoppingCartItems,
  bdd1,
  isCommodityFinished,
  trends
}) => (
  <div
    className={classNames({ clickable: !isCommodityFinished })}
    onClick={() => {
      if (isCommodityFinished) return;

      Popup.create({
        content: (
          <FillDataConfirmation
            amountGetter={row => {
                const trend = trends.find(t => t.code === row.xyz);
                const prediction = row.efficiencyWithoutOrder >= 0 && row.efficiencyWithoutOrder - 1 <=  trend.min_value ? row.app_prediction : 0
                return roundValueUpByPiecesInBox(
                  prediction,
                  row.pices_in_box)
            }}
            confirmationText={'Opravdu chcete vyplnit všechna nevyplněná políčka ve sloupci "Objednat" hodnotami ze sloupce "Návrh"?'}
            data={data}
            bdd1={bdd1}
            changeForOrderAmountBatching={changeForOrderAmountBatching}
            saveShoppingCartItems={saveShoppingCartItems}
          />
        )
      });
    }}
  >
    Návrh
  </div>
);

AppPredictionLabel.propTypes = {
  data: PropTypes.array.isRequired,
  changeForOrderAmountBatching: PropTypes.func.isRequired,
  saveShoppingCartItems: PropTypes.func.isRequired,
  bdd1: PropTypes.number.isRequired,
  trends: PropTypes.array.isRequired,
};
