import * as types from "../actions/actionTypes";
import _ from "lodash";

const commodities = (state = [], action) => {
  switch (action.type) {
    case types.FETCH_COMMODITIES_SUCCESS:
      return action.commodities;
    case types.CHANGE_COMMODITY:
      const item = state.find(item => item.commodity_code === action.payload.commodity_code)
      if (item) {
        Object.assign(item, action.payload);
        return [...state];
      }
      return state;
    default:
      return state;
  }
};

export default commodities;

export const groupByCategoryName = (state) =>
  _.groupBy(state.commodities, "category_name");
