export const formatNumber = number => {
  const float = parseFloat(number);
  return float.toLocaleString();
};

export const floorAndFormatNumber = number => formatNumber(Math.floor(number));

export const percentage = (value, total) => {
  const prc = (value / total) * 100;
  return prc > 100 ? 100 : prc;
};

export const czkToLocalRounded = (czkPrice, exchangeRate) =>
  Math.floor(czkPrice / exchangeRate);

export const getThousandSeparator = locale => {
  const numberWithDecimalSeparator = 1000;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find(part => part.type === "group").value;
};
