import React from "react";
import Popup from "react-popup";
import _ from "lodash";
import { ShoppingBag } from "react-feather";
import PropTypes from "prop-types";
import { trackEvent } from "../../lib/ga";

class ClosePurchasePopup extends React.Component {
  constructor() {
    super();
    this.state = { title: "" };
  }

  render() {
    return (
      <div className="ClosePurchasePopup">
        <div className="heading">
          <div className="iconWrap">
            <ShoppingBag className="icon" size="20" color="#FFF"/>
          </div>
          <div className="title">Ukončení nákupního rozhodnutí</div>
          <div className="close" onClick={() => Popup.close()}>Zavřít</div>
        </div>
        <div className="body">
          <div className="exclamationMarkIcon">!</div>
          <p className="warningText">
            Ukončení nákupního rozhodnutí je nevratná akce. Vynuluje se nákupní košík a vygeneruje se soubor s nákupním
            rozhodnutím do programu Excel, obsahující nomenklatury k nákupu. Tyto soubory lze zpětně dohledat v historii
            nákupních rozhodnutí.
          </p>
          <div className="inputGroup">
            <label>Název nákupního rozhodnutí</label>
            <input value={this.state.title}
                   className="inputTitle"
                   onChange={e => this.setState({ title: e.target.value })}/>
          </div>
          <button onClick={() => {
            trackEvent("shopping-cart", "end-purchase");
            this.props.saveShoppingCart(this.state.title);
          }}
                  disabled={_.isEmpty(this.state.title)}>
            Ukončit nákup
          </button>
        </div>
      </div>
    );
  }
}

ClosePurchasePopup.propTypes = {
  saveShoppingCart: PropTypes.func.isRequired,
};

export default ClosePurchasePopup;
