import * as types from "../actions/actionTypes";

const filters = (state = {}, action) => {
  switch (action.type) {
    case types.QUICK_SEARCH:
      return {
        ...state,
        quickSearch: action.searchTerm,
      };
    case types.RESET_BRAND_FILTER:
      return {
        ...state,
        brands: [...(state.defaultBrands || [])],
      };
    case types.UPDATE_FILTER:
      return {
        ...state,
        [action.filter]: action.value.map(brandCode => {
          const existingBrand = (state.defaultBrands || []).find(item => item.code === brandCode)
          return {
            name: existingBrand ? existingBrand.name : brandCode,
            code: brandCode,
          }
        }),
      };
    case types.FETCH_INITIAL_FILTERS_SUCCESS:
      return {
        quickSearch: "",
        categories: action.filterValues.category,
        colors: action.filterValues.color,
        sizes: action.filterValues.size,
        brands: action.filterValues.brand,
        defaultBrands: action.filterValues.brand,
      };
    default:
      return state;
  }
};

export default filters;
