import * as types from "../actions/actionTypes";
import { combineReducers } from "redux";

const categories = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_CATEGORY_REQUEST:
      return true;
    case types.FETCH_CATEGORY_SUCCESS:
      return false;
    case types.FETCH_CATEGORY_FAILURE:
      return false;
    default:
      return state;
  }
};

const commodities = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITIES_REQUEST:
      return true;
    case types.FETCH_COMMODITIES_SUCCESS:
      return false;
    case types.FETCH_COMMODITIES_FAILURE:
      return false;
    default:
      return state;
  }
};

const trends = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_TRENDS_REQUEST:
      return true;
    case types.FETCH_TRENDS_SUCCESS:
      return false;
    case types.FETCH_TRENDS_FAILURE:
      return false;
    default:
      return state;
  }
};

const commodityGraphUrl = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITY_GRAPH_REQUEST:
      return true;
    case types.FETCH_COMMODITY_GRAPH_SUCCESS:
      return false;
    case types.FETCH_COMMODITY_GRAPH_FAILURE:
      return false;
    default:
      return state;
  }
};

const filters = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_INITIAL_FILTERS_REQUEST:
      return true;
    case types.FETCH_INITIAL_FILTERS_SUCCESS:
      return false;
    case types.FETCH_INITIAL_FILTERS_FAILURE:
      return false;
    default:
      return state;
  }
};

const historicPurchase = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_HISTORIC_PURCHASE_REQUEST:
      return true;
    case types.FETCH_HISTORIC_PURCHASE_SUCCESS:
      return false;
    case types.FETCH_HISTORIC_PURCHASE_FAILURE:
      return false;
    default:
      return state;
  }
};

const nomenclatures = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURES_REQUEST:
      return true;
    case types.FETCH_NOMENCLATURES_SUCCESS:
      return false;
    case types.FETCH_NOMENCLATURES_FAILURE:
      return false;
    default:
      return state;
  }
};

const nomenclatureDrillDownInProduction = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_REQUEST:
      return true;
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_SUCCESS:
      return false;
    case types.FETCH_NOMENCLATURE_IN_PRODUCTION_FAILURE:
      return false;
    default:
      return state;
  }
};

const nomenclatureDrillDownExpediting = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_NOMENCLATURE_EXPEDITING_REQUEST:
      return true;
    case types.FETCH_NOMENCLATURE_EXPEDITING_SUCCESS:
      return false;
    case types.FETCH_NOMENCLATURE_EXPEDITING_FAILURE:
      return false;
    default:
      return state;
  }
};

const salesPlan = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SALES_PLAN_REQUEST:
      return true;
    case types.FETCH_SALES_PLAN_SUCCESS:
      return false;
    case types.FETCH_SALES_PLAN_FAILURE:
      return false;
    default:
      return state;
  }
};

const shoppingCart = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_REQUEST:
      return true;
    case types.FETCH_SHOPPING_CART_SUCCESS:
      return false;
    case types.FETCH_SHOPPING_CART_FAILURE:
      return false;
    default:
      return state;
  }
};

const shoppingCartHeader = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_HEADER_REQUEST:
      return true;
    case types.FETCH_SHOPPING_CART_HEADER_SUCCESS:
      return false;
    case types.FETCH_SHOPPING_CART_HEADER_FAILURE:
      return false;
    default:
      return state;
  }
};

const shoppingCartHistory = (state = false, action) => {
  switch (action.type) {
    case types.FETCH_SHOPPING_CART_HISTORY_REQUEST:
      return true;
    case types.FETCH_SHOPPING_CART_HISTORY_SUCCESS:
      return false;
    case types.FETCH_SHOPPING_CART_HISTORY_FAILURE:
      return false;
    default:
      return state;
  }
};

const commodityReport = (state = null, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITY_REPORT_REQUEST:
      return true;
    case types.FETCH_COMMODITY_REPORT_SUCCESS:
      return false;
    case types.FETCH_COMMODITY_REPORT_FAILURE:
      return false;
    default:
      return state;
  }
};

const keboola = (state = null, action) => {
  switch (action.type) {
    case types.KEBOOLA_IMPORT_REQUEST:
      return true;
    case types.KEBOOLA_IMPORT_SUCCESS:
      return false;
    case types.KEBOOLA_IMPORT_FAILURE:
      return false;
    default:
      return state;
  }
};

const session = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SESSION_REQUEST:
      return true;
    case types.AUTHENTICATION_SESSION_SUCCESS:
      return false;
    case types.AUTHENTICATION_SESSION_FAILURE:
      return false;
    default:
      return state;
  }
};

const fetching = combineReducers({
  categories,
  commodities,
  commodityGraphUrl,
  commodityReport,
  filters,
  historicPurchase,
  keboola,
  nomenclatureDrillDownExpediting,
  nomenclatureDrillDownInProduction,
  nomenclatures,
  salesPlan,
  session,
  shoppingCart,
  shoppingCartHeader,
  shoppingCartHistory,
  trends,
});

export default fetching;
