import * as React from "react";
import * as s from "./NomenclaturesTable.module.css";
import classNames from "classnames";
import ColorImg from "../../components/ColorImg";
import { formatDateDMY } from "../Commodity/columnHeaderRenderers";
import NewIcon from "../../images/new-icon.svg";
import RebrandingIcon from "../../images/rebranding-icon.svg";
import { ChevronLeft, Layers } from "react-feather";
import { Link } from "react-router-dom";

export const formatNumber = number => {
  const float = parseFloat(number);
  return float.toLocaleString();
};

function typeLabel(type) {
  return type === "ORDER" ? "OV - Na cestě" : "PV - Ve výrobě";
}

function FirstColLabel({ nom }) {
  let content = "";
  if (nom.id) {
    content = (
      <div className={s.nomFirstCol}>
        <ColorImg color={nom.color_code} className={s.nomColorCode} />
        <span className={s.nomId}>{nom.id}</span>
        <span className={s.nomSize}>{nom.Size.name}</span>
        <span className="isNew icon">
          {nom.new && <img src={NewIcon} alt="Novinka" title="Novinka " />}
        </span>
        <span className="isRebranding icon">
          {nom.rebranded && (
            <img src={RebrandingIcon} alt="Rebranding" title="Rebranding" />
          )}
        </span>
      </div>
    );
  } else if (nom.is_sum) {
    content = (
      <div className={s.colorFirstCol}>
        <ColorImg color={nom.color_code} className={s.nomColorCode} />
        <span className={s.nomId}>{nom.id}</span>
        <span className={s.nomName}>Celkem</span>
      </div>
    );
  } else if (nom.is_total_sum) {
    content = (
      <div className={s.totalSumFirstCol}>
        <Layers className={s.totalSumFirstColIcon} />
        Celkem
      </div>
    );
  }

  return (
    <td
      className={classNames(`${s.leftFixedCol} ${s.nomCell}`, {
        [s.totalSumRow]: nom.is_total_sum
      })}
    >
      {content}
    </td>
  );
}

export const CommodityReport = ({ items }) => {
  return (
    <section>
      <div className={s.header}>
        <Link className={s.backLink} to={`/commodity/${items.commodity.code}`}>
          <ChevronLeft className="backIcon" size="24" />
          Zpět
        </Link>
        <h1 className={s.mainTitle}>
          {items.commodity.code} - {items.commodity.name}
        </h1>
      </div>
      <div className={s.tableSection}>
        <NomenclaturesTable items={items} />
      </div>
    </section>
  );
};

function HeaderCell(props) {
  return (
    <th className={s.topHeaderCell}>
      <div className={s.headerCellGroups}>
        <div>
          <div
            className={classNames(s.headerSubRow, {
              [props.oi.type === "ORDER" ? s.orderType : s.inquiryType]: true
            })}
          >
            {typeLabel(props.oi.type)}
          </div>
          <div className={`${s.headerSubRow} ${s.referenceNumber}`}>
            {props.oi.reference_number}
          </div>
        </div>
        <div className={`${s.headerSubRow} ${s.supplierName}`}>
          {props.oi.supplier_name}
        </div>
        <div>
          <div className={s.headerSubRow}>
            {props.oi.rebranded === true && props.oi.brand}
          </div>
          <div
            className={classNames(s.headerSubRow, {
              "font-semibold": props.oi.type === "INQUIRY"
            })}
          >
            {formatDateDMY(props.oi.date_created)}
          </div>
          <div
            className={classNames(s.headerSubRow, s.bottomDivider, {
              "font-semibold": props.oi.type === "ORDER"
            })}
          >
            {formatDateDMY(props.oi.date_delivered)}
          </div>
        </div>
      </div>
    </th>
  );
}

function BodyCell(props) {
  return (
    <td className={classNames(s.numCell, props.sharedClasses)}>
      {formatNumber(props.nom.cells[props.oi.reference_number] || 0)}
    </td>
  );
}

export const NomenclaturesTable = ({ items }) => {
  const orders = items.orderInquiries["ORDER"] || [];
  const inquiries = items.orderInquiries["INQUIRY"] || [];
  return (
    <table className={s.table}>
      <thead>
        <tr className={s.topHeaderCell}>
          <th className={`${s.topLeftCell}`} />
          <th
            className={classNames(
              s.topHeaderCell,
              s.secondLeftStickyCol,
              s.topHeaderLayer
            )}
          >
            Stav skladu
          </th>
          <th
            className={classNames(
              s.topHeaderCell,
              s.thirdLeftStickyCol,
              s.topHeaderLayer
            )}
          >
            Prům.
            <br />
            měsíční prodej
          </th>
          <th
            className={classNames(
              s.topHeaderCell,
              s.forthLeftStickyCol,
              s.topHeaderLayer
            )}
          >
            Výstačnost dle prům. mesíčního prodeje
          </th>
          {orders.map(oi => {
            return (
              <HeaderCell
                key={`${oi.reference_number}-${oi.type}-${oi.date_created}-${oi.date_delivered}`}
                oi={oi}
              />
            );
          })}
          <th className={classNames(s.topHeaderCell, s.topHeaderLabelCell)}>
            Sklad + OV
          </th>
          {inquiries.map(oi => {
            return (
              <HeaderCell
                key={`${oi.reference_number}-${oi.type}-${oi.date_created}-${oi.date_delivered}`}
                oi={oi}
              />
            );
          })}
          <th className={classNames(s.topHeaderCell, s.topHeaderLabelCell)}>
            Sklad + OV + PV
          </th>
        </tr>
      </thead>
      <tbody>
        {items.nomenclatures.map(nom => {
          const sharedClasses = { [s.totalSumRow]: nom.is_total_sum };
          const sumStickyCls = { [s.sumSticky]: nom.is_sum };
          return (
            <tr
              key={nom.id || nom.color_code || "total"}
              className={classNames(s.row, {
                [s.sumRow]: nom.is_sum
              })}
            >
              <FirstColLabel nom={nom} />
              <td
                className={classNames(
                  `${s.numCell} font-semibold`,
                  s.secondLeftStickyCol,
                  sharedClasses,
                  sumStickyCls
                )}
              >
                {formatNumber(nom.storage_state ? nom.storage_state.amount : 0)}
              </td>
              <td
                className={classNames(
                  s.numCell,
                  s.thirdLeftStickyCol,
                  sharedClasses,
                  sumStickyCls
                )}
              >
                {formatNumber(nom.average_month_sale)}
              </td>
              <td
                className={classNames(
                  s.numCell,
                  s.forthLeftStickyCol,
                  sharedClasses,
                  sumStickyCls
                )}
              >
                {Math.floor(
                  (nom.storage_state ? nom.storage_state.amount : 0) /
                    nom.average_month_sale
                ) || 0}
              </td>
              {orders.map(oi => {
                return (
                  <BodyCell
                    key={`${oi.reference_number}-${oi.type}-${oi.date_created}-${oi.date_delivered}`}
                    sharedClasses={sharedClasses}
                    nom={nom}
                    oi={oi}
                  />
                );
              })}
              <td
                className={classNames(s.numCell, sharedClasses, sumStickyCls)}
              >
                {formatNumber(nom.storage_state_plus_ov)}
              </td>
              {inquiries.map(oi => {
                return (
                  <BodyCell
                    key={`${oi.reference_number}-${oi.type}-${oi.date_created}-${oi.date_delivered}`}
                    sharedClasses={sharedClasses}
                    nom={nom}
                    oi={oi}
                  />
                );
              })}
              <td
                className={classNames(s.numCell, sharedClasses, sumStickyCls)}
              >
                {formatNumber(nom.sum)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
