import React from "react";
import ReactDOM from "react-dom";
import Root from "./components/Root";
import { initGA } from "./lib/ga";
import { initHotjar } from "./lib/hotjar";
import { initSentry } from "./lib/sentry_io";

ReactDOM.render(<Root />, document.getElementById("root"));

initGA();
initHotjar();
initSentry();
