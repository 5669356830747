export const DELETE_SHOPPING_CART_HEADER_REQUEST =
  "DELETE_SHOPPING_CART_HEADER_REQUEST";
export const DELETE_SHOPPING_CART_HEADER_SUCCESS =
  "DELETE_SHOPPING_CART_HEADER_SUCCESS";
export const DELETE_SHOPPING_CART_HEADER_FAILURE =
  "DELETE_SHOPPING_CART_HEADER_FAILURE";

export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "FETCH_CATEGORY_FAILURE";

export const FETCH_SHOPPING_CART_HEADER_REQUEST =
  "FETCH_SHOPPING_CART_HEADER_REQUEST";
export const FETCH_SHOPPING_CART_HEADER_SUCCESS =
  "FETCH_SHOPPING_CART_HEADER_SUCCESS";
export const FETCH_SHOPPING_CART_HEADER_FAILURE =
  "FETCH_SHOPPING_CART_HEADER_FAILURE";

export const FETCH_SHOPPING_CART_HISTORY_REQUEST =
  "FETCH_SHOPPING_CART_HISTORY_REQUEST";
export const FETCH_SHOPPING_CART_HISTORY_SUCCESS =
  "FETCH_SHOPPING_CART_HISTORY_SUCCESS";
export const FETCH_SHOPPING_CART_HISTORY_FAILURE =
  "FETCH_SHOPPING_CART_HISTORY_FAILURE";

export const FETCH_HISTORIC_PURCHASE_REQUEST =
  "FETCH_HISTORIC_PURCHASE_REQUEST";
export const FETCH_HISTORIC_PURCHASE_SUCCESS =
  "FETCH_HISTORIC_PURCHASE_SUCCESS";
export const FETCH_HISTORIC_PURCHASE_FAILURE =
  "FETCH_HISTORIC_PURCHASE_FAILURE";

export const FETCH_SALES_PLAN_REQUEST = "FETCH_SALES_PLAN_REQUEST";
export const FETCH_SALES_PLAN_SUCCESS = "FETCH_SALES_PLAN_SUCCESS";
export const FETCH_SALES_PLAN_FAILURE = "FETCH_SALES_PLAN_FAILURE";

export const CHANGE_NOMENCLATURE = "CHANGE_NOMENCLATURE";
export const CHANGE_NOMENCLATURE_PROP = "CHANGE_NOMENCLATURE_PROP";
export const UPDATE_NOMENCLATURE = "UPDATE_NOMENCLATURE";

export const CHANGE_FOR_ORDER_AMOUNT = "CHANGE_FOR_ORDER_AMOUNT";
export const CHANGE_FOR_ORDER_AMOUNT_BATCHING =
  "CHANGE_FOR_ORDER_AMOUNT_BATCHING";

export const START_APPROVAL_SHOPPING_CART_REQUEST = "START_APPROVAL_SHOPPING_CART_REQUEST";
export const START_APPROVAL_SHOPPING_CART_REQUEST_SUCCESS = "START_APPROVAL_SHOPPING_CART_REQUEST_SUCCESS";
export const START_APPROVAL_SHOPPING_CART_REQUEST_FAILURE = "START_APPROVAL_SHOPPING_CART_REQUEST_FAILURE";
export const SAVE_SHOPPING_CART_REQUEST = "SAVE_SHOPPING_CART_REQUEST";
export const SAVE_SHOPPING_CART_SUCCESS = "SAVE_SHOPPING_CART_SUCCESS";
export const SAVE_SHOPPING_CART_FAILURE = "SAVE_SHOPPING_CART_FAILURE";

export const BATCH_REMOVE_SHOPPING_CART_HEADER_REQUEST =
  "BATCH_REMOVE_SHOPPING_CART_HEADER_REQUEST";
export const BATCH_REMOVE_SHOPPING_CART_HEADER_SUCCESS =
  "BATCH_REMOVE_SHOPPING_CART_HEADER_SUCCESS";
export const BATCH_REMOVE_SHOPPING_CART_HEADER_FAILURE =
  "BATCH_REMOVE_SHOPPING_CART_HEADER_FAILURE";

export const BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_REQUEST =
  "BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_REQUEST";
export const BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_SUCCESS =
  "BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_SUCCESS";
export const BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_FAILURE =
  "BATCH_CHANGE_STATE_SHOPPING_CART_HEADER_FAILURE";

export const SAVE_SHOPPING_CART_HEADER_REQUEST =
  "SAVE_SHOPPING_CART_HEADER_REQUEST";
export const SAVE_SHOPPING_CART_HEADER_SUCCESS =
  "SAVE_SHOPPING_CART_HEADER_SUCCESS";
export const SAVE_SHOPPING_CART_HEADER_FAILURE =
  "SAVE_SHOPPING_CART_HEADER_FAILURE";

export const SAVE_SHOPPING_CART_ITEM_REQUEST =
  "SAVE_SHOPPING_CART_ITEM_REQUEST";
export const SAVE_SHOPPING_CART_ITEM_SUCCESS =
  "SAVE_SHOPPING_CART_ITEM_SUCCESS";
export const SAVE_SHOPPING_CART_ITEM_FAILURE =
  "SAVE_SHOPPING_CART_ITEM_FAILURE";

export const SAVE_SHOPPING_CART_ITEMS_REQUEST =
  "SAVE_SHOPPING_CART_ITEMS_REQUEST";
export const SAVE_SHOPPING_CART_ITEMS_SUCCESS =
  "SAVE_SHOPPING_CART_ITEMS_SUCCESS";
export const SAVE_SHOPPING_CART_ITEMS_FAILURE =
  "SAVE_SHOPPING_CART_ITEMS_FAILURE";

export const FETCH_SHOPPING_CART_REQUEST = "FETCH_SHOPPING_CART_REQUEST";
export const FETCH_SHOPPING_CART_SUCCESS = "FETCH_SHOPPING_CART_SUCCESS";
export const FETCH_SHOPPING_CART_FAILURE = "FETCH_SHOPPING_CART_FAILURE";

export const FETCH_COMMODITY_GRAPH_REQUEST = "FETCH_COMMODITY_GRAPH_REQUEST";
export const FETCH_COMMODITY_GRAPH_SUCCESS = "FETCH_COMMODITY_GRAPH_SUCCESS";
export const FETCH_COMMODITY_GRAPH_FAILURE = "FETCH_COMMODITY_GRAPH_FAILURE";

export const QUICK_SEARCH = "QUICK_SEARCH";

export const FETCH_COMMODITIES_REQUEST = "FETCH_COMMODITIES_REQUEST";
export const FETCH_COMMODITIES_SUCCESS = "FETCH_COMMODITIES_SUCCESS";
export const FETCH_COMMODITIES_FAILURE = "FETCH_COMMODITIES_FAILURE";

export const CHANGE_COMMODITY = "CHANGE_COMMODITY";
export const FETCH_COMMODITY_PATCH_REQUEST = "FETCH_COMMODITY_PATCH_REQUEST";
export const FETCH_COMMODITY_PATCH_SUCCESS = "FETCH_COMMODITY_PATCH_SUCCESS";
export const FETCH_COMMODITY_PATCH_FAILURE = "FETCH_COMMODITY_PATCH_FAILURE";

export const FETCH_INITIAL_FILTERS_REQUEST = "FETCH_INITIAL_FILTERS_REQUEST";
export const FETCH_INITIAL_FILTERS_SUCCESS = "FETCH_INITIAL_FILTERS_SUCCESS";
export const FETCH_INITIAL_FILTERS_FAILURE = "FETCH_INITIAL_FILTERS_FAILURE";

export const UPDATE_FILTER = "UPDATE_FILTER";
export const RESET_BRAND_FILTER = "RESET_BRAND_FILTER";

export const FETCH_NOMENCLATURES_REQUEST = "FETCH_NOMENCLATURES_REQUEST";
export const FETCH_NOMENCLATURES_SUCCESS = "FETCH_NOMENCLATURES_SUCCESS";
export const FETCH_NOMENCLATURES_FAILURE = "FETCH_NOMENCLATURES_FAILURE";

export const FETCH_NOMENCLATURE_IN_PRODUCTION_REQUEST =
  "FETCH_NOMENCLATURE_IN_PRODUCTION_REQUEST";
export const FETCH_NOMENCLATURE_IN_PRODUCTION_SUCCESS =
  "FETCH_NOMENCLATURE_IN_PRODUCTION_SUCCESS";
export const FETCH_NOMENCLATURE_IN_PRODUCTION_FAILURE =
  "FETCH_NOMENCLATURE_IN_PRODUCTION_FAILURE";

export const FETCH_NOMENCLATURE_EXPEDITING_REQUEST =
  "FETCH_NOMENCLATURE_EXPEDITING_REQUEST";
export const FETCH_NOMENCLATURE_EXPEDITING_SUCCESS =
  "FETCH_NOMENCLATURE_EXPEDITING_SUCCESS";
export const FETCH_NOMENCLATURE_EXPEDITING_FAILURE =
  "FETCH_NOMENCLATURE_EXPEDITING_FAILURE";

export const FETCH_NOMENCLATURE_EFFICIENCY_REQUEST =
  "FETCH_NOMENCLATURE_EFFICIENCY_REQUEST";
export const FETCH_NOMENCLATURE_EFFICIENCY_SUCCESS =
  "FETCH_NOMENCLATURE_EFFICIENCY_SUCCESS";
export const FETCH_NOMENCLATURE_EFFICIENCY_FAILURE =
  "FETCH_NOMENCLATURE_EFFICIENCY_FAILURE";

export const LOG_IN_REQUEST = "LOG_IN_REQUEST";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILURE = "LOG_IN_FAILURE";

export const AUTHENTICATION_SESSION_REQUEST = "AUTHENTICATION_SESSION_REQUEST";
export const AUTHENTICATION_SESSION_SUCCESS = "AUTHENTICATION_SESSION_SUCCESS";
export const AUTHENTICATION_SESSION_FAILURE = "AUTHENTICATION_SESSION_FAILURE";

export const KEBOOLA_IMPORT_REQUEST = "KEBOOLA_IMPORT_REQUEST";
export const KEBOOLA_IMPORT_SUCCESS = "KEBOOLA_IMPORT_SUCCESS";
export const KEBOOLA_IMPORT_FAILURE = "KEBOOLA_IMPORT_FAILURE";

export const EFFICIENCY_CHECK_REQUEST = "EFFICIENCY_CHECK_REQUEST";
export const EFFICIENCY_CHECK_SUCCESS = "EFFICIENCY_CHECK_SUCCESS";
export const EFFICIENCY_CHECK_FAILURE = "EFFICIENCY_CHECK_FAILURE";

export const TOGGLE_SUPPLIER_SELECTION = "TOGGLE_SUPPLIER_SELECTION";
export const TOGGLE_SUPPLIER_REMOVAL = "TOGGLE_SUPPLIER_REMOVAL";
export const TOGGLE_ALL_SUPPLIERS_REMOVAL = "TOGGLE_ALL_SUPPLIERS_REMOVAL";

export const CHANGE_SUPPLIER_COUNT = "CHANGE_SUPPLIER_COUNT";

export const FETCH_COMMODITY_REPORT_REQUEST = "FETCH_COMMODITY_REPORT_REQUEST";
export const FETCH_COMMODITY_REPORT_SUCCESS = "FETCH_COMMODITY_REPORT_SUCCESS";
export const FETCH_COMMODITY_REPORT_FAILURE = "FETCH_COMMODITY_REPORT_FAILURE";

export const FETCH_SUPPLIERS_REQUEST = "FETCH_SUPPLIERS_REQUEST";
export const FETCH_SUPPLIERS_SUCCESS = "FETCH_SUPPLIERS_SUCCESS";
export const FETCH_SUPPLIERS_FAILURE = "FETCH_SUPPLIERS_FAILURE";

export const FETCH_SUPPLIER_HEADER_REQUEST = "FETCH_SUPPLIER_HEADER_REQUEST";
export const FETCH_SUPPLIER_HEADER_SUCCESS = "FETCH_SUPPLIER_HEADER_SUCCESS";
export const FETCH_SUPPLIER_HEADER_FAILURE = "FETCH_SUPPLIER_HEADER_FAILURE";

export const FETCH_SUPPLIER_OVERVIEW_REQUEST =
  "FETCH_SUPPLIER_OVERVIEW_REQUEST";
export const FETCH_SUPPLIER_OVERVIEW_SUCCESS =
  "FETCH_SUPPLIER_OVERVIEW_SUCCESS";
export const FETCH_SUPPLIER_OVERVIEW_FAILURE =
  "FETCH_SUPPLIER_OVERVIEW_FAILURE";

export const BATCH_SAVE_SUPPLIER_HEADER_REQUEST =
  "BATCH_SAVE_SUPPLIER_HEADER_REQUEST";
export const BATCH_SAVE_SUPPLIER_HEADER_SUCCESS =
  "BATCH_SAVE_SUPPLIER_HEADER_SUCCESS";
export const BATCH_SAVE_SUPPLIER_HEADER_FAILURE =
  "BATCH_SAVE_SUPPLIER_HEADER_FAILURE";

export const BATCH_REMOVE_SUPPLIER_HEADER_REQUEST =
  "BATCH_REMOVE_SUPPLIER_HEADER_REQUEST";
export const BATCH_REMOVE_SUPPLIER_HEADER_SUCCESS =
  "BATCH_REMOVE_SUPPLIER_HEADER_SUCCESS";
export const BATCH_REMOVE_SUPPLIER_HEADER_FAILURE =
  "BATCH_REMOVE_SUPPLIER_HEADER_FAILURE";

export const SAVE_SUPPLIER_ITEM_REQUEST = "SAVE_SUPPLIER_ITEM_REQUEST";
export const SAVE_SUPPLIER_ITEM_SUCCESS = "SAVE_SUPPLIER_ITEM_SUCCESS";
export const SAVE_SUPPLIER_ITEM_FAILURE = "SAVE_SUPPLIER_ITEM_FAILURE";

export const SUPPLIERS_POP_STATE = "SUPPLIERS_POP_STATE";
export const SUPPLIERS_CLEAR_STATE = "SUPPLIERS_CLEAR_STATE";

export const DUPLICATE_SUPPLIER_ITEM_REQUEST =
  "DUPLICATE_SUPPLIER_ITEM_REQUEST";
export const DUPLICATE_SUPPLIER_ITEM_SUCCESS =
  "DUPLICATE_SUPPLIER_ITEM_SUCCESS";
export const DUPLICATE_SUPPLIER_ITEM_FAILURE =
  "DUPLICATE_SUPPLIER_ITEM_FAILURE";

export const DEDUPLICATE_SUPPLIER_ITEM_REQUEST =
  "DEDUPLICATE_SUPPLIER_ITEM_REQUEST";
export const DEDUPLICATE_SUPPLIER_ITEM_SUCCESS =
  "DEDUPLICATE_SUPPLIER_ITEM_SUCCESS";
export const DEDUPLICATE_SUPPLIER_ITEM_FAILURE =
  "DEDUPLICATE_SUPPLIER_ITEM_FAILURE";

export const EXPORT_TO_KARAT_REQUEST = "EXPORT_TO_KARAT_REQUEST";
export const EXPORT_TO_KARAT_SUCCESS = "EXPORT_TO_KARAT_SUCCESS";
export const EXPORT_TO_KARAT_FAILURE = "EXPORT_TO_KARAT_FAILURE";

export const FETCH_TRENDS_REQUEST = "FETCH_TRENDS_REQUEST";
export const FETCH_TRENDS_SUCCESS = "FETCH_TRENDS_SUCCESS";
export const FETCH_TRENDS_FAILURE = "FETCH_TRENDS_FAILURE";
export const FETCH_TREND_PATCH_REQUEST = "FETCH_TREND_PATCH_SUCCESS";
export const FETCH_TREND_PATCH_SUCCESS = "FETCH_TREND_PATCH_SUCCESS";
export const FETCH_TREND_PATCH_FAILURE = "FETCH_TREND_PATCH_FAILURE";
export const CHANGE_TREND = "CHANGE_TREND";