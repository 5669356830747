import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchCommodityReport } from "../actions/commodityReport";
import Header from "../components/Header";
import ShoppingCart from "../components/ShoppingCart";
import { fetchShoppingCart } from "../actions/shoppingCart";
import { CommodityReport } from "./CommodityReport/NomenclaturesTable";

function LoadableWrapper(props) {
  const { items, fetching, errors, Component } = props;
  let content;
  if (fetching === null || fetching) content = <p>Načítá se...</p>;
  else if (errors) content = <p>{errors}</p>;
  else if (items && items.length === 0) content = <p>Žádná data.</p>;
  else {
    content = <Component {...props} />;
  }

  return <div className="wFullWithPadding">{content}</div>;
}

class CommodityReportScreen extends React.PureComponent {
  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(fetchCommodityReport(match.params.id));
    dispatch(fetchShoppingCart());
  }

  render() {
    const {
      commodityReport,
      shoppingCart,
      commodityReportFetching,
      commodityReportError
    } = this.props;

    return (
      <div>
        <Header />
        <ShoppingCart shoppingCart={shoppingCart} showBackLink={false} />

        <LoadableWrapper
          items={commodityReport}
          fetching={commodityReportFetching}
          errors={commodityReportError}
          Component={CommodityReport}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    commodityReportFetching: state.fetching.commodityReport,
    commodityReportError: state.errors.commodityReport,
    commodityReport: state.commodityReport,
    shoppingCart: state.shoppingCart
  }),
  dispatch => ({
    dispatch
  })
)(withRouter(CommodityReportScreen));
