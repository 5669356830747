import * as types from "../actions/actionTypes";
import _ from "lodash";

const commodityGraphUrl = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_COMMODITY_GRAPH_REQUEST:
      return state;
    case types.FETCH_COMMODITY_GRAPH_SUCCESS:
      return _(state).set(action.commodityCode, action.graphParams).clone();
    case types.FETCH_COMMODITY_GRAPH_FAILURE:
      return _(state).set(action.commodityCode, null).clone();
    default:
      return state;
  }
};

export default commodityGraphUrl;
